import React from 'react'
import styles from './InfoBlock.module.scss'
import classnames from 'classnames'

const InfoBlock = ({value=null, label=null, large=false, inline=false, fullWidth=false, noMargin=false, labelStyle=null, children}) => {

  const getClasses = (tgt) => {
    const arr = {
      main: classnames([inline ? styles.inlineWrap : styles.wrap, noMargin ? styles.noMargin : '', large ? styles.large : '', fullWidth && styles.fullWidth]),
      header: classnames(inline ? [styles.label, styles.inline, styles[labelStyle]] : [styles.label, styles[labelStyle]]),
      content: classnames(inline ? [styles.value, styles.inline] : styles.value)
    }

    return arr[tgt];
  }
  return (
    <div className={getClasses('main')}>
      <span className={getClasses('header')}>{label}</span>
      <div className={getClasses('content')}>{children ? children : value}</div>
    </div>
  )
}

export default InfoBlock
