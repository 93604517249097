import { request, generateParameters } from './requestService';

export const assets = args => {
  return request(`/assets${generateParameters(args)}`)  
}

export const summary = () => {
  return request('/assets/summary')
}

export const single = iccid => {
  return request(`/assets/${iccid}`)
}

export const location = iccid => {
  return request(`/assets/${iccid}/location`)
}

export const provision = inputArgs => {
  const args = {
    method: 'POST',
    body: JSON.stringify(inputArgs)
  }
  return request('/assets', args)
}

export const activate = iccid => {
  const args = {
    method: 'PUT'
  }
  return request(`/assets/${iccid}/activate`, args)
}

export const suspend = iccid => {
  const args = {
    method: 'PUT'
  }
  return request(`/assets/${iccid}/suspend`, args)
}

export const reboot = iccid => {
  const args = {
    method: 'PUT'
  }
  return request(`/assets/${iccid}/reboot`, args)
}

export const updateAsset = (iccid, body) => {
  const args = {
    method: 'PUT',
    body: JSON.stringify(body)
  }
  return request(`/assets/${iccid}`, args)
}

export const transferAggPlan = (iccid, body) => {
  const args = {
    method: 'PUT',
    body: JSON.stringify(body)
  }
  return request(`/assets/${iccid}/transfer`, args)
}

export const cancelTransfer = (iccid) => {
  const args = {
    method: 'DELETE',
  }
  return request(`/assets/${iccid}/transfer`, args)
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  assets,
  summary,
  single,
  location,
  provision,
  activate,
  suspend,
  reboot,
  updateAsset,
  transferAggPlan,
  cancelTransfer
}