import React from 'react'
import Modal from '../../../components/Modal/Modal'
import Input from '../../../components/Input/Input'
import Button from '../../../components/Button/Button'
import { Row, Col } from '../../../components/Grid/Grid'
import useForm from '../../../hooks/useForm'
import apiService from '../../../services/apiService'
import { useTranslation } from 'react-i18next'

const CreateApiKeyModal = ({isShowing, onClose, onSuccess}) => {
  const {t} = useTranslation()

  const validateRules = {
    name: 'required'
  }

  const handleActivateAttempt = () => {
    return apiService.createKey(values).then(response => {
      onSuccess()
    })
  }

  const { handleChange, handleSubmit, isSubmitting, values, errors } = useForm(handleActivateAttempt, validateRules)
  
  return (
    <Modal title={t('api.keys.create.header')} show={isShowing} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs='12'>
            <Input name="name" label={t('api.keys.create.inputLabel')} placeholder={t('api.keys.create.inputPlaceholder')} onChange={handleChange} value={values.name} error={errors.name} />
          </Col>
          <Col xs='12' align="right">
            <Button type='submit' loading={isSubmitting}>{t('api.keys.create.button')}</Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )

}

export default CreateApiKeyModal