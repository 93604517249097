import React, {useState} from 'react'
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Button/Button'
import userService from '../../services/userService';
import {Row, Col} from '../../components/Grid/Grid'
import {toast} from 'react-toastify'

const ConfirmInviteDeleteModal = ({isShowing, onClose, user}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  
  const handleConfirm = () => {    
    setIsSubmitting(true)
    return userService.removeInvite(user.token)
    .then( response => {
      if (response.ok) {
        toast.success('Invite successfully revoked')
        onClose(true)
      } else {
        toast.warning('Could not revoke invitation. Please try again soon')
        onClose()
      }
      setIsSubmitting(false)
    })
  }

  return (
    <Modal title="EDIT SHIPPING ADDRESS" show={isShowing} onClose={onClose}>
      <Row>
       <Col xs="12">
          <p>Remove invite to account?</p>
        </Col>
        <Col xs="12" align="right">
            <Button disabled={isSubmitting} variant="link" onClick={onClose}>Cancel</Button>
            <Button loading={isSubmitting} onClick={handleConfirm}>Confirm</Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default ConfirmInviteDeleteModal;