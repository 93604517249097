import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import styles from './Loader.module.scss'
import {ReactComponent as Loading} from '../../assets/icons/loading.svg'

const Loader = ({inline=false, className=null, size=null, type='default', width=Math.floor(Math.random() * 35) + 60, dotted=false, variant="primary"}) => {
  const getClasses = () => {
    let classes = [];
    if( className ) classes.push(className);
    if( size ) classes.push(styles[size]);
    if( inline ) classes.push('d-inline');
    if (type === 'default') {
      classes.push(styles.dotted);
    } else if (type === 'text') {
      classes.push(styles.textLoader);
    }
    return classes.join(' ');
  }

  if (type === 'default') {
    return <Loading className={getClasses()} style={variant && {fill: variant}} />
  } else if (type === 'text') {
    return <span className={getClasses()} style={{width: width + "%" }} />
  }else if (type === "spinner") {
    return (
      <Spinner size={size} as={inline ? 'span' : 'div'} animation="border" variant={variant} role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }
}

export default Loader