import React, {useEffect} from 'react';
import {Row, Col} from '../../../components/Grid/Grid';
import Card from '../../../components/Card/Card';
import Heading from '../../../components/Heading/Heading'
import Input from '../../../components/Input/Input'
import Button from '../../../components/Button/Button'
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal/Modal'
import userService from '../../../services/userService';
import useForm from '../../../hooks/useForm'
import useModal from '../../../hooks/useModal';
import { Trans, useTranslation } from 'react-i18next';

const NewUserSignup = ({invite, onProgress}) => {
  const [isShowing, toggle] = useModal()
  const {t} = useTranslation()

  const validateRules = {
    name: 'required|string',
    password: 'required|string',
    confirm: 'required|string|matches:password',
  }

  const handleInviteProcessAttempt = () => {
    const data = {
      name: values.name,
      password: values.password
    }

    return userService.acceptInvite(invite.token, data).then( response => {
      if (response.ok) {
        onProgress('creation')
      } else {
        toast.error(t('toasts.createAccountError'))
      }
    })
  }

  const handleRejectInvite = () => {
    userService.removeInvite(invite.token).then( response => {
      if (response.ok) {
        onProgress('reject')
      } else {
        toast.error(t('toasts.rejectInviteError'))
      }
    })
  }
  
  const {handleChange, handleSubmit, isSubmitting, values, errors, setDefaults} = useForm(handleInviteProcessAttempt, validateRules);
  
  useEffect( () => {
    setDefaults({
      email: invite.email
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Modal show={true} size={'md'} fixed title={t('invite.newHeader')}>
        <div className={'text-left'}>
          <Row>
            <Col xs="12">
              <p>
                <Trans i18nKey="invite.newBody" values={{ acc: invite.account.name}} />
              </p>
              <Card className={'mb-3'} slim title={`${t('invite.youreInvited')}:`} productCard='active'>
                <h3>{invite.account.name}</h3>
              </Card>
              <form onSubmit={handleSubmit}>
                <Row spacer='small'>
                  <Col xs="12">
                    <Heading>{t('invite.signup')}</Heading>
                    <Input name="email" label={`${t('misc.email')}:`} disabled onChange={handleChange} value={values.email || ''} />
                    <Input name="name" label={`${t('misc.fullName')}:`} placeholder={t('misc.fullNamePlaceholder')} onChange={handleChange} value={values.name || ''} error={errors.name} />
                    <Input name="password" type="password" label={`${t('misc.password')}:`} placeholder={t('misc.passwordPlaceholder')} onChange={handleChange} value={values.password || ''} error={errors.password} />
                    <Input name="confirm" type="password" label={`${t('misc.confirmPassword')}:`} placeholder={t('misc.confirmPasswordPlaceholder')} onChange={handleChange} value={values.confirm || ''} error={errors.confirm} />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" align="right">
                    <Button loading={isSubmitting} onClick={toggle} variant="link">{t('invite.reject')}</Button>
                    <Button loading={isSubmitting} type="submit">{t('invite.signupAndAccept')}</Button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal size="lg" title={t('invite.rejectModal.header')} show={isShowing} onClose={toggle}>
        <p>{t('invite.rejectModal.body')}</p>
        <p>{t('invite.rejectModal.continue')}</p>
        <Row>
          <Col xs='12' align='right'>
            <Button onClick={toggle} variant="link">{t('misc.cancel')}</Button>
            <Button onClick={handleRejectInvite}>{t('invite.reject')}</Button>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default NewUserSignup