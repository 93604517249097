import React, {useState} from 'react'
import styles from './SimStatusSwitch.module.scss'
import assetService from '../../services/assetService'
import {toast} from 'react-toastify'
import Modal from '../../components/Modal/Modal'
import useModal from '../../hooks/useModal'
import { Row, Col } from '../Grid/Grid'
import Button from '../Button/Button'
import { useTranslation } from 'react-i18next'

const SimStatusSwitch = ({asset, onSwitch}) => {
  const [switching, setSwitching] = useState(false)
  const [isShowing, toggle] = useModal()
  const {t} = useTranslation()

  const handleActivate = e => {
    setSwitching(true)

    assetService.activate(asset.iccid).then( response => {
      setSwitching(false)
      toggle()
      if (response.ok) {
        toast.success(t('toasts.activate'))
        onSwitch({status: 'active'})
      } else {
        toast.warning(t('toasts.activateFailed'))
      }
    })
  }
  
  const handleSuspend = e => {
    setSwitching(true)
    assetService.suspend(asset.iccid).then( response => {
      setSwitching(false)
      toggle()
      if (response.ok) {
        toast.success(t('toasts.suspend'))
        onSwitch({status: 'suspended'})
      } else {
        toast.warning(t('toasts.suspendFailed'))
      }
    })
  }

  return (
    <div className={`${styles.wrap} ${switching && styles.busy}`}>
      <SwitchButton type="success" className="text-uppercase" onClick={toggle} active={asset.status === 'active'}>{t('misc.active')}</SwitchButton>
      <SwitchButton type="danger" className="text-uppercase" onClick={toggle} active={asset.status !== 'active'}>{t('misc.suspended')}</SwitchButton>
      <Modal title={asset.status === 'active' ? t('simOverview.controls.suspendActivate.susHeader') : t('simOverview.controls.suspendActivate.actHeader')} show={isShowing} onClose={toggle}>
        <Row>
          <Col xs='12'>
            <p>{asset.status === 'active' ? t('simOverview.controls.suspendActivate.susBody') : t('simOverview.controls.suspendActivate.actBody')}</p>
          </Col>
          <Col align='right'>
            <Button disabled={switching} variant="a" onClick={toggle}>{t('misc.cancel')}</Button>
            <Button loading={switching} onClick={asset.status === 'active' ? handleSuspend : handleActivate}>{t('misc.confirm')}</Button>
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

const SwitchButton = ({active, type, onClick, children}) => {
  return (
    <div className={`${styles.button} ${styles[type]} ${active && styles.active}`} onClick={onClick}>
      {children}
    </div>
  )
}

export default SimStatusSwitch