import React from 'react'
import {Alert} from 'react-bootstrap'
import Modal from '../../../../../../../components/Modal/Modal'
import {Row, Col} from '../../../../../../../components/Grid/Grid'
import Input from '../../../../../../../components/Input/Input'
import Button from '../../../../../../../components/Button/Button'
import assetService from '../../../../../../../services/assetService'
import {toast} from 'react-toastify'
import useForm from '../../../../../../../hooks/useForm'
import { Trans, useTranslation } from 'react-i18next'

const RebootModal = ({show, onClose, iccid}) => {
  const {t} = useTranslation()

  const validateRules = {
    test: 'required|exact:REBOOT',
  }

  const handleRebootAttempt = () => {
    return assetService.reboot(iccid).then(response => {
      if (response.ok) {
        toast.success(t('toasts.rebootSuccess'))
        onClose()
      } else {
        toast.warning(t('toasts.rebootError'))
        onClose()
      }
    })
  }
  
  const { handleChange, handleSubmit, isSubmitting, values, errors } = useForm(handleRebootAttempt, validateRules)

  return (
    <Modal title={t('simOverview.controls.reboot')} show={show} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Row spacer="small">
          <Col xs='12'>
            <p>{t('simOverview.controls.rebootModalBody')}</p>
          </Col>
        </Row>
        <Row>
          <Col xs='12'>
            <Input name="test" label={<Trans i18nKey='simOverview.controls.rebootModalInstruction' values={{ phrase: "REBOOT" }} />} onChange={handleChange} value={values.test} error={errors.test} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Alert variant='warning'><small><Trans i18nKey='simOverview.controls.rebootModalWarning' components={{ bold: <b/> }} /></small></Alert>
          </Col>
        </Row>
        <Row>
          <Col xs='12' align="right">
            <Button type='submit' loading={isSubmitting}>{t('simOverview.controls.rebootModalButton')}</Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default RebootModal