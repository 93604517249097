import React, {useState} from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useAuth} from '../../../contexts/AuthContext'
import SimsTable from '../../../containers/SimsTable/SimsTable'
import SimsOverView from '../../../containers/SimsOverView/SimsOverView'
import AlertModals from '../../../containers/AlertModals/AlertModals'

const SimsLayout = () => {
  const {activeAccount} = useAuth();
  const [refreshSims, setRefreshSims] = useState(false)

  const handleSimActivation = () => {
    setRefreshSims(!refreshSims);
  }

  return (
    <>
      <SimsOverView active={activeAccount} onSimActivated={handleSimActivation} />
      <Container>
        <Row>
          <Col xs="12">
           <SimsTable refresh={refreshSims} />
          </Col>
        </Row>
      </Container>

      <AlertModals accepted={['single-bundle-payment']} />
    </>    
  )
}

export default SimsLayout