import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container'
import {Row, Col} from '../../../components/Grid/Grid'
import PageHeader from '../../../components/PageHeader/PageHeader'
import PlanCard from '../../../components/PlanCard/PlanCard';
import Button from '../../../components/Button/Button'
import Loader from '../../../components/Loader/Loader'
import useModal from '../../../hooks/useModal'
import CreatePlanModal from './modals/CreatePlanModal'
import PlanTypeModal from './modals/PlanTypeModal'
import productService from '../../../services/productService'
import { useAuth } from '../../../contexts/AuthContext'
import { useTranslation } from 'react-i18next';
import { usePayPalScriptReducer } from '@paypal/react-paypal-js';

const PlansLayout = () => {
  const [isCreatePlanShowing, toggleCreatePlan] = useModal()
  const [isPlanTypeModalShowing, togglePlanTypeModal] = useModal()
  const [products, setProducts] = useState(null)
  const {activeAccount} = useAuth()
  const {t} = useTranslation()
  const [{ isPending }] = usePayPalScriptReducer();
  useEffect( () => {
    productService.products().then( res => {
      if (res.ok) setProducts(res.data)
    })
  }, [])

  const handleUpdatePlan = () => {
    productService.products().then( res => {
      if (res.ok) setProducts(res.data)
    })
  }

  const toggleHelperModal = () => {
    toggleCreatePlan()
    togglePlanTypeModal()
  }

  const handleCreatePlanClose = success => {
    toggleCreatePlan()
    if (success) {
      productService.products().then( res => {
        if (res.ok) setProducts(res.data)
      })
    }
  }
  
  return (
    <>
      <PageHeader title={t('plans.pageTitle')} inset={products && products.length > 0}>
        <Button variant="primary" disabled={isPending} onClick={toggleCreatePlan}>{t('plans.create.header')}</Button>
      </PageHeader>
      
      <Container>
        <Row>
          {products ? (
            <>
             {products.length > 0 ? 
               products.map( prod => (
                  <Col xs="6" className="mb-3" key={prod.id}>
                    <PlanCard planData={prod} onUpdatePlan={handleUpdatePlan} />
                  </Col>
                )) : (
                  <>
                    <Col xs="12" align="center">
                      <p>{t('plans.noPlansFound')}</p>
                    </Col>
                    <Col xs="12" align="center">
                      <Button variant="primary" className="text-uppercase" onClick={toggleCreatePlan}>{t('plans.create.header')}</Button>
                    </Col>
                  </>
                )
              }
            </>
          ) : (
          <>
            <Col xs='12' align='center'><Loader size='lg'/></Col>
            <Col xs='12' align='center'><p>{t('plans.loading')}</p></Col>
          </>
          )}
        </Row>
      </Container>

      {!isPending && (
        <CreatePlanModal isShowing={isCreatePlanShowing} onClose={handleCreatePlanClose} onHelperModal={toggleHelperModal} type={activeAccount ? activeAccount.billing.dataPackageType : null} />
      )}
      
      <PlanTypeModal type={activeAccount ? activeAccount.billing.dataPackageType : null} isShowing={isPlanTypeModalShowing} onClose={togglePlanTypeModal} onReturn={toggleHelperModal} />
    </>    
  )
}

export default PlansLayout