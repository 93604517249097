export const toCurrency = (value, currency="GBP") => {
  let string = '';

  switch (currency) {
    case 'GBP':
      string = '£'
      break;
    case 'EUR':
      string = '€'
      break;
    case 'USD':
      string = '$'
      break;
    default:
      string = ''
      break;
  }

  return value !== null ? string.concat((value / 100).toFixed(2)) : string
}