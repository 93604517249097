import React, {useEffect, useState} from 'react'
import Modal from '../../components/Modal/Modal';
import {Row, Col} from '../../components/Grid/Grid'
import Heading from '../../components/Heading/Heading'
import CountrySelect from '../../components/CountrySelect/CountrySelect'
import Input from '../../components/Input/Input'
import useForm from '../../hooks/useForm'
import accountService from '../../services/accountService'
import { useAuth } from '../../contexts/AuthContext';
import {toCurrency} from '../../helpers/currency'
import BigText from '../../components/BigText/BigText'
import billingService from '../../services/billingService';
import {toast} from 'react-toastify'
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { PayPalButtons } from '@paypal/react-paypal-js';
import paypalOrderService from '../../services/paypalOrderService';

const PayInvoiceModal = ({isShowing, toggle, onClose, invoice}) => {
  
  const validateRules = {amount: 'number'}
  const {activeAccount} = useAuth()
  const {t} = useTranslation()

  useEffect( () => {
    setDefaults({
      name: activeAccount ? activeAccount.billing.billingAddress.name : null,
      line1: activeAccount ? activeAccount.billing.billingAddress.line1 : null,
      line2: activeAccount ? activeAccount.billing.billingAddress.line2 : null,
      postalCode: activeAccount ? activeAccount.billing.billingAddress.postalCode : null,
      city: activeAccount ? activeAccount.billing.billingAddress.city : null,
      stateRegion: activeAccount ? activeAccount.billing.billingAddress.stateRegion : null,
      country: activeAccount ? activeAccount.billing.billingAddress.country : null
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccount])

  const [error, setError] = useState();
  const handleCreateOrder = (data, actions) => {
    return new Promise((resolve, reject) => {

      billingService.payInvoice(invoice.id)
        .then(response => {
          if (!response.ok || !response.data?.paypal_order_id) {
            toast.error(t('toasts.payInvoiceError'))
            reject();
          }
          const paypalID = response.data.paypal_order_id;
          paypalOrderService.createOrder(paypalID)
            .then(res => {
              resolve(res.data.id);
            })
            .catch(err => {
              toast.error(t('toasts.payInvoiceError'))
              reject();
            })
        })
        .catch(err => {
          toast.error(t('toasts.payInvoiceError'))
          reject();
        })
    });
  };
  const handlePaymentStarted = (data, actions) => {
    return new Promise((resolve, reject) => {
      if ( activeAccount ) {
        accountService.updateAddress(activeAccount.billing.billingAddress.id, values)
          .then(response => {
            if (response.ok) {
              resolve();
              return actions.resolve();
            } else {
              toast.error(t('toasts.billingAddressError'))
              reject();
              return actions.reject();
            }
          })
      } else {
        // technically impossible - can't get here without an account logged in!
        toast.error(t('toasts.unknownPaymentError'))
        reject();
        return actions.reject()
      }
    })
  }
  const handlePaymentError = () => {
    setError(t('paypal.error.default'));
  }
  const handlePaymentCancel = () => {
    setError(t('paypal.cancel.default'));
  }
  const handlePaymentApproved = (data, actions) => {
    const processPaymentURL = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_PROCESS_PAYMENT_ENDPOINT}?paypal-order-id=${data.orderID}`;
    window.location.href = processPaymentURL;
  }

  // TODO: could swap this out for a non 'useForm' state at this point..
  const handlePayInvoice = () => {}
  const { handleChange, values, errors, setDefaults } = useForm(handlePayInvoice, validateRules);

  return (
    <Modal size="lg" title={t('invoices.list.pay')} show={isShowing} onClose={onClose} banner={{type:'warning', text: `Invoice #${invoice.number} PAYMENT DUE`}}>
      <Row spacer>
        <Col xs="12">
          <Heading>{t('account.address.billing.header')}</Heading>
          <Input name="line1" label={`${t('account.address.labels.lineOne')}:`} onChange={handleChange} value={values.line1 || undefined} error={errors.line1} />
          <Input name="line2" label={`${t('account.address.labels.lineTwo')}:`} onChange={handleChange} value={values.line2 || undefined} error={errors.line2} />
          <Input name="city" label={`${t('account.address.labels.city')}:`} onChange={handleChange} value={values.city || undefined} error={errors.city} />
          <Input name="stateRegion" label={`${t('account.address.labels.state')}:`} onChange={handleChange} value={values.stateRegion || undefined} error={errors.stateRegion} />
          <Input name="postalCode" label={`${t('account.address.labels.postcode')}:`} onChange={handleChange} value={values.postalCode || undefined} error={errors.postalCode} />
          <CountrySelect name="country" label={`${t('account.address.labels.country')}:`} onChange={handleChange} value={values.country || undefined} error={errors.country} />
        </Col>
      </Row>
      <Row spacer>
        <Col xs="12">
          <BigText label={t('misc.subtotal')} value={ toCurrency(invoice.subTotal, invoice.currency)} horizontal />
          <hr/>
          <BigText label={t('misc.totalIncVat')} value={toCurrency(invoice.total, invoice.currency)} horizontal />
        </Col>
      </Row>
      <Row spacer>
        <Col xs="12">
          {error && (
            <Form.Text className="text-center text-danger mt-0 mb-2">{error}</Form.Text>
          )}
          <PayPalButtons
            onClick={handlePaymentStarted}
            onCancel={handlePaymentCancel}
            onError={handlePaymentError}
            createOrder={handleCreateOrder}
            onApprove={handlePaymentApproved}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default PayInvoiceModal;