import React, {useEffect} from 'react'
import Modal from '../../components/Modal/Modal';
import Input from '../../components/Input/Input'
import CountrySelect from '../../components/CountrySelect/CountrySelect'
import useForm from '../../hooks/useForm'
import Button from '../../components/Button/Button'
import {Row, Col} from '../../components/Grid/Grid'
import accountService from '../../services/accountService'
import {useAuth} from '../../contexts/AuthContext'
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const EditShippingAddressModal = ({isShowing, toggle, onClose}) => {
  const {activeAccount, setActiveAccount} = useAuth()
  const {t} = useTranslation()
  
  useEffect( () => {
    setValues({
      name: activeAccount ? activeAccount.billing.deliveryAddress.name : '',
      line1: activeAccount ? activeAccount.billing.deliveryAddress.line1 : '',
      line2: activeAccount ? activeAccount.billing.deliveryAddress.line2 : '',
      city: activeAccount ? activeAccount.billing.deliveryAddress.city : '',
      stateRegion: activeAccount ? activeAccount.billing.deliveryAddress.stateRegion : '',
      postalCode: activeAccount ? activeAccount.billing.deliveryAddress.postalCode : '',
      country: activeAccount ? activeAccount.billing.deliveryAddress.country : ''
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccount])

  const validateRules = {
    name: 'required',
    line1: 'required',
    line2: '',
    stateRegion: 'required',
    city: 'required',
    postalCode: 'required',
    country: 'required',
  }

  const handleUpdateAttempt = () => {
    const addressID = activeAccount.billing.deliveryAddress.id
    return accountService.updateAddress(addressID, values)
    .then( response => {
      if (response.ok) {
        onClose()
        toast.success(t('toasts.shippingUpdated'))
        const deliveryAddressData = { ...activeAccount.billing, deliveryAddress: response.data }
        setActiveAccount( { 
          ...activeAccount,
          billing: deliveryAddressData
        })
      }
    })
    .catch( err => {
      console.warn(err)
    })
  }

  const {handleChange, handleSubmit, isSubmitting, values, errors, setValues} = useForm(handleUpdateAttempt, validateRules);
 
  return (
    <Modal title={t('account.address.shipping.edit')} show={isShowing} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Input name="name" label={t('account.address.labels.name')} placeholder="Give your SIM a name..." onChange={handleChange} value={values.name} error={errors.name} />
        <Input name="line1" label={t('account.address.labels.lineOne')} onChange={handleChange} value={values.line1} error={errors.line1} />
        <Input name="line2" label={t('account.address.labels.lineTwo')} onChange={handleChange} value={values.line2} error={errors.line2} />
        <Input name="city" label={t('account.address.labels.city')} onChange={handleChange} value={values.city} error={errors.city} />
        <Input name="stateRegion" label={t('account.address.labels.state')} onChange={handleChange} value={values.stateRegion} error={errors.stateRegion} />
        <Input name="postalCode" label={t('account.address.labels.postcode')} onChange={handleChange} value={values.postalCode} error={errors.postalCode} />
        <CountrySelect name="country" label={t('account.address.labels.country')} onChange={handleChange} value={values.country} error={errors.country} />
        <Row>
          <Col align="right">
            <Button type="submit" className="text-uppercase" loading={isSubmitting} disabled={isSubmitting}>{t('misc.updateChanges')}</Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default EditShippingAddressModal;