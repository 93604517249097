import React from 'react';
import {Row, Col} from '../../../../components/Grid/Grid'
import useModal from '../../../../hooks/useModal';
import Button from '../../../../components/Button/Button'
import EditAccountModal from '../../../../containers/EditAccountModal/EditAccountModal'
import EditShippingAddressModal from '../../../../containers/EditShippingAddressModal/EditShippingAddressModal'
import Card from '../../../../components/Card/Card'
import InfoBlock from '../../../../components/InfoBlock/InfoBlock'
import Loader from '../../../../components/Loader/Loader'
import { useAuth } from '../../../../contexts/AuthContext';
import {toCurrency} from '../../../../helpers/currency'
import { useTranslation } from 'react-i18next';

const Accounts = () => {
  const [isEditAccountShowing, toggleEditAccount] = useModal()
  const [isEditShippingModalShowing, toggleEditShipping] = useModal()
  const {activeAccount} = useAuth();
  const {t} = useTranslation()

  return (
    <>
      <Row>
        <Col>
          <Card title={t('settings.account.accountInfo.header')} link={<Button onClick={toggleEditAccount} variant="a">{t('settings.account.accountInfo.edit')}</Button>}>
            <InfoBlock label={`${t('settings.account.accountInfo.labels.name')}:`} value={activeAccount ? activeAccount.name : <Loader type="text" width={60}/>} />
            <InfoBlock label={`${t('settings.account.accountInfo.labels.id')}:`} value={activeAccount ? activeAccount.id : <Loader type="text" width={50} />} />
            <InfoBlock label={`${t('settings.account.accountInfo.labels.email')}:`}>
              {activeAccount ? (
                <>
                  <p>{activeAccount.billing.defaultEmail}</p>
                  <small>{`${t('settings.account.accountInfo.labels.emailNote')}:`}</small>
                </>
              ) : (
                <>
                  <p><Loader type="text" width={40} /></p>
                  <Loader type="text" width={100} />
                  <Loader type="text" width={20} />
                </>
              )}
            </InfoBlock>
            <InfoBlock label={`${t('settings.account.accountInfo.labels.vpn')}:`}>
              {activeAccount ? (
                <>
                  {(activeAccount.vpn && activeAccount.vpn.active) ? (
                    <p className="text-success">{t('misc.active')}</p>
                  ) : (
                    <p className="text-danger">{t('misc.inactive')}</p>
                  )}
                </>
              ) : (
                <Loader type="text" width={10} />
              )}
            </InfoBlock>
            <InfoBlock label={`${t('settings.account.accountInfo.labels.currency')}:`} value={activeAccount ? <span>{activeAccount.billing.currency} ({toCurrency(null, activeAccount.billing.currency)})</span> : <Loader type="text" width={20} />} />
          </Card>
        </Col>
        <Col>
          <Card title={t('settings.account.shippingInfo.header')} link={<Button onClick={toggleEditShipping} variant="a">{t('account.address.edit')}</Button>}>
            <InfoBlock label={`${t('account.address.shipping.header')}:`}>
              {activeAccount ? (
                <>
                  {activeAccount.billing.deliveryAddress.name && <p>{activeAccount.billing.deliveryAddress.name}</p>}
                  {activeAccount.billing.deliveryAddress.line1 && <p>{activeAccount.billing.deliveryAddress.line1}</p>}
                  {activeAccount.billing.deliveryAddress.line2 && <p>{activeAccount.billing.deliveryAddress.line2}</p>}
                  {activeAccount.billing.deliveryAddress.city && <p>{activeAccount.billing.deliveryAddress.city}</p>}
                  {activeAccount.billing.deliveryAddress.stateRegion && <p>{activeAccount.billing.deliveryAddress.stateRegion}</p>}
                  {activeAccount.billing.deliveryAddress.country && <p>{activeAccount.billing.deliveryAddress.country}</p>}
                </>
              ) : (
                <>
                 <p><Loader type="text" width={60} /></p>
                 <p><Loader type="text" width={70} /></p>
                 <p><Loader type="text" width={50} /></p>
                 <p><Loader type="text" width={55} /></p>
                 <p><Loader type="text" width={45} /></p>
                 <p><Loader type="text" width={20} /></p>
                </> 
              )}
            </InfoBlock>
            <InfoBlock label={`${t('account.address.shipping.contact')}:`} value={activeAccount ? activeAccount.billing.defaultEmail : <Loader type="text" width={50} />} />
          </Card>
        </Col>
      </Row>

      <EditAccountModal isShowing={isEditAccountShowing} onClose={toggleEditAccount} />
      <EditShippingAddressModal isShowing={isEditShippingModalShowing} onClose={toggleEditShipping} />
    </>   
  )
}

export default Accounts