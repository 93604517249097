import cookieService from './cookieService';
import { request } from './requestService';

export const login = body => {
  return new Promise((resolve, reject) => {
    request(
      `/auth/login`, 
      {
        method: 'POST',
        body: JSON.stringify(body)
      }
    )
    .then((response) => { 
      if (response.ok) {
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        cookieService.addCookie('LunerTempToken', response.data.token, tomorrow.toUTCString())    
        
        resolve(response)
      
      } else {
        reject(response)
      }
    })
  })
}

export const logout = () => {
  return new Promise( async (resolve, reject) => {
    await request('/auth/logout', {method: 'POST'})
    cookieService.removeAllAuthCookies()
    resolve()
  })
}

export const resetPasswordRequest = body => {
  return request(
    '/auth/forgotPassword',
    {
      method: 'POST',
      body: JSON.stringify(body)
    }
  )
}

export const requestAccountSwitch = accountId => {
  return new Promise((resolve, reject) => {
    request(`/auth/switchAccount/${accountId}`, 
    {
      method: 'PUT', 
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-api-key': cookieService.getCookie('LunerAPIToken') || cookieService.getCookie('LunerTempToken')
      }
    }
    ).then(response => {
      if (response.ok) {    
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        cookieService.addCookie('LunerAPIToken', response.data.token, tomorrow.toUTCString())  
        resolve(response)  
      } else {
        reject(response)
      }
    })
  })
}

export const getAuthAccounts = () => {
  return request('/auth/getAccounts', {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'x-api-key': cookieService.getCookie('LunerAPIToken') || cookieService.getCookie('LunerTempToken')
    }
  })
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  login,
  logout,
  resetPasswordRequest,
  requestAccountSwitch,
  getAuthAccounts
}