import React from 'react'
import DeleteApiKeyModal from '../../layouts/ApiLayout/modals/DeleteApiKeyModal'
import Button from '../../components/Button/Button'
import useModal from '../../hooks/useModal'

const DeleteApiKeyButton = ({onSuccess, apiKey}) => {

  const [isShowing, toggle] = useModal()

  const handleClose = (success=false) => {
    if (success) onSuccess()
    toggle()
  }

  return (
    <>
      <Button variant="a" style={{fill:'#ff0000'}} icon="delete" onClick={toggle}></Button> 
      <DeleteApiKeyModal show={isShowing} onClose={handleClose} apiKey={apiKey} />    
    </>
  )
}


export default DeleteApiKeyButton