import React, {useEffect, useState} from 'react'
import Modal, {ModalSection} from '../../components/Modal/Modal';
import Input from '../../components/Input/Input'
import styles from './AddSimDataModal.module.scss'
import Button from '../../components/Button/Button'
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import {toast} from 'react-toastify'
import productService from '../../services/productService';
import { toCurrency } from '../../helpers/currency';
import Loader from '../../components/Loader/Loader';
import { Alert, Form } from 'react-bootstrap';
import { PayPalButtons } from '@paypal/react-paypal-js';
import paypalOrderService from '../../services/paypalOrderService';

const AddSimDataModal = ({selected = [], isShowing, onClose}) => {
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState({})
  const [costs, setCosts] = useState(null)
  const [discount, setDiscount] = useState('')
  const [iccids, setIccids] = useState([])
  const {activeAccount} = useAuth()
  const [submitting, setSubmitting] = useState(false)
  const {t} = useTranslation()

  useEffect( () => {
    setIccids(selected)
  }, [selected])

  useEffect( () => {
    setValues({
      name: '',
      iccid: '',
      discount: ''
    })
    setCosts(null)
    setDiscount("")
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccount, isShowing])


  useEffect( () => {
    if ( isShowing ) {
      productService.getBundlePricing(selected.length, values.discount ?? null).then( res => {
        if ( res.ok ) {
          setCosts(res.data)
        }
      })
    }
  }, [selected, values.discount, isShowing])
  
  const handleDiscountChange = e => {
    setDiscount(e.target.value);
  }

  const applyDiscount = () => {
    productService.getBundlePricing(selected.length, discount).then( res => {
      if ( res.ok ) {
        setValues({
          ...values,
          discount: discount
        })
        setCosts( res.data)
        setErrors({})

      } else if( res.data && res.data.errors ) {
        setErrors(res.data.errors)
      }
    })
  }

  const [paypalError, setPaypalError] = useState();
  const handleCreateOrder = (data, actions) => {
    return new Promise((resolve, reject) => {
      const args = {
        "iccid": [...iccids],
        "discount": values.discount ?? null
      }
      
      productService.singleBundlePurchase(args)
        .then( response => {
          if (!response.ok || !response.data?.paypal_order_id) reject();
        
          const paypalID = response.data.paypal_order_id;
          paypalOrderService.createOrder(paypalID)
            .then(res => {
                resolve(res.data.id);
            })
            .catch(err => {
              toast.error(t('toasts.bundlePurchaseError'))
              setSubmitting(false);
              reject();
            })
        })
        .catch(err => {
          setSubmitting(false)
          toast.error(t('toasts.unknownPaymentError'))
          reject()
        })
    });
  };
  const handlePaymentStarted = (data, actions) => {
    setErrors({});
    setPaypalError(undefined);
    setSubmitting(true);
  }
  const handlePaymentError = () => {
    setSubmitting(false)
    setPaypalError(t('paypal.error.default'));
  }
  const handlePaymentCancel = () => {
    setSubmitting(false)
    setPaypalError(t('paypal.cancel.default'));
  }
  const handlePaymentApproved = (data, actions) => {
    const processPaymentURL = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_PROCESS_PAYMENT_ENDPOINT}?paypal-order-id=${data.orderID}`;
    window.location.href = processPaymentURL;
  }

  return (
    <Modal title={t('connectivity.sims.addDataModal.title')} show={isShowing} onClose={onClose}>
      <ModalSection size="xs">
        <div className={styles.tagWrap}>
          { iccids.map( iccid => (
            <span key={iccid} className={styles.tag}>{ iccid }</span>
            ))}
        </div>
      </ModalSection>
      <ModalSection size="xs">
        <span className="text-muted mb-2">Order:</span>
        <div className={styles.orderDetails}>
          <div className={styles.orderLine}>
            <span>{iccids.length} x { t('connectivity.sims.addDataModal.planName') }</span>
            <span>{ costs ? toCurrency(costs.subTotal, activeAccount.billing.currency) : <Loader width={10} /> }</span>
          </div>
          <div className={styles.orderLine}>
            <span>{iccids.length} x { t('connectivity.sims.addDataModal.tax') }</span>
            <span>{ costs ? toCurrency(costs.vatTotal, activeAccount.billing.currency) : <Loader width={10} /> }</span>
          </div>
          {(costs && costs.discountTotal > 0) && (
            <div className={styles.orderLine}>
              <span>{costs.discountCode} - { t('connectivity.sims.addDataModal.discountApplied') } - {costs.discountPercentage}%</span>
              <span>{costs ? toCurrency(costs.discountTotal, activeAccount.billing.currency) : <Loader width={10} /> }</span>
            </div>
          )}
        </div>
        
        <div>
            <label>{t('connectivity.sims.addDataModal.discountHeader')}</label>
            <div className="d-flex">
            <Input containerClassName="w-100" slim name="discount" value={discount} onChange={handleDiscountChange} placeholder={t('connectivity.sims.addDataModal.discountInput')} />
            <Button className="ml-2" onClick={applyDiscount}>Apply Discount</Button>
            </div>
            {(errors && errors.discount) && (
              <div className="pt-2">
                <Alert variant="warning"><small>{errors.discount}</small></Alert>
              </div>
            )}
        </div>

      </ModalSection>     
      <ModalSection size="xs">
        <div className={styles.orderLine}>
          <span>{iccids.length} x { t('connectivity.sims.addDataModal.orderTotal') }</span>
          <span>{ costs ? toCurrency(costs.total, activeAccount.billing.currency) : <Loader width={10} /> }</span>
        </div>
      </ModalSection>

      <div className="text-right">
        {paypalError && (
          <Form.Text className="text-center text-danger mt-0 mb-2">{paypalError}</Form.Text>
        )}
        <PayPalButtons
          onClick={handlePaymentStarted}
          onCancel={handlePaymentCancel}
          onError={handlePaymentError}
          createOrder={handleCreateOrder}
          onApprove={handlePaymentApproved}
        />
        <Button disabled={submitting} fullWidth variant="link" onClick={onClose}>{t('misc.return')}</Button>
      </div>
    </Modal>
  ) 
}

export default AddSimDataModal;