import React from 'react'
import Button from '../../components/Button/Button'
import styles from './ActivateSimsSplash.module.scss'
import useModal from '../../hooks/useModal'
import ActivateSimModal from '../../containers/ActivateSimModal/ActivateSimModal'
import { useTranslation } from 'react-i18next';
import AddSimModal from '../../containers/AddSimModal/AddSimModal'
import Loader from '../Loader/Loader'

const ActivateSimsSplash = ({varient=null, accountType}) => {

  const [isSimModalShowing, toggleSimModal] = useModal()
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.main}>
        {!accountType ? <Loader /> : (
          <>
            <span className={styles.header}>{ accountType === 'single_bundle' ? t('header.provisionSingleHeader') : t('header.provisionHeader') }</span>
            <Button icon='twinkle' variant="light" onClick={toggleSimModal}>{!accountType ? <Loader type="text" /> : accountType === 'single_bundle' ? t('header.provisionSingleButton') : t('header.provisionButton')}</Button>
          </>
        )}
      </div>

      { accountType === 'single_bundle' ? (
        <AddSimModal isShowing={isSimModalShowing} onClose={toggleSimModal} />
      ) : 
        <ActivateSimModal isShowing={isSimModalShowing} onClose={toggleSimModal} />
      }
    </>
  )
}

export default ActivateSimsSplash