import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationsEN from '../assets/locales/en/translation.json'
import translationsES from '../assets/locales/es/translation.json'

// the translations
const resources = {
  en: {
    translation: translationsEN
  },
  es: {
    translation: translationsES
  }
};

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;