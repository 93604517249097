import React from 'react'
import styles from './Heading.module.scss'
const Heading = ({type='section', noMargin=false, headerStyle=null, children}) => {

  const generateMargins = () => {
    if (!noMargin) return ''
    switch (noMargin) {
      case 'top':
        return styles.noTopMargin
      case 'bottom':
        return styles.noBottomMargin
      default:
        return styles.noMargin
    }
  }

  switch (type) {

    case 'page':
      return <h2 className={`${styles.pageHeader} ${styles[headerStyle]} ${generateMargins()}`}>{children}</h2>
    case 'section':
      return <h3 className={`${styles.sectionHeader} ${styles[headerStyle]} ${generateMargins()}`}>{children}</h3>
    case 'banner':
      return <h3 className={`${styles.bannerHeader} ${styles[headerStyle]} ${generateMargins()}`}>{children}</h3>
    case 'card':
      return <h3 className={`${styles.cardHeader} ${styles[headerStyle]} ${generateMargins()}`}>{children}</h3>
    case 'sub':
      return <h3 className={`${styles.subSectionHeader} ${styles[headerStyle]} ${generateMargins()}`}>{children}</h3>
    default:
      return <h3 className={generateMargins()}>{children}</h3>
  }
}

export default Heading