import React, { useEffect, useState } from 'react'
import {NavLink} from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './SimsOverView.module.scss'
import BigText from '../../components/BigText/BigText'
import Button from '../../components/Button/Button'
import Loader from '../../components/Loader/Loader'
import assetService from '../../services/assetService';
import AddSimModal from '../AddSimModal/AddSimModal';
import ActivateSimModal from '../ActivateSimModal/ActivateSimModal';
import useModal from '../../hooks/useModal'
import Heading from '../../components/Heading/Heading';
import { useTranslation } from 'react-i18next';

const SimsOverView = ({active, onSimActivated}) => {
  const [summary, setSummary] = useState({})
  const [isSimModalShowing, toggleSimModal] = useModal()
  const {t} = useTranslation()
  
  useEffect( () => {
    assetService.summary().then( response => {
      if (response.ok) {
        setSummary(response.data)
      }
    })
  }, [])

  const toggleActivateSimModal = success => {
    toggleSimModal()
    if (success) {
      assetService.summary().then( response => {
        if (response.ok) {
          setSummary(response.data)
        }
      })
      onSimActivated()
    }
  }

  

  return (
    <div className={styles.wrap}>
      <Container>
        <Row>
          <Col xs='8'>
            <Heading>{t('dashboard.overview.header')}</Heading>
            {/* <BigText inline value={summary.usage !== undefined ? toReadableData(summary.usage, '', 1) : null} label={t('dashboard.overview.data')} /> */}
            <BigText inline value={summary.active !== null ? summary.active : null} label={t('dashboard.overview.active')} />
            <BigText inline value={summary.inactive !== null ? summary.inactive : null} label={t('dashboard.overview.inactive')} />
            {(active && active.billing.dataPackageType !== 'single_bundle') && (
            <BigText inline value={summary.suspended !== null ? summary.suspended : null} label={t('dashboard.overview.suspended')} />
            )}
          </Col>
          <Col className={styles.actionsRight} xs='4'>
            <NavLink to="/connectivity/order-sims">
              <Button icon='order' variant="light">{t('dashboard.overview.orderButton')}</Button>
            </NavLink> 
            {!active ? (
              <Button icon='twinkle'><span className="px-3"><Loader size="sm" /></span></Button>
            ) : (
              <Button icon='twinkle' onClick={toggleSimModal}>{active.billing.dataPackageType === 'single_bundle' ? t('dashboard.overview.addButton') : t('dashboard.overview.activateButton')}</Button>
            )}
          </Col>
        </Row>
      </Container>

            {active && ( 
              active.billing.dataPackageType === 'single_bundle' ? (
                <AddSimModal isShowing={isSimModalShowing} onClose={toggleActivateSimModal} />
                // <AddSimDataModal isShowing={isSimModalShowing} onClose={toggleActivateSimModal} />
              ) : (
                <ActivateSimModal isShowing={isSimModalShowing} onClose={toggleActivateSimModal} />
              ))
            }
    </div>
  )
}

export default SimsOverView;