import React from 'react'
import {useRouteMatch} from 'react-router-dom'
import styles from './App.module.scss'
import Main from '../Main/Main'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { usePayPalScriptReducer } from '@paypal/react-paypal-js'
import { useAuth } from '../../contexts/AuthContext'
import { useEffect } from 'react'

const AppLayout = ({match}) => {
  const inviteScreens = useRouteMatch('/invite');
  const login = useRouteMatch('/login');
  const passwordReset = useRouteMatch('/password-reset');
  const resetPassword = useRouteMatch('/reset-password');
  const selectAccount = useRouteMatch('/select-account');

  const noDashboard = login || passwordReset
  const noHeaders = login || passwordReset || resetPassword || inviteScreens || selectAccount
  const dark = inviteScreens || selectAccount || resetPassword
  const {activeAccount} = useAuth();

  const [{ options }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    if (activeAccount && activeAccount.billing?.currency && activeAccount.billing.currency !== options.currency) {
      dispatch({
        type: "resetOptions",
        value: {
            ...options,
            currency: activeAccount.billing.currency,
        },
    });
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccount])

  return (
    <div className={`${styles.app} ${!noDashboard && styles.dashboard}`}>
      <Header full={!noHeaders} dark={dark} />
      <Main />
      {!noHeaders && (
        <Footer />
        )}
    </div>
  );
}

export default AppLayout;