import React, {useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container'
import {Row, Col} from '../../components/Grid/Grid';
import Loader from '../../components/Loader/Loader'
import { toast } from 'react-toastify';
import {motion, AnimatePresence} from 'framer-motion'
import userService from '../../services/userService';

import SuccessScreen from './progress/SuccessScreen'
import RejectScreen from './progress/RejectScreen'

import ExistingUserSignup from './variants/ExistingUserSignup'
import NewUserSignup from './variants/NewUserSignup'

const InviteLayout = ({match, location}) => {
  const [progress, setProgress] = useState(null)
  const [invite, setInvite] = useState(null)

  useEffect( () => {
    if (match.params.token) {
      userService.getInvite(match.params.token).then( response => {
        if (response.ok) {
          setInvite({
            token: match.params.token,
            ...response.data
          })
        } else {
          handleProgress('error')
          toast.warning('There was a problem retrieving the invite data.')
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleProgress = status => {
    setProgress(status)
  }

  const getProgressState = () => {
    if (!progress) return null

    switch (progress) {
      case 'creation':
        return <SuccessScreen create={true}/>
      case 'addition':
        return <SuccessScreen create={false} />
      case 'reject':
        return <RejectScreen />
      case 'error':
        return (
          <div style={{textAlign: 'center'}}>
            <h3>There was a problem</h3>
            <p>We couldn't find this invitation on our system.</p>
            <p>Please check your invitation URL or <a href="mailto:support@luner.io">contact us</a> for further help.</p>
          </div>
          )
      default:
        return null
    }
  }

  const anims = {
    initial: {y: -20, opacity: 0},
    show: {y: 0, opacity: 1},
    hide: {y: 20, opacity: 0},
  }

  return ( 
    <Container>
      <Row>
        <Col xs="12" className={'text-center'}>
          <AnimatePresence exitBeforeEnter>
          { progress ? (
            <motion.div variants={anims} key='progress' initial='initial' animate='show' exit='hide'>
              {getProgressState()}
            </motion.div>
          ) : (
            <>
              { invite ? (
                <>
                  { invite.userExists ? (
                    <motion.div variants={anims} key='existinguser' initial='initial' animate='show' exit='hide'>
                      <ExistingUserSignup invite={invite} onProgress={handleProgress} />  
                    </motion.div>
                  ) : (
                    <motion.div variants={anims} key='newuser' initial='initial' animate='show' exit='hide'>
                      <NewUserSignup invite={invite} onProgress={handleProgress} />
                    </motion.div>
                  )}
                </>
              ) : (
                <motion.div variants={anims} key='newuser' initial='initial' animate='show' exit='hide'>
                  <Loader />
                </motion.div>
              )}
            </>
          )}
          </AnimatePresence>   
        </Col>
      </Row>
    </Container>
  )
}

export default InviteLayout