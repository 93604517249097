import React, {useState} from 'react'
import Table from '../../components/Table/Table'
import {toReadableDateTime} from '../../helpers/data'
import ConfirmButton from '../../components/ConfirmButton/ConfirmButton'
import ConfirmInviteDeleteModal from './ConfirmInviteDeleteModal'
import useModal from '../../hooks/useModal'
import Loader from '../../components/Loader/Loader'
import { useTranslation } from 'react-i18next'

const InviteTable = ({invites, onDataRefresh}) => {
  const [targetInvite, setTargetInvite] = useState(null)
  const [isShowing, toggle] = useModal()
  const {t} = useTranslation()

  const userHeader = [
    {name: t('settings.users.invites.headers.email')},
    {name: t('settings.users.invites.headers.sent')},
    {name: t('settings.users.invites.headers.actions')}
  ]

  const handleDeleteInvite = invite => {
    setTargetInvite(invite)
    toggle()
  }

  const handleDeleteInviteClose = (success=false) => {
    if (success) {
      onDataRefresh()
    }
    toggle()
  }

  return (
    <>
      <Table headers={userHeader}>
        {invites ? (
          invites.length > 0 ? (
            invites.map (user => (
              <tr key={user.email}>
                <td>{user.email}</td>
                <td>{toReadableDateTime(user.created_at, 'yyyy-LL-dd HH:mm:ss')}</td> { user.createdAt }
                <td><ConfirmButton variant="a" buttonStyle="danger" icon="delete" data={user} onClick={handleDeleteInvite}>{t('settings.users.invites.remove')}</ConfirmButton></td>
              </tr>
            ))
          ) : (
            <tr><td colSpan="3">{t('settings.users.invites.noInvitesFound')}</td></tr>
          )
        ) : (
          <tr>
            <td><Loader type="text" width={70} /></td>  
            <td><Loader type="text" width={60} /></td>  
            <td><Loader type="text" width={50} /></td>  
          </tr>
        )}
      </Table>
      <ConfirmInviteDeleteModal isShowing={isShowing} onClose={handleDeleteInviteClose} user={targetInvite} />
    </>
  )
}

export default InviteTable