import React, {useState, useEffect} from 'react';
import Card from '../../components/Card/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import useForm from '../../hooks/useForm';
import {Link} from 'react-router-dom';
import {useAuth} from '../../contexts/AuthContext';
import Alert from 'react-bootstrap/Alert';
import { useTranslation, Trans } from 'react-i18next';

const LoginLayout = () => {
  const [error, setError] = useState(null)
  const {t} = useTranslation()

  // handle errors
  const handleLoginError = e => {
    if (e.status === 422) {
      setError(t('auth.invalidError'))
    } else {
      setError(t('auth.authError'))
    }
  }

  useEffect(() => {
    setIsInDashboard(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const handleLoginAttempt = () => {
    const args = {
      "email": values.email,
      "password": values.password
    }
    return userLogin(args).catch(handleLoginError)
  }
    
  const { userLogin, setIsInDashboard } = useAuth()
  
  const validateRules = {
    email: 'required|email',
    password: 'required'
  }

  const { handleChange, handleSubmit, isSubmitting, values, errors} = useForm(handleLoginAttempt, validateRules);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={7}>
          <Card title={t('auth.login')}>
            <Form onSubmit={handleSubmit} method='POST'>
              <Row>
                <Col xs="12">
                  <Input type="email" name="email" label={t('misc.email')} placeholder={t('misc.email')} onChange={handleChange} value={values.email || ''} error={errors.email}/>
                  <Input type="password" name="password" label={t('misc.password')} placeholder={t('misc.password')} onChange={handleChange} value={values.password || ''} error={errors.password} autocomplete="current-password" />
                </Col>
                <Col xs="12">
                  <Alert className='mb-3' show={error} variant={'warning'}>{error}</Alert>
                </Col>
                <Col xs="12" align="right">
                <Link to="/password-reset"><Button disabled={isSubmitting} variant="link">{t('auth.resetPassword')}</Button></Link>
                <Button loading={isSubmitting} type="submit" variant="primary">{t('auth.login')}</Button>
                </Col>
                <Col xs="12">
                  <p className="text-muted mb-0 mt-1 text-center"><small><Trans i18nKey="auth.podGroupCustomers" components={{ 
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    podLink: <a href="http://business.luner.io" /> 
                  }} /></small></p>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  )
  
}

export default LoginLayout