import React, {useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container'
import styles from './StoreProductLayout.module.scss'
import {Row} from '../../components/Grid/Grid';
import {Tabs, Tab, TabContent} from '../../components/Tabs/Tabs'
import Col from 'react-bootstrap/Col';
// import ProductAdvertisementBlock from '../../components/ProductAdvertisementBlock/ProductAdvertisementBlock'
import ProductHeader from '../../components/ProductHeader/ProductHeader';
import AddressSelect from '../../components/AddressSelect/AddressSelect'
import Loader from '../../components/Loader/Loader'
import { useTranslation } from 'react-i18next';
import productData from '../../assets/data/products.json'
import ProductPurchaseBlock from '../../components/ProductPurchaseBlock/ProductPurchaseBlock';

const StoreProductLayout = ({match}) => {
  const [product, setProduct] = useState(null)
  const [order, setOrder] = useState(false)
  const [qty, setQty] = useState(1)
  const [addressData, setAddressData] = useState(null)
  const [addressErrors, setAddressErrors] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  
  const {t} = useTranslation()

  useEffect( () => {
    if (match.params.productID) {
      window.scrollTo(0, 0)
      // replace with API call in future
      let p = productData.products.find( p => p.id === match.params.productID)
      setProduct(p)
    }
  }, [match.params.productID])

  const handleCountChange = count => {
    setQty(count)
  }

  const handleAddressErrors = value => {
    setAddressErrors(value)
  }

  const handleResetOrder = () => {
    setOrder(null)
  }

  const [key, setKey] = useState('home');

  return (
    <>
      <ProductHeader 
        product={ product } 
        qty={qty}
        loading={isLoading}
        disabled={order ? true : false}
        onChange={handleCountChange}
        onReset={handleResetOrder}
      />

      <Container>
        <Row spacer="large">
          <Col xs='8'>

              {!order ? (

                <Tabs
                  id="product-tabs"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                >
                  <Tab eventKey="home" title={ t('store.product.tabs.productInformation') }>
                    <TabContent>{product ? product.description.split('\n').map((str, i) => <p key={`${str}-${i}`}>{str}</p>) : <Loader type="text" />}</TabContent>
                  </Tab>
                  <Tab eventKey="specs" title={ t('store.product.tabs.specs') }>
                    <TabContent>
                      <div className={styles.specificationItem}>
                        <strong>{ t('store.product.tabs.specs') }</strong>
                      </div>
                      {product ? product.specification.map( spec => (
                        <div key={spec.title} className={styles.specificationItem}>
                          <strong>{spec.title}:</strong> <span>{spec.value}</span>
                        </div>
                      )) : (
                        <>
                          <div className={styles.specificationItem}><Loader type='text' /></div>
                          <div className={styles.specificationItem}><Loader type='text' /></div>
                          <div className={styles.specificationItem}><Loader type='text' /></div>
                          <div className={styles.specificationItem}><Loader type='text' /></div>
                          <div className={styles.specificationItem}><Loader type='text' /></div>
                        </>
                      )}
                    </TabContent>
                  </Tab>
                </Tabs>

              ) : (
                <AddressSelect onChange={setAddressData} errors={addressErrors} />
              )}

          </Col>
          
          <Col xs='4'>

              <ProductPurchaseBlock 
                product={product}
                qty={qty}
                order={order} 
                createOrder={setOrder}
                onQtyChange={setQty}
                loading={isLoading} 
                setLoading={setIsLoading}
                newAddressDetails={addressData}
                onError={handleAddressErrors}
              />
            
          </Col>
        </Row>

        {/* <Row>
          <Col xs={12}>
            <ProductAdvertisementBlock productID={ product ? (product.id === '1' ? '2' : '1') : null } />
          </Col>
        </Row> */}
      </Container>
    </>    
  )
}

export default StoreProductLayout