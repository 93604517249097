import React from 'react'
import RBButton from 'react-bootstrap/Button'
import styles from './Button.module.scss'

import { ReactComponent as Loading } from '../../assets/icons/loading.svg'
import { ReactComponent as TwinkleIcon } from '../../assets/icons/twinkleIcon.svg'
import { ReactComponent as FileIcon } from '../../assets/icons/fileIcon.svg'
import { ReactComponent as DownloadIcon } from '../../assets/icons/downloadIcon.svg'
import { ReactComponent as CopyIcon } from '../../assets/icons/copyIcon.svg'
import { ReactComponent as AddIcon } from '../../assets/icons/plusIcon.svg'
import { ReactComponent as AltAddIcon } from '../../assets/icons/plusFillIcon.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/deleteIcon.svg'
import { ReactComponent as OrderSimsIcon } from '../../assets/icons/orderSimIcon.svg'

const Button = props => {

  const getIcon = iconStyle => {
    const style = `${styles.icon} ${props.size === 'sm' && styles.smallIcon}`
    switch(iconStyle) {
      case 'twinkle':
        return <TwinkleIcon className={style} />
      case 'order':
        return <OrderSimsIcon className={style} />
      case 'file':
        return <FileIcon className={style} />
      case 'download':
        return <DownloadIcon className={style} />
      case 'copy':
        return <CopyIcon className={style} />
      case 'add':
        return <AddIcon className={style} />
      case 'add-fill':
        return <AltAddIcon className={style} />
      case 'delete':
        return <DeleteIcon className={style} />
      case 'go':
        return <AddIcon className={`${style} ${styles.goIcon}`} />
      default:
        return null
    }
  }

  const styling = () => {
    let styling = (props.append) ? [styles.buttonRight] : [styles.button]
    if (props.className) styling.push(props.className)
    if (props.fullWidth) styling.push(styles.fullWidth)
    if (props.loading) styling.push(styles.loading)
    if (props.variant === 'a') styling.push(styles.bareLink)
    if (props.variant === 'cardLink') styling.push(styles.cardLink)
    return styling.join(' ')
  }

  return (
    <RBButton type={props.type} size={props.size} className={styling()} disabled={props.loading || props.disabled} onClick={props.onClick} variant={props.variant === 'a' ? 'link' : props.variant} value={props.value} style={props.style}>
      {props.icon && getIcon(props.icon)}
      {props.prepend && getIcon(props.prepend)}
      {props.loading && <Loading className={styles.loadingIcon} />}
      <span>{props.children}</span>
      {props.append && getIcon(props.append)}
    </RBButton>
  )
}

export default Button