import React, {useState, useEffect} from 'react'
import Container from 'react-bootstrap/Container'
import {Row, Col} from '../../components/Grid/Grid'
import Card from '../../components/Card/Card'
import Button from 'react-bootstrap/Button';
import PageHeader from '../../components/PageHeader/PageHeader'
import {Link, useParams} from 'react-router-dom'
import orderService from '../../services/orderService'
import Loader from '../../components/Loader/Loader'
import { useTranslation } from 'react-i18next';
import ProductPurchaseBlock from '../../components/ProductPurchaseBlock/ProductPurchaseBlock';
import { formatAddress } from '../../helpers/address';
import { toast } from 'react-toastify';
import { toReadableDateTime } from '../../helpers/data';
import {useAuth} from '../../contexts/AuthContext'
import { toCurrency } from '../../helpers/currency';
import styles from './OrderSuccessLayout.module.scss'
import billingService from '../../services/billingService';
import Input from '../../components/Input/Input';

const OrderSuccessLayout = () => {
  const [order, setOrder]  = useState(null)
  const [downloadInvoice, setDownloadInvoice] = useState('-')
  const [downloading, setDownloading] = useState(false)
  let { orderId } = useParams();
  const {t} = useTranslation()
  const {activeAccount} = useAuth()

  useEffect( () => {
    orderService.getOrder(orderId)
    .then (response => {
      setOrder(response.data)
    }).catch( e => {
      toast.error(t('toasts.orderRetrieveError'))
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  const calculateTotalShipping = () => {
    const t = order.shipments.reduce ( (acc, curr) => acc + curr.delivery.total, 0 )
    return toCurrency(t, activeAccount.billing.currency)
  }

  const handleDownloadInvoice = e => {
    setDownloadInvoice(e.target.value)
    setDownloading(true)
    billingService.downloadInvoice(e.target.value)
    .then( response => {
      if (response.ok) {
        window.open(response.data.redirect_url, '_blank')
      }
      
      if (response.status === 404) {
        toast.error(t('toasts.generateInvoiceError'))
      }
      setDownloading(false)
      setDownloadInvoice('-')
    })
  }


  return (
    <>
      <PageHeader title={t('order.success.header')} slim subtitle={
        <div className="pt-2">
          <p className="h6 text-primary text-uppercase">{t('order.success.order')} #{order ? order.id : ''}</p>
          <p className="h6 text-muted">{t('order.success.subtext')}</p>
          <Link to="/">
            <Button>{t('misc.returnHome')}</Button>
          </Link>
        </div>
      }>
      </PageHeader>
      <Container>
        <Row spacer="small" align="bottom">

          <Col xs={6}>
            <h3 className="mb-1">Order Details</h3>
            <p>Order Date: {order && toReadableDateTime(order.shipments[0].invoice.createdAt, null, 'DATE_SHORT')} <span className="px-2">-</span> Order #{order ? order.id : ''}</p>
          </Col>
          <Col xs={6} align="right">
            <Input disabled={!order || downloading} type='select' onChange={handleDownloadInvoice} value={downloadInvoice} className={styles.styledSelect}>
              <option disabled value='-'>Download Invoices</option>
              {order && order.shipments && order.shipments.map( option => (
                <option disabled={option.invoice === null} value={option.invoice ? option.invoice.id : null}>{option.invoice.id || t('misc.unavailable')}</option>
              ))}
            </Input>
          </Col>
        </Row>

        <Row>
          <Col xs={6}>
            <Card title={t('order.summary.header')}>
              {order ? order.shipments.map( (shipment, i) => ( 
                <div className={styles.orderDetail} key={`shipment-${i}`}>
                  <span>{ `${t('misc.shipment')} #${i + 1}` }</span><span>{(activeAccount && activeAccount.billing.currency) ? toCurrency(shipment.invoice.subTotal, activeAccount.billing.currency) : <Loader type="text"/>  }</span>
                </div>
              )) : (
                <div className={styles.orderDetail}>
                  <Loader type='text' width={30} /><Loader type='text' width={10}/>
                </div>
              )}

              <hr />
              
              <div className={styles.orderDetail}>
                <span>{ t('order.summary.totalShipping') }</span>
                {order ? (
                  <span>{(activeAccount && activeAccount.billing.currency) ? calculateTotalShipping() : <Loader type='text' width={10}/>}</span>
                ) : (
                  <Loader type='text' width={10}/>
                )}
              </div>
              <div className={styles.orderDetail}>
                <span>{ t('order.summary.totalTax') }</span>
                {order ? (
                  <span>{(activeAccount && activeAccount.billing.currency) ? toCurrency(order.vatTotal, activeAccount.billing.currency) : <Loader type='text' width={10}/>}</span>
                ) : (
                  <Loader type='text' width={10}/>
                )}
              </div>
              <div className={styles.orderDetail}>
                <span>{ t('order.summary.total') }</span>
                {order ? (
                  <span>{(activeAccount && activeAccount.billing.currency) ? toCurrency(order.total, activeAccount.billing.currency) : <Loader type='text' width={10}/>}</span>
                ) : (
                  <Loader type='text' width={10}/>
                )}
              </div>
            </Card>
          </Col>

          <Col xs={6}>
            <ProductPurchaseBlock order={order} review />
          </Col>
        </Row>
        <Row spacer>
          <Col className="py-3" xs={12}>
            <hr/>
          </Col>
          <Col xs={6}>
            <Card section title={t('account.address.shipping.header')}>
              {order ? ( 
                <div>{formatAddress(order.shippingAddress)}</div> 
              ) : (
                <>
                  <Loader type='text' />
                  <Loader type='text' />
                  <Loader type='text' />
                  <Loader type='text' />
                  <Loader type='text' />
                </>
              )}
            </Card>
          </Col>
          <Col xs={6}>
            <Card section title={t('account.address.billing.header')}>
              {order && order.shipments && order.shipments[0] && order.shipments[0].invoice ? ( 
                <div>{formatAddress(order.shipments[0].invoice.billingAddress)}</div> 
              ) : (
                <>
                  <Loader type='text' />
                  <Loader type='text' />
                  <Loader type='text' />
                  <Loader type='text' />
                  <Loader type='text' />
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>   
    </>
  )
}

export default OrderSuccessLayout