import React, {useEffect} from 'react'
import Modal from '../../../../../components/Modal/Modal';
import Input from '../../../../../components/Input/Input'
import useForm from '../../../../../hooks/useForm'
import {Row, Col} from '../../../../../components/Grid/Grid'
import Button from '../../../../../components/Button/Button'
import {toast} from 'react-toastify'
import assetService from '../../../../../services/assetService';
import { useTranslation } from 'react-i18next';

const InviteUserModal = ({isShowing, onClose, asset}) => {
  const {t} = useTranslation()

  useEffect( () => {
    setDefaults({
      name: asset ? asset.name : ''
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset])

  const validateRules = {
    name: 'required',
  }

  const handleSimNameUpdate = () => {
    return assetService.updateAsset(asset.iccid, values)
    .then( response => {
      if (response.ok) {
        onClose(response.data)
        toast.success(t('toasts.updateSim'))
      } else {
        onClose()
        toast.warning(t('toasts.updateSimError'))
      }
    })
    .catch( err => {
      console.warn(err)
    })
  }

  const {handleChange, handleSubmit, values, errors, setDefaults, isSubmitting} = useForm(handleSimNameUpdate, validateRules);
 
  return (
    <Modal title={t('simOverview.nameModal.title')} show={isShowing} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs="12">
            <Input name="name" label={`${t('simOverview.nameModal.label')}:`} placeholder={!asset ? t('simOverview.nameModal.gettingDetails') : t('simOverview.nameModal.gettingDetails')} onChange={handleChange} value={values.name} error={errors.name} disabled={!asset || isSubmitting}/>
          </Col>
          <Col xs="12" align="right">
            <Button type="submit" loading={!asset || isSubmitting}>{!asset ? t('simOverview.nameModal.buttonGettingInfo') : t('simOverview.nameModal.button')}</Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default InviteUserModal;