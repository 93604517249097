import React from 'react'
import Modal from '../../../components/Modal/Modal'
import Heading from '../../../components/Heading/Heading'
import Button from '../../../components/Button/Button'
import {Row, Col} from '../../../components/Grid/Grid'
import { useTranslation, Trans } from 'react-i18next'

const InvoicePaymentModal = ({isShowing, onClose, success}) => {
  const {t} = useTranslation()

  const successText = {
    title: t('invoices.payment.success.title'),
    header: t('invoices.payment.success.header'),
    body: t('invoices.payment.success.body'),
    
  }
  const failureText = {
    title: t('invoices.payment.failure.title'),
    header: t('invoices.payment.failure.header'),
    body: null
  }

  let text = (success===true) ? successText : failureText

  return (
    <Modal size="lg" title={text.title} show={isShowing} onClose={onClose}>
      <Heading>{text.header}</Heading>
      {success ? (
        <p>{text.body}</p>
      ) : (
        <p>
          <Trans
            i18nKey=""
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            components={{ helpLink: <a href="mailto:support@luner.io"/> }}
          />
        </p>
      )}

      <Row>
        <Col align="right">
          <Button size="lg" className="text-uppercase" onClick={onClose}>{t('misc.returnInvoices')}</Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default InvoicePaymentModal