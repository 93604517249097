import { request, generateParameters } from './requestService';


export const createAccount = body => {
  const args = {
    method: 'POST',
    body: JSON.stringify(body)
  }
    return request('/accounts', args)
}

export const getActiveAccount = body => {
  return request(`/accounts`)
}

export const cdrs = params => {
  return request(`/accounts/cdrs${generateParameters(params)}`)
}

export const topup = value => {
  const args = {
    method: 'POST',
    body: JSON.stringify(value)
  }
  return request('/accounts/topup', args)
}

export const updateInfo = values => {
  const args = {
    method: 'PUT',
    body: JSON.stringify(values)
  }
  return request('/accounts', args)
}

export const updateAddress = (id, values) => {
  const args = {
    method: 'PUT',
    body: JSON.stringify(values)
  }
  return request(`/accounts/address/${id}`, args)
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  createAccount,
  getActiveAccount,
  cdrs,
  topup,
  updateInfo,
  updateAddress
}