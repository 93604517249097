import React from 'react'
import styles from './Checkbox.module.scss'

const Checkbox = ({name, label, value, inline=false, onChange, disabled=false, selected}) => {
  return (
    <label className={styles.wrap}>
      <input type="checkbox" className={styles.input} checked={selected} onChange={onChange} name={name} value={value} disabled={disabled} />
      <span className={styles.check}></span>
      <span className={styles.label}>{label}</span>
    </label>
  )
}

export default Checkbox