import React, {useState, useEffect} from 'react'
import {Row, Col} from '../../../../../components/Grid/Grid'
import LocationMap from '../../../../../components/LocationMap/LocationMap'
import Card from '../../../../../components/Card/Card'
import Button from '../../../../../components/Button/Button'
import Loader from '../../../../../components/Loader/Loader'
import InfoBlock from '../../../../../components/InfoBlock/InfoBlock'
import { toReadableData, toReadableDateTime } from '../../../../../helpers/data'
import productService from '../../../../../services/productService'
import useModal from '../../../../../hooks/useModal'
import DataLimitModal from '../SimControls/modals/DataLimitModal/DataLimitModal'
import Input from '../../../../../components/Input/Input'
import InactiveSimCard from '../../../../../components/InactiveSimCard/InactiveSimCard'
import Modal from '../../../../../components/Modal/Modal'
import Badge from '../../../../../components/Badge/Badge'
import assetService from '../../../../../services/assetService'
import { useAuth } from '../../../../../contexts/AuthContext'
import {toast} from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { toCurrency } from '../../../../../helpers/currency'
import DateTime from 'luxon/src/datetime.js'

const SimInformation = ({asset, onAssetUpdate}) => {
  const [plans, setPlans] = useState(null)
  const [targetPlan, setTargetPlan] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [isDataLimitsShowing, toggleDataLimits] = useModal()
  const [isPlanChangeShowing, togglePlanChange] = useModal()
  const {t} = useTranslation()

  const {activeAccount} = useAuth()

  useEffect( () => {
    productService.products().then(response => {
      if (response.ok) {
        setPlans(response.data)
      }
    })
  }, [])

  useEffect( () => {
    if (asset && asset.product && asset.product.id) {
      setTargetPlan(asset.product.id)
    }
  }, [asset])

  const getCurrentPlan = () => {
    if (asset && plans) {
      if (asset.product && asset.product.id) {
        return (
          <Input type="select" name="status" placeholder={t('simOverview.info.namePlaceholder')} onChange={handlePlanChange} value={targetPlan}>
            {plans.map (plan => (
              <option key={plan.id} value={plan.id}>{plan.name} {(plan.type==="prepay" && activeAccount) ? `(${toCurrency(plan.perMbCost, activeAccount.billing.currency)}/MB)` : ''}</option>
            ))}
          </Input>
        )
      } else {
        return <small>{t('misc.unavailable')}</small>
      }
    } else {
      return <Loader type="text" width={40} />
    }
  }

  const handleDataLimitsClose = (result=null) => {
    toggleDataLimits()
    if (result) {
      onAssetUpdate(result)
    }
  }

  const handlePlanChange = e => {
    setTargetPlan(e.target.value)
    togglePlanChange()
  }

  const handlePlanChangeCancel = () => {
    togglePlanChange()
    setTargetPlan(asset.product.id)
  }

  const handlePlanChangeConfirm = val => {
    setSubmitting(true)
    const args = {
      name: asset.name,
      productId: targetPlan
    }
    
    if (activeAccount && activeAccount.billing.dataPackageType === 'aggregate') {
      assetService.transferAggPlan(asset.iccid, args).then( response => {
        setSubmitting(false)
        toast.success(t('toasts.planQueued'))
        togglePlanChange()
      })
    } else {
      assetService.updateAsset(asset.iccid, args).then( response => {
        setSubmitting(false)
        toast.success(t('toasts.planChanged'))
        togglePlanChange()
      })
    }
    
  }

  const getStatus = () => {
    let status = null;
    if ( 
      (activeAccount && activeAccount.billing.dataPackageType === 'single_bundle') &&
      (asset.subscription.expiresAt && (DateTime.fromISO(asset.subscription.expiresAt) < DateTime.now()))
    ) {
      status = 'expired';
    } else {
      status = asset.status;
    }

    switch(status) {
      case 'active':
        return <span className='text-success text-uppercase'>{t('misc.active')}</span>;
      case 'suspended':
        return <span className='text-danger text-uppercase'>{t('misc.suspended')}</span>;
      case 'inactive':
        return <span className='text-muted text-uppercase'>{t('misc.inactive')}</span>;
      case 'expired':
        return <span className='text-muted text-uppercase'>{t('misc.inactive')}</span>;
      default:
        return <small>{t('misc.unavailable')}</small>
    }
  }

  return (
    <>
      <Row>
        <Col xs="8">
          {asset && asset.status === 'inactive' && (
            <InactiveSimCard iccid={asset.iccid} name={asset.name} onActivate={onAssetUpdate} />
          )}
          <Card section title={t('simOverview.tabs.info')}>
            <Row>
              <Col xs="4">

                <InfoBlock label={`${t('misc.status')}:`} value={asset ? getStatus() : <Loader type='text' width={35} />} />
              </Col>
              <Col xs="4">
                <InfoBlock label={t('simOverview.info.activationDate')} value={asset ? (
                  asset.network.activationDate ? (
                    toReadableDateTime(asset.network.activationDate) 
                  ) : (
                    <small>{t('misc.unavailable')}</small>
                  )
                ) : (
                  <Loader type='text' width={90} />
                )} />
              </Col>
              <Col xs="4">
                <InfoBlock label={`${t('misc.iccid')}:`} value={asset ? asset.iccid : <Loader type='text' width={80} />} />
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <InfoBlock label={`${t('misc.msisdn')}:`} value={asset ? asset.msisdn : <Loader type='text' width={70} />} />
              </Col>
              <Col xs="4">
                <InfoBlock label={`${t('misc.imei')}:`} value={asset ? (asset.imei || <small>{t('misc.unavailable')}</small>) : <Loader type='text' width={60} />} />
              </Col>
              {(activeAccount && activeAccount.billing.dataPackageType !== 'single_bundle') && (
              <Col xs="4">
                <InfoBlock label={`${t('misc.dataLimit')}:`}>
                  {asset ? (
                    (asset.dataLimit && asset.status !== 'inactive') ? (
                    <><span className="mr-2">{toReadableData(asset.dataLimit)}</span> <Button variant="a" onClick={toggleDataLimits}>{t('misc.edit')} &gt;&gt;</Button><br/><span className="mt-1 text-small text-muted"><small>(Usage: {toReadableData(asset.dataUsed)})</small></span></>
                      ) : (
                        <small>{t('misc.unavailable')}</small>  
                      )
                    ) : (
                      <Loader type='text' width={40} />
                    )
                  }
                </InfoBlock>
              </Col>
              )}
            </Row>
          </Card>
          

          <Card section title={t('simOverview.info.networkInfo')}>
            <Row>
              <Col xs="4">
                <InfoBlock label={`${t('simOverview.info.dataSession')}:`} value={asset ? (asset.data.activeDataSession.active ? <span className='text-success text-uppercase'>{t('misc.active')}</span> : <span className='text-danger text-uppercase'>{t('misc.inactive')}</span>) : <Loader type='text' width={50} />} />
              </Col>
              <Col xs="4">
                <InfoBlock label={`${t('simOverview.info.lastRegistration')}:`} value={asset ? (asset.network.lastRegistration.lastActive ? toReadableDateTime(asset.network.lastRegistration.lastActive) : <small>{t('misc.unavailable')}</small>) : <Loader type='text' width={75} />} />
              </Col>
              <Col xs="4">
                <InfoBlock label={`${t('simOverview.info.networkRegistered')}:`} value={asset ? (asset.network.lastRegistration.network || <small>{t('misc.unavailable')}</small>) : <Loader type='text' width={50}/>} />
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <InfoBlock label={`${t('simOverview.info.countryRegistered')}:`} value={asset ? (asset.network.lastRegistration.country || <small>{t('misc.unavailable')}</small>) : <Loader type='text' width={35} />} />
              </Col>
              <Col xs="4">
                <InfoBlock label={`${t('simOverview.info.apn')}:`} value={asset ? (asset.data.apn ? asset.data.apn : <small>{t('misc.unavailable')}</small>) : <Loader type='text' width={50}/>} />
              </Col>
              {(activeAccount && activeAccount.billing.dataPackageType !== 'single_bundle') && (
              <Col xs="4">
                <InfoBlock label={`${t('simOverview.info.plan')}:`}>
                  { getCurrentPlan() }
                  {(asset && asset.transfer && asset.transfer.queued) && <Badge type='success' content='Transfer queued' />}
                </InfoBlock>
              </Col>
              )}
            </Row>
            <Row>
              <Col xs="4">
                <InfoBlock label={`${t('settings.account.accountInfo.labels.vpn')}:`} value={asset ? (asset.vpn.active ? <span className='text-success text-uppercase'>{t('misc.active')}</span> : <span className='text-danger text-uppercase'>{t('misc.inactive')}</span>) : <Loader type='text' width={40} />} />
              </Col>
              <Col xs="4">
                <InfoBlock label={`${t('simOverview.info.vpnIp')}:`} value={asset ? (asset.vpn.ip || <small>{t('misc.unavailable')}</small>) : <Loader type='text' width={55} />} />
              </Col>
            </Row>
          </Card>
        </Col>
        
        <Col xs="4">
          <LocationMap iccid={asset ? asset.iccid : null} />
        </Col>
      </Row>
      
      <DataLimitModal show={isDataLimitsShowing} onClose={handleDataLimitsClose} asset={asset ? asset : null} />
      <Modal title={t('simOverview.info.changePlan')} show={isPlanChangeShowing} onClose={togglePlanChange} asset={targetPlan}>
        <Row spacer>
          <Col xs="12">{t('simOverview.info.changePlanDescription')}</Col>
        </Row>
        <Row>
          <Col xs="12" align="right">
            <Button disabled={submitting} variant="link" onClick={handlePlanChangeCancel}>{t('misc.cancel')}</Button>
            <Button loading={submitting} onClick={handlePlanChangeConfirm}>{t('connectivity.sims.planSwitch.confirmButton')}</Button>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default SimInformation