import React from 'react'
import Loader from '../../components/Loader/Loader'
import {motion} from 'framer-motion'
import styles from './BigText.module.scss'

const BigText = ({value=null, label=null, inline=false, horizontal=false}) => {

  const getStyles = () => {
    if (inline) {
      return styles.inline
    } else if (horizontal) {
      return styles.horizontal
    } else {
      return styles.wrap
    }
  }

  const anims = {
    hide: {y: 20, opacity: 0},
    show: {y: 0, opacity: 1}
  }

  return (
    <div className={getStyles()}>
      <span className={styles.value}>{value !== null ? <motion.div variants={anims} initial={'hide'} animate='show'>{value}</motion.div> : <div className={styles.loaderWrap}><Loader /></div>}</span>
      <span className={styles.label}>{label}</span>
    </div>
  )
}

export default BigText