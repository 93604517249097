import React, {useEffect, useState} from 'react'
import assetService from '../../services/assetService'
import Input from '../../components/Input/Input'
import Heading from '../../components/Heading/Heading'
import Loader from '../../components/Loader/Loader'
import Button from '../../components/Button/Button'
import AddSimDataModal from '../AddSimDataModal/AddSimDataModal'
import StatusWindow from '../../components/StatusWindow/StatusWindow'
import Modal, {ModalSection} from '../../components/Modal/Modal'
import {Row, Col} from '../../components/Grid/Grid'
import {toBytes} from '../../helpers/data'
import useModal from '../../hooks/useModal'
import styles from './SimsTable.module.scss'
import { toast } from 'react-toastify'
import useForm from '../../hooks/useForm'
import {useAuth} from '../../contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import {ReactComponent as InformationIcon} from '../../assets/icons/information.svg'
import {ReactComponent as GoIcon} from '../../assets/icons/chevronsRight.svg'
import Popover from '../../components/Popover/Popover'
import { toCurrency } from '../../helpers/currency'

const PlanSwitcher = ({iccid, products, currentProduct, name, queued, status, updateTable}) => {
  const [isPlanChangeShowing, togglePlanChange] = useModal()
  const [selected, setSelected] = useState(null)
  const [queuePlan, setQueuePlan] = useState(null)
  const [isCancelling, setIsCancelling] = useState(false)
  const [hover, setHover] = useState(false)
  const {t} = useTranslation()

  const setHoverOver = () => setHover(!hover)

  const validateRules = {
    productId: 'required',
    dataLimit: 'required'
  }

  useEffect( () => {
    const prod = products.find( ({id}) => id === (currentProduct) )
    if (prod) {
      setDefaults( {productId: prod.id} )
      setSelected(prod)
    } else {
      setSelected(null)
    }
    if (queued) {
      const Qprod = products.find( ({id}) => id === (queued) )
      if (Qprod) {
        setQueuePlan(Qprod)
      } else {
        setQueuePlan(null)
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, queued, currentProduct])

  useEffect( () => {
    setDefaults( {
      dataLimitMultiplier: 'MB'
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {activeAccount} = useAuth()

  const handlePlanChangeConfirm = () => {
    const data = toBytes(values.dataLimit, values.dataLimitMultiplier)  
    const args = { ...values }
    delete args.dataLimitMultiplier
    if (data) args.dataLimit = data

    if (activeAccount && activeAccount.billing.dataPackageType === 'aggregate' && currentProduct) {
      return assetService.transferAggPlan(iccid, args).then( response => {
        if (response.ok) {
          toast.success('Successfully updated SIM plan')
          togglePlanChange()
          updateTable()
        } else {
          toast.warning('There was an error updating your SIM plan')
        }
      })
    } else {
      return assetService.updateAsset(iccid, args).then( response => {
        if (response.ok) {
          toast.success('Successfully updated SIM plan')
          const prod = products.find( ({id}) => id === values.productId )
          if (prod) {
            setSelected(prod)
          }
          togglePlanChange()
          updateTable()
        } else {
          toast.warning('There was an error updating your SIM plan')
        }
      })
    }
  }
  const handlePlanChangeCancel = () => {
    togglePlanChange()
  }

  const handleTransferCancellation = () => {
    setIsCancelling(true)
  }

  const confirmTransferCancellation = () => {
    return assetService.cancelTransfer(iccid).then( response => {
      if (response.ok) {
        toast.success(t('toasts.planCancellationQueued'))
        setIsCancelling(false)
        togglePlanChange()
        updateTable()
      } else {
        toast.warning(t('toasts.planCancellationError'))
      }
    })
  }

  const {handleSubmit: handleCancelSubmit, isSubmitting: isCancellationSubmitting} = useForm(confirmTransferCancellation, {});
  const {handleChange, handleSubmit, values, errors, setDefaults, isSubmitting} = useForm(handlePlanChangeConfirm, validateRules);

  const planSwitcher = [];

  if (!products) {
    planSwitcher.push(<Loader type="text" />)
  } else {
    if ( activeAccount && activeAccount.billing.dataPackageType === "single_bundle" && status !== 'inactive') {
      if ( status === 'expired' || status === 'nearing_data_limit' ) {
        planSwitcher.push(<Button key="expired-button" variant="success" size="sm" append="go" onClick={togglePlanChange}>{t('plans.renewPlan')}</Button>)
      } else if(status === "active") {
        planSwitcher.push(<Button key="edit-button" variant="light" disabled>{ name || <Loader />}</Button>)
      }
    } else if (!selected) {
      if (!currentProduct) {
        planSwitcher.push(<Button key="add" size="sm" append="go" onClick={togglePlanChange}>{t('plans.addSim')}</Button>)
      } else {
        planSwitcher.push(<Loader key="loading" type="text" />)
      }
    } else {
      planSwitcher.push(
      <Popover small text={!queued ? (selected ? <>{selected.name} {(selected.type==="prepay" && activeAccount) ? <small>({toCurrency(selected.perMbCost, activeAccount.billing.currency)}/MB)</small> : ''}</> : <Loader />) : (`${selected ? selected.name : <Loader />} >> ${queuePlan ? queuePlan.name : <Loader />}`)} action="hover" placement="left">
          <div onMouseEnter={setHoverOver} onMouseLeave={setHoverOver} onClick={togglePlanChange} className={queued ? styles.QueuedPlanButton : styles.planButton}>
            <span>{hover ? t('plans.editPlan') : (!queued ? selected ? <>{selected.name} {(selected.type==="prepay" && activeAccount) ? <small className="text-muted">({toCurrency(selected.perMbCost, activeAccount.billing.currency)}/MB)</small> : ''}</> : <Loader /> : t('plans.transfer.queued')) }</span>
            <InformationIcon className={styles.infoIcon} />
            <GoIcon className={styles.goIcon} />
          </div>
        </Popover>
      )
    }

    if ( activeAccount && activeAccount.billing.dataPackageType === 'single_bundle') {
      planSwitcher.push(<AddSimDataModal key="single-bundle-modal" selected={[iccid]} isShowing={isPlanChangeShowing} onClose={togglePlanChange} />)
    } else {
      planSwitcher.push(
        <Modal key="data-modal" title={currentProduct ? t('plans.editSim') : t('plans.addSim')} show={isPlanChangeShowing} onClose={handlePlanChangeCancel}>
          <Row spacer={!queued ? true : 'small'}>
            <Col xs="12">
              <p>{t('plans.switcher.body')}</p>
              {currentProduct && (
                <>
                  <Heading type='sub'>{t('plans.current')}</Heading>
                  {selected ? (
                    <Input label="Current Plan:" value={selected.name + ((selected.type==="prepay" && activeAccount) ? ` (${toCurrency(selected.perMbCost, activeAccount.billing.currency)}/MB)` : '')  || '-'} disabled={true} />
                  ) : (
                    <Loader type="text" />    
                  )}
                </>
              )}
            </Col>
          </Row>
          {!queued ? (
            <form onSubmit={handleSubmit}>
              <ModalSection type="gray">
                <Heading type='mini'> {currentProduct ? t('plans.transferToPlan') : t('plans.addToPlan')}</Heading> 
                <Input className='mb-2' type="select" name="productId" onChange={handleChange} value={values.productId} error={errors.productId} slim>
                  <option value>{t('plans.addToPlanPlaceholder')}</option>
                  { products && products.map ( prod => (
                    <option key={prod.id} value={prod.id}> {prod.name} {(prod.type==="prepay" && activeAccount) ? `(${toCurrency(prod.perMbCost, activeAccount.billing.currency)}/MB)` : ''}</option>
                  ))}
                </Input>
                <Row>
                  <Col xs='7'>
                    <Input type="number" name="dataLimit" label={`${t('connectivity.sims.inactive.dataLimitLabel')}:`} onChange={handleChange} value={values.limit} error={errors.dataLimit} />
                  </Col>
                  <Col xs='5'>
                    <Input type="select" name="dataLimitMultiplier" label onChange={handleChange} value={values.dataLimitMultiplier} error={errors.dataLimitMultiplier}>
                      <option value='MB'>MB</option>
                      <option value='GB'>GB</option>
                    </Input>
                  </Col>
                </Row>

                <div>
                  {currentProduct ? (
                  <StatusWindow type='warning'>
                    <Heading type='mini'>{t('plans.transfer.subheader')}</Heading>
                    {(activeAccount && activeAccount.billing.dataPackageType === 'aggregate') ? (
                      <p>{t('plans.transfer.timeframeAgg')}</p>
                    ) : (
                      <p>{t('plans.transfer.timeframeImmediate')}</p>
                    )}
                  </StatusWindow>
                  ) : activeAccount.billing.dataPackageType === 'aggregate' && (
                  <StatusWindow type='warning'>
                    <Heading type='mini'>{t('misc.warning')}</Heading>
                    <p>{t('plans.transfer.aggWarning')}</p>
                  </StatusWindow>
                  )}
                </div>
              </ModalSection>
                <Row>
                  <Col xs="12" align="right">
                    <Button disabled={isSubmitting} variant="link" onClick={handlePlanChangeCancel}>{t('misc.cancel')}</Button>
                    <Button loading={isSubmitting} type="submit">{currentProduct ? ((activeAccount && activeAccount.billing.dataPackageType === 'aggregate') ? t('plans.transfer.queue') : t('plans.transferSim')) : t('plans.addSim')}</Button>
                  </Col>
                </Row>
            </form>
          ) : (
              <div>
                <Row align='bottom'>
                  <Col xs={7}>
                    <Input label={`${t('plans.transfer.queuedLabel')}:`} disabled value={queuePlan ? queuePlan.name : '-'} />
                  </Col>
                  <Col xs={5}>
                    <Button className="mb-2" variant="danger" onClick={handleTransferCancellation}>{t('plans.transfer.cancel.button')}</Button>
                  </Col>
                </Row>
                  {isCancelling ? (
                  <StatusWindow type='danger'>
                    <Heading type='mini'>{t('plans.transfer.cancel.header')}</Heading>
                    <p>{t('plans.transfer.cancel.body')}</p>
                    <form onSubmit={handleCancelSubmit}>
                    <Button fullWidth variant="danger" type="submit" loading={isCancellationSubmitting}>{t('plans.transfer.cancel.confirm')}</Button>
                    </form>
                  </StatusWindow>
                  ) : (
                  <StatusWindow type='warning'>
                    <Heading type='mini'>{t('plans.transfer.subheader')}</Heading>
                    <p>{t('plans.transfer.timeframeAgg')}</p>
                  </StatusWindow>
                  )}
              </div>
          )}
        </Modal>
      )

    }
  }

  return (
    <>
    {planSwitcher}
    </>
  );

}

export default PlanSwitcher