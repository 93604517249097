import React, {useEffect} from 'react'
import Modal from '../../../Modal/Modal';
import {Row, Col} from '../../../Grid/Grid'
import Input from '../../..//Input/Input'
import useForm from '../../../../hooks/useForm'
import Button from '../../../Button/Button'
import { useTranslation } from 'react-i18next';
import cookieService from '../../../../services/cookieService';
import { toast } from 'react-toastify';
import userService from '../../../../services/userService';

const LanguageSelectModal = ({isShowing, language="en", setUser, onClose}) => {
  const {t} = useTranslation()

  useEffect( () => {
    setDefaults({
      language: language
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing])

  // doesn't need validation
  const validateRules = {}

  const handleLangSet = () => {
    //TODO: hook into API to set language on account

    return userService.update(values).then( response => {
      if (response.ok) {
        if (response.data.language) {
          // create two-year cookie to hold language selection
          const twoYear = new Date(new Date().setFullYear(new Date().getFullYear() + 2))
          cookieService.addCookie('LunerLang', response.data.language, twoYear)
        }
        // set new user data, which will trigger language change
        setUser(response.data)

        // show a toast & close
        toast.success(t('toasts.languageUpdated'))
        onClose()
      } else {
        toast.success(t('toasts.languageUpdatedError'))
      }
    })
    .catch( err => {
      toast.success(t('toasts.languageUpdatedError'))
    })
  }

  const { handleChange, handleSubmit, values, errors, setDefaults, isSubmitting } = useForm(handleLangSet, validateRules);

  return (
    <Modal size="md" title={t('footer.language.header')} show={isShowing} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Row spacer>
          <Col xs="12">
            <p>{t('footer.language.body')}</p>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Input type="select" name="language" label={t('footer.language.selectLabel')} placeholder="Select an option" onChange={handleChange} value={values.language} error={errors.language} disabled={isSubmitting}>
                <option value="en">{t('footer.language.en')} {language === 'en' && `- ${t('footer.language.current')}` }</option>
                <option value="es">{t('footer.language.es')} {language === 'es' && `- ${t('footer.language.current')}` }</option>
            </Input> 
          </Col>
        </Row>
        <Row>
          <Col xs="12" align="right">
            <Button disabled={isSubmitting} variant="light" onClick={onClose}>{t('misc.cancelChanges')}</Button>
            <Button loading={isSubmitting} variant="primary" type="submit">{t('misc.saveChanges')}</Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default LanguageSelectModal;