import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import {input, slimInput, helperText} from './Input.module.scss';

const Input = ({
  name,
  className=null,
  containerClassName=null,
  type='text',
  label=null,
  placeholder="",
  prepend=null,
  append=null,
  helpText=null,
  disabled=false,
  value,
  error=null,
  slim=false,
  onChange = () => {},
  children
}) => {

  return (
    <Form.Group className={[(slim ? slimInput : input), containerClassName].join(' ') } controlId={name}>
      {label && (
      <Form.Label>{label}</Form.Label>
      )}
      <InputGroup>
        {prepend && (
        <InputGroup.Prepend>
          <InputGroup.Text>{prepend}</InputGroup.Text>
        </InputGroup.Prepend>
        )}

        <Form.Control className={className} type={type} step="0.01" as={(['select', 'textarea'].includes(type)) ? type : 'input'} name={name} disabled={disabled} placeholder={placeholder} value={value} onChange={onChange}>
          {(type === 'select' && children) ? children : null}
        </Form.Control>

        {append && (
        <InputGroup.Prepend>
          <InputGroup.Text>{append}</InputGroup.Text>
        </InputGroup.Prepend>
        )}
      </InputGroup>
      {helpText && (
        <Form.Text className={helperText}>{helpText}</Form.Text>
      )}
      { error && (
        <Form.Text className="text-muted">
          {error}
        </Form.Text>
      )}
    </Form.Group> 
  )
}

export default Input