import { request } from './requestService';

export const products = () => {
  return request(`/accounts/products`)
}

export const updateProduct = (id, values) => {
  const args = {
    method: 'PUT',
    body: JSON.stringify(values)
  }
  return request(`/products/${id}`, args)
}

export const upgradeProduct = (id, values) => {
  const args = {
    method: 'PUT',
    body: JSON.stringify(values)
  }
  return request(`/products/${id}/upgrade`, args)
}

export const cancelUpgrade = (id) => {
  const args = {
    method: 'DELETE',
  }
  return request(`/products/${id}/upgrade`, args)
}

export const cancelProduct = (id, values) => {
  const args = {
    method: 'PUT',
    body: JSON.stringify(values)
  }
  return request(`/products/${id}/cancel`, args)
}

export const revokeCancellation = (id) => {
  const args = {
    method: 'DELETE',
  }
  return request(`/products/${id}/cancel`, args)
}

export const getZones = () => {
  return request(`/products/zones`)
}

export const getPricing = () => {
    return request(`/accounts/pricing`)
}

export const getBundlePricing = (qty, discount=null) => {

  const args = {
    method: "POST",
    body: JSON.stringify({
      "quantity": qty,
      "discount": discount
    })
  }
  return request(`/products/single-bundle/pricing`, args)
}

export const createProduct = values => {
  const args = {
    method: 'POST',
    body: JSON.stringify(values)
  }
  return request(`/products`, args)
}

export const singleBundlePurchase = values => {
  const args = {
    method: 'POST',
    body: JSON.stringify(values)
  }
  return request(`/products/single-bundle`, args);
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default  {
  products,
  updateProduct,
  getZones,
  getPricing,
  getBundlePricing,
  createProduct,
  upgradeProduct,
  cancelUpgrade,
  cancelProduct,
  revokeCancellation,
  singleBundlePurchase
}