import React from 'react'
import styles from './QtyInput.module.scss'

const QtyInput = ({value=0, min=0, max=100, disabled=false, onChange, inline=false}) => {
  
  const handleDecreaseValue = () => {
    if (value > min) {
      onChange(value - 1)
    }
  }
  
  const handleIncreaseValue = () => {
    if (value < max) {
      onChange(value + 1)
    }
  }

  const handleTypeValue = e => {
    if (!isNaN(parseInt(e.target.value, 10))) {
      if ((parseInt(e.target.value, 10) <= max) && (parseInt(e.target.value, 10) >= min)) {
        onChange(parseInt(e.target.value, 10))
      } else if(parseInt(e.target.value, 10) > max) { 
        onChange(max)
      } else if(parseInt(e.target.value, 10) < min) {
        onChange(min)
      }
    } else {
      onChange(0)
    }
  }

  return (
    <div className={`${styles.wrap} ${disabled && styles.disabled} ${inline && 'd-inline-flex'}`}>
        <button type="button" className={styles.button} disabled={disabled} onClick={!disabled ? handleDecreaseValue : null}> - </button>
        <input className={styles.input} readOnly={disabled} type="number" value={value} onChange={handleTypeValue} />
        <button type="button" className={styles.button} disabled={disabled} onClick={!disabled ? handleIncreaseValue : null}> + </button>
    </div>
  )
}

export default QtyInput