import {request} from './requestService';

export const getOrder = id => {
  return request(`/shop/order/${id}`)
}

export const orderPayment = (id) => {
  const args = { 
    method: 'POST',
  }
  
  return request(`/shop/order/${id}/pay`, args)
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  getOrder,
  orderPayment
}