import React from 'react';
import {Link} from 'react-router-dom'
import styles from './StoreAdvert.module.scss'
import { useTranslation } from 'react-i18next';

const StoreAdvert = () => {
  const {t} = useTranslation()

  return (
    <Link to="/connectivity/order-sims">
      <div className={styles.wrap}>
        <span className={styles.toptext}>{t('dashboard.ad.subheader')}</span>
        <span className={styles.maintext}>{t('dashboard.ad.header')}</span>
      </div>
    </Link>
  )
}

export default StoreAdvert