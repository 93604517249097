import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import styles from './Popover.module.scss'

const Popover = ({text, temporary=true, action="click", placement="top", small=false, children}) => {
  let trigger = React.createRef();

  const handleHide = () => {
    setTimeout( ()=> {
      if (trigger.current) {
        trigger.current.handleHide()
      }
    }, 1000)
  }

  return (
    <OverlayTrigger 
      trigger={action}
      ref={trigger}
      placement={placement}
      onEntered={temporary ? handleHide : null}
      overlay={<Tooltip><span className={`${styles.text} ${small && styles.small}`}>{text}</span></Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
}

export default Popover
