import React, {useEffect} from 'react'
import Modal from '../../components/Modal/Modal';
import Input from '../../components/Input/Input'
import useForm from '../../hooks/useForm'
import Button from '../../components/Button/Button'
import accountService from '../../services/accountService'
import {toBytes} from '../../helpers/data'
import {useAuth} from '../../contexts/AuthContext'
import {toast} from 'react-toastify'
import {Row, Col} from '../../components/Grid/Grid'
import { useTranslation } from 'react-i18next';

const EditAccountModal = ({isShowing, toggle, onClose}) => {
  const {activeAccount, setActiveAccount} = useAuth()
  const {t} = useTranslation()
  
  useEffect( () => {
    setValues({
      name: activeAccount ? activeAccount.name : null,
      defaultEmail: activeAccount ? activeAccount.billing.defaultEmail : null,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccount])

  const validateRules = {
    name: 'required',
    defaultEmail: 'required|email',
  }

  const handleUpdateAttempt = () => {
    const data = toBytes(values.dataLimit, values.dataLimitMultiplier)  
    
    if (data) values.dataLimit = data

    return accountService.updateInfo(values)
    .then( response => {
      if (response.ok) {
        onClose()
        toast.success('Account details updated successfully!')
        setActiveAccount(response.data)
      }
    })
    .catch( err => {
      console.warn(err)
    })
  }

  const {handleChange, handleSubmit, values, errors, setValues, isSubmitting} = useForm(handleUpdateAttempt, validateRules);
 
  return (
    <Modal title={t('dashboard.accDetails.editAccountDetails')} show={isShowing} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Input name="name" label={t('settings.account.accountInfo.labels.name')} onChange={handleChange} value={values.name} error={errors.name} disabled={isSubmitting}/>
        <Input name="defaultEmail" label={t('settings.account.accountInfo.labels.email')} placeholder={t('misc.emailPlaceholder')} onChange={handleChange} value={values.defaultEmail} error={errors.defaultEmail} disabled={isSubmitting}/>
        <Row>
          <Col align="right">
            <Button type="submit" className="text-uppercase" loading={isSubmitting}>{t('misc.updateChanges')}</Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default EditAccountModal;