import React from 'react'
import styles from './Header.module.scss'
import {Switch} from 'react-router'
import {NavLink, Route} from 'react-router-dom'
import HeaderUserPanel from './HeaderUserPanel/HeaderUserPanel'
import ConnectivityMenu from './ConnectivityMenu/ConnectivityMenu'
import { useAuth } from '../../contexts/AuthContext'
import SearchBar from '../../components/SearchBar/SearchBar'
import {ReactComponent as LunerLogo} from '../../assets/images/lunerLogoLight.svg'
import {ReactComponent as LunerLogoDark} from '../../assets/images/lunerLogoDark.svg'
import { useTranslation } from 'react-i18next'

const Header = ({full, dark}) => {
  const {activeUser, activeAccount} = useAuth()
  const { t } = useTranslation()
  return ( 
    <header className={styles.header}>
      {full ? (
      <>
        <div className={styles.headerMain}>
          <span className={styles.logo}><LunerLogo /></span>
          <nav className={styles.nav}>
            <NavLink exact to="/" className={styles.navItem} activeClassName={styles.activeNavItem}>{t('header.nav.dashboard')}</NavLink>
            <NavLink to="/connectivity" className={styles.navItem} activeClassName={styles.activeNavItem}>{t('header.nav.connectivity')}</NavLink>
            <NavLink to="/api" className={styles.navItem} activeClassName={styles.activeNavItem}>{t('header.nav.api')}</NavLink>
            <NavLink to="/invoices" className={styles.navItem} activeClassName={styles.activeNavItem}>{t('header.nav.invoices')}</NavLink>
          </nav>
          <div className={styles.search}>
            <SearchBar />
          </div>
          <HeaderUserPanel user={ {'name': activeUser ? activeUser.name : t('header.nav.myAccount'), 'company': activeAccount ? activeAccount.name : null} } />
        </div>

        {/* we're using routes to render the correct components for submenu */}
        <Switch>
          <Route path="/connectivity" render={(props) => <ConnectivityMenu />} />
        </Switch>
      </>
      ) : (
        <div className={styles.headerAuth}>
          {dark ? (
            <span><LunerLogoDark /></span>
          ) : (
            <span><LunerLogo /></span>
          )}
        </div>
      )}
    </header>
  )
}

export default Header