import React from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import { AuthRoute } from '../../components/AuthRoute/AuthRoute'
import {useAuth} from '../../contexts/AuthContext';
import styles from './Main.module.scss'

import HomeLayout from '../../layouts/HomeLayout/HomeLayout'
import LoginLayout from '../../layouts/LoginLayout/LoginLayout'
import SelectAccountLayout from '../../layouts/SelectAccountLayout/SelectAccountLayout'
import PasswordResetLayout from '../../layouts/PasswordResetLayout/PasswordResetLayout'
import NewPasswordLayout from '../../layouts/NewPasswordLayout/NewPasswordLayout'
import SimsLayout from '../../layouts/connectivity/SimsLayout/SimsLayout'
import SimOverviewLayout from '../../layouts/connectivity/SimOverviewLayout/SimOverviewLayout'
import PlansLayout from '../../layouts/connectivity/PlansLayout/PlansLayout'
import StoreLayout from '../../layouts/StoreLayout/StoreLayout'
import StoreProductLayout from '../../layouts/StoreProductLayout/StoreProductLayout'
import OrderSuccessLayout from '../../layouts/OrderSuccessLayout/OrderSuccessLayout'
import OrderFailureLayout from '../../layouts/OrderFailureLayout/OrderFailureLayout'
import SettingsLayout from '../../layouts/SettingsLayout/SettingsLayout'
import ApiLayout from '../../layouts/ApiLayout/ApiLayout'
import InviteLayout from '../../layouts/InviteLayout/InviteLayout'
import NotFoundLayout from '../../layouts/NotFoundLayout/NotFoundLayout'
import InvoicesLayout from '../../layouts/InvoicesLayout/InvoicesLayout';

function Main() {
  const { preloggedIn, loggedIn, setActiveUser, setActiveAccount } = useAuth()
  const location = useLocation()

  return (
    <main className={styles.main}>
      <Switch>
        {/* Login & account routes - redirects based on login status  */}
        <Route exact path="/login" render={() => {
          
          if(loggedIn()) {
            
            return <Redirect to="/" />
          } else if(preloggedIn()) {
            
            return <Redirect to={"/select-account" + location.search} />
          } else {
            setActiveUser(false)
            setActiveAccount(false)
            return <LoginLayout />
          }
        }} />
        <Route exact path="/select-account" render={(props) => {
          if(!preloggedIn() && !loggedIn()) {
            return <Redirect to="/login" />
          } else {
            return <SelectAccountLayout />
          }
        }} />

        {/* User Invite Accept Route */}
        <Route exact path='/invite/:token' component={InviteLayout} />
        
        {/* Password reset */}
        <AuthRoute unauthed path='/password-reset' component={PasswordResetLayout} />
        <AuthRoute unauthed path='/reset-password/:token' component={NewPasswordLayout} />
        
        {/* Connectivity Routes */}
        <AuthRoute exact path="/connectivity/sims" component={SimsLayout} />
        <AuthRoute path="/connectivity/sims/:iccid" component={SimOverviewLayout} />
        <AuthRoute exact path="/connectivity/plans" /* accountType="postpay" */ component={PlansLayout} />
        {/* <AuthRoute exact path="/connectivity/data-records" component={DataRecordsLayout} /> */}
        <AuthRoute exact path="/connectivity/order-sims/:productID" component={StoreProductLayout} />
        <AuthRoute exact path="/connectivity/order-sims" component={StoreLayout} />
        <Redirect from="/connectivity" to="/connectivity/sims" />

        {/* Store/Order Routes */}
        <AuthRoute path="/order/:orderId/success" component={OrderSuccessLayout} />
        <AuthRoute path="/payment-failure" component={OrderFailureLayout} />

        {/* API Routes */}
        <AuthRoute exact path="/api" component={ApiLayout} />
        
        {/* Invoices Route */}
        <AuthRoute exact path="/invoices" component={InvoicesLayout} />

        {/* Settings Routes */}
        <Redirect exact from="/settings/" to="/settings/account" />
        <AuthRoute path="/settings" component={SettingsLayout} />

        {/* Homepage Route */}
        <AuthRoute exact path="/" component={HomeLayout} />
        
        {/* 404 Route */}
        <AuthRoute component={NotFoundLayout} />
      </Switch>
    </main>
  );
}

export default Main;