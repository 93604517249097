import React, { useEffect, useState } from 'react';
import Card from '../../components/Card/Card'
import {useTranslation} from 'react-i18next'
import {Accordion, AccordionItem} from '../../components/Accordion/Accordion'
import {useAuth} from '../../contexts/AuthContext'
import useForm from '../../hooks/useForm'
import Input from '../Input/Input';
import CountrySelect from '../CountrySelect/CountrySelect';
import accountService from '../../services/accountService';
import Loader from '../Loader/Loader';

const AddressSelect = ({onChange=null, errors}) => {
  const {t} = useTranslation()
  const [enabledAddreses, setEnabledAddress] = useState({shipping: true, billing: true})
  const {activeAccount, setActiveAccount} = useAuth()

  const validateRules = {
    name: 'required',
    line1: 'required',
    line2: '',
    stateRegion: 'required',
    city: 'required',
    postalCode: 'required',
    country: 'required',
  }

  const handleUpdateAttempt = () => {
    const addressID = activeAccount.billing.billingAddress.id
    return accountService.updateAddress(addressID, values)
    .then( response => {
      if (response.ok) {
        // onClose()
        const billingAddressData = { ...activeAccount.billing, billingAddress: response.data }
        setActiveAccount( { 
          ...activeAccount,
          billing: billingAddressData
        })
      }
    })
    .catch( err => {
      console.warn(err)
    })
  }

  const handleBillingKeyUpdate = value => {
    setEnabledAddress( { ...enabledAddreses, billing: value }) 
    const t = { ...values }
    if ( value ) delete t.billing
    setValues( {...t} )
  }

  const handleShippingKeyUpdate = value => {
    setEnabledAddress( { ...enabledAddreses, shipping: value })
    const t = { ...values }
    if ( value ) delete t.shipping
    setValues( {...t} )
  }

  const {handleChange, values, setValues} = useForm(handleUpdateAttempt, validateRules);

  useEffect( () => {
    if (onChange) onChange(values)
  }, [onChange, values]);

  return (
    <>
      <Card section title={ t('account.address.shipping.header') }>
        <Accordion initial={activeAccount && activeAccount.billing.deliveryAddress ? true : false} onChange={handleShippingKeyUpdate}>
          <AccordionItem title={ t(`account.address.shipping.saved`) } item={true} disabled={!activeAccount || !activeAccount.billing.deliveryAddress}>
            { activeAccount && activeAccount.billing.deliveryAddress ? (
              <>
                <p className="mb-0">{activeAccount.billing.deliveryAddress.line1}</p>
                {activeAccount.billing.deliveryAddress.line2 && (<p className="mb-0">{activeAccount.billing.deliveryAddress.line2}</p>)}
                <p className="mb-0">{activeAccount.billing.deliveryAddress.city}</p>
                <p className="mb-0">{activeAccount.billing.deliveryAddress.stateRegion}</p>
                <p className="mb-0">{activeAccount.billing.deliveryAddress.postalCode}</p>
                <p className="mb-0">{activeAccount.billing.deliveryAddress.country}</p>
              </>
            ) : (
               <>
               <Loader type="text" />
               <Loader type="text" />
               <Loader type="text" />
               <Loader type="text" />
               <Loader type="text" />
               <Loader type="text" />
              </>
            )}
          </AccordionItem>
          <AccordionItem title={ t(`account.address.shipping.new`) } item={false}>
              <Input name="shipping.name" label={t('account.address.labels.name')} placeholder="Enter your name or company here..." onChange={handleChange} error={(errors && errors.shipping) && errors.shipping.name} />
              <Input name="shipping.line1" label={t('account.address.labels.lineOne')}  onChange={handleChange} error={(errors && errors.shipping) && errors.shipping.line1} />
              <Input name="shipping.line2" label={t('account.address.labels.lineTwo')} onChange={handleChange} error={(errors && errors.shipping) && errors.shipping.line2} />
              <Input name="shipping.city" label={t('account.address.labels.city')}  onChange={handleChange} error={(errors && errors.shipping) && errors.shipping.city} />
              <Input name="shipping.stateRegion" label={t('account.address.labels.state')}  onChange={handleChange} error={(errors && errors.shipping) && errors.shipping.stateRegion} />
              <Input name="shipping.postalCode" label={t('account.address.labels.postcode')}  onChange={handleChange} error={(errors && errors.shipping) && errors.shipping.postalCode} />
              <CountrySelect name="shipping.country" label={t('account.address.labels.country')}  onChange={handleChange} error={(errors && errors.shipping) && errors.shipping.country} />
          </AccordionItem>
        </Accordion>
      </Card>

      <Card title={ t('account.address.billing.header') }>
        <Accordion initial={activeAccount && activeAccount.billing.billingAddress ? true : false} onChange={handleBillingKeyUpdate}>
          <AccordionItem title={ t(`account.address.billing.saved`) } item={true} disabled={!activeAccount || !activeAccount.billing.billingAddress}>
            { activeAccount && activeAccount.billing.billingAddress ? (
              <>
                <p className="mb-0">{activeAccount.billing.billingAddress.line1}</p>
                {activeAccount.billing.billingAddress.line2 && (<p className="mb-0">{activeAccount.billing.billingAddress.line2}</p>)}
                <p className="mb-0">{activeAccount.billing.billingAddress.city}</p>
                <p className="mb-0">{activeAccount.billing.billingAddress.stateRegion}</p>
                <p className="mb-0">{activeAccount.billing.billingAddress.postalCode}</p>
                <p className="mb-0">{activeAccount.billing.billingAddress.country}</p>
              </>
            ) : (
               <>
               <Loader type="text" />
               <Loader type="text" />
               <Loader type="text" />
               <Loader type="text" />
               <Loader type="text" />
               <Loader type="text" />
              </>
            )}
          </AccordionItem>
          <AccordionItem title={ t(`account.address.billing.new`) } item={false}>
              <Input name="billing.name" label={t('account.address.labels.name')} placeholder="Enter your name or company here..." onChange={handleChange} error={(errors && errors.billing) && errors.billing.name} />
              <Input name="billing.line1" label={t('account.address.labels.lineOne')}  onChange={handleChange} error={(errors && errors.billing) && errors.billing.line1} />
              <Input name="billing.line2" label={t('account.address.labels.lineTwo')} onChange={handleChange} error={(errors && errors.billing) && errors.billing.line2} />
              <Input name="billing.city" label={t('account.address.labels.city')}  onChange={handleChange} error={(errors && errors.billing) && errors.billing.city} />
              <Input name="billing.stateRegion" label={t('account.address.labels.state')}  onChange={handleChange} error={(errors && errors.billing) && errors.billing.stateRegion} />
              <Input name="billing.postalCode" label={t('account.address.labels.postcode')}  onChange={handleChange} error={(errors && errors.billing) && errors.billing.postalCode} />
              <CountrySelect name="billing.country" label={t('account.address.labels.country')}  onChange={handleChange} error={(errors && errors.billing) && errors.billing.country} />
          </AccordionItem>
        </Accordion>
      </Card>
    </>
  )
}

export default AddressSelect