import { request, generateParameters } from "./requestService"

export const logs = (params={}) => {
  return request(`/api/logs${generateParameters(params)}`)
}

export const keys = () => {
  return request(`/api/keys`)
}

export const createKey = body => {

  const args = {
    method: 'POST',
    body: JSON.stringify(body)
  }
  return request('/api/keys', args)
}

export const deleteKey = key => {

  const args = {
    method: 'DELETE',
  }
  return request(`/api/keys/${key}`, args)
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  logs,
  keys,
  createKey,
  deleteKey
}