import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import { Row, Col } from '../../Grid/Grid'
import Modal, {ModalSection} from '../../Modal/Modal'
import Button from '../../Button/Button'
import Input from '../../Input/Input'
import Heading from '../../Heading/Heading'
import useForm from '../../../hooks/useForm'
import InfoBlock from '../../InfoBlock/InfoBlock'
import {toCurrency} from '../../../helpers/currency'
import {toReadableMbData, toReadableData} from '../../../helpers/data'
import productService from '../../../services/productService'
import StatusWindow from '../../StatusWindow/StatusWindow'
import Loader from '../../Loader/Loader'
import { useTranslation } from 'react-i18next'

const EditPlanModal = ({isShowing, onClose, onPlanUpdate, onPlanChange, onPlanCancel, onUpgradeCancel, plan, upgrade}) => {
  const [isSelectedAction, setIsSelectedAction] = useState(false)
  const [isPerformingAction, setIsPerformingAction] = useState(false)
  const [zones, setZones] = useState([])
  const [dataPackages, setDataPackages] = useState([])
  const {t} = useTranslation()

  useEffect( () => {
    productService.getPricing().then( response => {
      if (response.ok) setZones(response.data.zones)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateRules = {
    name: 'required',
  }

  useEffect( () => {
    setDefaults({
      name: plan.name,
      initialPoolSizeMb: plan.initialPoolSizeMb,
      zoneId: plan.zone.id
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing])

  useEffect( () => {
    if (plan.zone.id && zones) {
      const selected = zones.find( zone => parseInt(zone.id) === parseInt(plan.zone.id))
      if (selected && selected.dataPackages) setDataPackages(selected.dataPackages)
    }
  }, [plan, zones])

  useEffect( () => {
    if (!isShowing) {
      setIsPerformingAction(false)
      setIsSelectedAction(false)
    }
  }, [isShowing])

  const handlePlanChange = () => {

    const promises = []
    if (values.name) promises.push(productService.updateProduct(plan.id, {name: values.name}))

    if (values.zoneId && values.initialPoolSizeMb) {
      promises.push(productService.upgradeProduct(plan.id, {zoneId: values.zoneId, initialPoolSizeMb: values.initialPoolSizeMb}))
    }

    return Promise.all(promises).then( res => {
      onClose(true)
    }).catch(err => {
      //toast.error('There was a problem upgrading your plan')
      onClose()
    })
  }

  const {handleChange, handleSubmit, values, errors, setDefaults, isSubmitting} = useForm(handlePlanChange, validateRules);

  const handlePlanCancel = () => setIsSelectedAction('cancel')
  const handlePlanCancellationCancel = () => setIsSelectedAction('cancelCancellation')

  const handlePlanUpgradeCancel = () => setIsSelectedAction('cancelPlanUpgrade')

  const confirmPlanCancel = () => {
    setIsPerformingAction(true)
    productService.cancelProduct(plan.id).then( response => {
      if (response.ok) {
        onPlanUpdate()
        setIsPerformingAction(false)
        setIsSelectedAction('cancelComplete')
      } else {
        setIsPerformingAction(false)
        //setErrors('error')
      }
    })
  }

  const confirmPlanCancellationCancel = () => {
    setIsPerformingAction(true)
    productService.revokeCancellation(plan.id).then( response => {
      if (response.ok) {
        onPlanUpdate()
        setIsPerformingAction(false)
        setIsSelectedAction('cancellationCancelComplete')
      } else {
        setIsPerformingAction(false)
        // errors
      }
    })
  }

  const confirmPlanUpgradeCancel = () => {
    setIsPerformingAction(true)
    productService.cancelUpgrade(plan.id).then( response => {
      if (response.ok) {
        onPlanUpdate()
        setIsPerformingAction(false)
        setIsSelectedAction('UpgradeCancelComplete')
      } else {
        setIsPerformingAction(false)
        // errors
      }
    })
  }

  const getActionWindow = () => {
    switch (isSelectedAction) {
      case 'cancel':
        // if there's SIMs attached to the plan..
        if ((plan.assetSummary.active + plan.assetSummary.suspended) > 0) {
          return  (
            <StatusWindow type='danger'>
              <Heading>{t('plans.edit.cancel.failedHeader')}</Heading>
              <p>{t('plans.edit.cancel.failedBody')}</p>
              <Link to={`/connectivity/sims?productId=${plan.id}`}>
                <Button fullWidth variant="danger" onClick={null}>{t('misc.viewSims')}</Button>
              </Link>
            </StatusWindow>
          )
        } else {
          return  (
            <StatusWindow type='danger'>
              <Heading>{t('plans.edit.cancel.cancelHeader')}</Heading>
              <p>{t('plans.edit.cancel.cancelBody')}</p>
              <Button fullWidth variant="danger" onClick={confirmPlanCancel} loading={isPerformingAction}>{t('plans.edit.cancel.cancelConfirm')}</Button>
            </StatusWindow>
          )
        }
      case 'cancelComplete':
        return (
          <StatusWindow type='success'>
            <Heading>{t('plans.edit.cancel.successHeader')}</Heading>
            <p>{t('plans.edit.cancel.successBody')}</p>
            <Button fullWidth variant="success" onClick={onClose}>{t('misc.close')}</Button>
          </StatusWindow>
        )
      case 'cancelCancellation':
        return (
          <StatusWindow type='danger'>
            <Heading>{t('plans.edit.revoke.revokeHeader')}</Heading>
            <p>{t('plans.edit.revoke.revokeBody')}</p>
            <Button fullWidth variant="danger" onClick={confirmPlanCancellationCancel} loading={isPerformingAction}>{t('plans.edit.revoke.revokeConfirm')}</Button>
          </StatusWindow>
        )
      case 'cancellationCancelComplete':
        return (
          <StatusWindow type='success'>
            <Heading>{t('plans.edit.revoke.successHeader')}</Heading>
            <p>{t('plans.edit.revoke.successBody')}</p>
            <Button fullWidth variant="success" onClick={onClose}>{t('misc.close')}</Button>
          </StatusWindow>
        )
      case 'cancelPlanUpgrade':
        return (
          <StatusWindow type='danger'>
            <Heading>{t('plans.edit.upgradeCancel.cancelHeader')}</Heading>
            <p>{t('plans.edit.upgradeCancel.cancelBody')}</p>
            <Button fullWidth variant="danger" onClick={confirmPlanUpgradeCancel} loading={isPerformingAction}>{t('misc.cancel')}</Button>
          </StatusWindow>
        )
      case 'UpgradeCancelComplete':
        return (
          <StatusWindow type='success'>
            <Heading>{t('plans.edit.upgradeCancel.successHeader')}</Heading>
            <p>{t('plans.edit.upgradeCancel.successBody')}</p>
            <Button fullWidth variant="success" onClick={onClose}>{t('misc.close')}</Button>
          </StatusWindow>
        )
      default:
        return;
    }
  }

  const handleZoneChange = e => {
    const selected = zones.find( zone => parseInt(zone.id) === parseInt(e.target.value))
    if (!selected || !selected.dataPackages) return []
    setDataPackages(selected.dataPackages)
    handleChange(e)
  }

  const getCost = () => {
    if (!dataPackages || !values.initialPoolSizeMb) return <Loader type="text" />
    const pkg = dataPackages.find(dataPackage => dataPackage.initialPoolSizeMb === parseFloat(values.initialPoolSizeMb) )
    if (!pkg) return <Loader type="text" />
    return <span>{toCurrency(pkg.poolCost, pkg.currency)}</span>
  }

  const getOveruseCost = (append=null) => {
    if (!dataPackages || !values.initialPoolSizeMb) return '-'
    const pkg = dataPackages.find(dataPackage => dataPackage.initialPoolSizeMb === parseFloat(values.initialPoolSizeMb) )
    if (!pkg) return <Loader type="text" />
    return <span>{toCurrency(pkg.perMbPrice, pkg.currency)} / per MB</span>
  }

  return (
    <Modal size="lg" title={`${(plan.name).toUpperCase()} ${t('plans.edit.headerAppend')}`} show={isShowing} onClose={onClose}>
        <div>
          <p>{t('plans.edit.body')}</p>
        </div>
        <ModalSection type="gray">
          <Row>
            <Col xs="12">
              <form onSubmit={handleSubmit}>
                <Row align="bottom">
                  <Col xs="12">
                    <Input name="name" label={`${t('plans.edit.nameLabel')}:`} placeholder={t('plans.edit.namePlaceholder')} onChange={handleChange} value={values.name} error={errors.name} disabled={isSubmitting}/>
                  </Col>
                  { plan.type === 'aggregate' ? (
                    <Col xs="12">
                      {plan.upgrade && plan.upgrade.queued ? (
                        <Input label={`${t('plans.edit.queuedLabel')}:`} value={`${plan.upgrade.zone.name} - ${toReadableData(plan.upgrade.initialPoolSizeMb, 'MB')} - ${toCurrency(plan.upgrade.lineRentalCost, plan.upgrade.currency)}`} disabled={true}/>
                      ) : (
                        <Input label={`${t('plans.edit.currentLabel')}:`} value={`${plan.name}`} disabled={true}/>
                      )}
      
                      <Heading type="mini">{t('plans.edit.editHeader')}</Heading>
                      <Input type="select" name="zoneId" label={`${t('plans.edit.zone.label')}:`} onChange={handleZoneChange} value={values.zoneId  || ''} error={errors.zoneId} disabled={isSubmitting}>
                        <option>{t('plans.edit.zone.optionPlaceholder')}</option>
                        {zones.map( zone => <option key={zone.id} value={zone.id}>{zone.name}</option> )}
                      </Input>

                      <Input className="mb-2" type="select" name="initialPoolSizeMb" label={`${t('plans.edit.aggPlanSize.label')}:`} placeholder={t('plans.edit.aggPlanSize.placeholder')} onChange={handleChange} value={values.initialPoolSizeMb} error={errors.initialPoolSizeMb} disabled={isSubmitting}>
                        <option>{t('plans.edit.aggPlanSize.optionPlaceholder')}</option>
                        {dataPackages.map( dataPackage => <option key={dataPackage.initialPoolSizeMb} value={dataPackage.initialPoolSizeMb}>{toReadableMbData(dataPackage.initialPoolSizeMb)}</option> )}
                      </Input>

                      <Heading>{t('plans.edit.costs.header')}</Heading>
                      <p>{t('plans.edit.costs.body')}</p>
                      <ModalSection type='xs'>
                        <InfoBlock inline fullWidth label={`${t('plans.edit.costs.overuse')}:`} value={getOveruseCost()} /> 
                      </ModalSection>
                      <div className="mb-2">
                        <InfoBlock inline fullWidth label={`${t('plans.edit.costs.monthly')}:`} value={getCost()} /> 
                      </div>
                      
                      <small>{t('plans.edit.costs.footer')}</small>
                    </Col>
                  ) : (
                    <Col xs="12">
                      <Button fullWidth loading={isSubmitting} className="mb-2" type='submit'>{t('plans.edit.updatePlanName')}</Button>
                    </Col>
                  )}
                </Row>
              </form>
            </Col>
          </Row>
        </ModalSection>

        {plan.type !== 'aggregate' && (
          <>  
          <ModalSection>
            <Heading>{t('plans.edit.postpayPlanSize.header')}</Heading>
            <p>{t('plans.edit.postpayPlanSize.body')}</p>
          </ModalSection>
          <ModalSection>

            <Row>
              <Col xs={(plan.upgrade && plan.upgrade.queued) ? 6 : 12}>
                <div className="mb-3">
                  <Heading>{t('plans.edit.postpayPlanSize.subheader')}</Heading>
                  <InfoBlock inline label={`${t('plans.edit.postpayPlanSize.allowance')}:`} value={toReadableMbData(plan.initialPoolSizeMb)} />
                  <InfoBlock inline label={`${t('plans.edit.postpayPlanSize.coverage')}:`} value={plan.zone.name} />
                  <InfoBlock inline label={`${t('plans.edit.postpayPlanSize.monthly')}:`} value={`${toCurrency(plan.poolCost, plan.currency)} / ${t('misc.month')}`} />
                  <InfoBlock inline label={`${t('plans.edit.postpayPlanSize.overuse')}:`} value={toCurrency(plan.perMbCost, plan.currency) + ' ' + t('misc.perMb')} />
                  <InfoBlock inline label={`${t('plans.edit.postpayPlanSize.lineRental')}:`} value={toCurrency(plan.lineRentalCost, plan.currency)} />
                </div>
              </Col>
              { plan.upgrade && plan.upgrade.queued && (
              <Col xs={6}>
                <div className="mb-3">
                  <Heading>{t('plans.transfer.upgradeQueued')}</Heading>
                  <InfoBlock inline label={`${t('plans.edit.postpayPlanSize.allowance')}:`} value={toReadableMbData(plan.upgrade.initialPoolSizeMb)} />
                  <InfoBlock inline label={`${t('plans.edit.postpayPlanSize.coverage')}:`} value={plan.upgrade.zone.name} />
                  <InfoBlock inline label={`${t('plans.edit.postpayPlanSize.monthly')}:`} value={`${toCurrency(plan.upgrade.poolCost, plan.upgrade.currency)} / ${t('misc.month')}`} />
                  <InfoBlock inline label={`${t('plans.edit.postpayPlanSize.overuse')}:`} value={toCurrency(plan.upgrade.perMbCost, plan.upgrade.currency) + ' ' + t('misc.perMb')} />
                  <InfoBlock inline label={`${t('plans.edit.postpayPlanSize.lineRental')}:`} value={toCurrency(plan.upgrade.lineRentalCost, plan.upgrade.currency)} />
                </div>
              </Col>
              )}
            </Row>
            <div>
              <Button fullWidth onClick={onPlanChange}>{t('plans.edit.postpayPlanSize.upgradePlan')}</Button>
            </div>
          </ModalSection>
          </>
        )}

        { !isSelectedAction ? (
          <Row>
            <Col xs="6" align="left">
              { plan.cancellation && plan.cancellation.queued && (
                <Button variant='danger' onClick={handlePlanCancellationCancel}>{t('plans.edit.cancelCancellationBtn')}</Button>
              )}
              { plan.upgrade && plan.upgrade.queued && (
                <Button variant='danger' onClick={handlePlanUpgradeCancel}>{t('plans.edit.cancelUpgradeBtn')}</Button>
              )}
              { ((plan.upgrade && !plan.upgrade.queued) && (plan.cancellation && !plan.cancellation.queued)) && (
                <Button variant='danger' onClick={handlePlanCancel}>{t('plans.edit.cancelBtn')}</Button>
              )}
            </Col>
            <Col xs="6" align="right">
              {plan.type === 'aggregate' ? (
                <Button loading={isSubmitting} onClick={handleSubmit}>{t('misc.saveChanges')}</Button>
              ) : (
                <Button onClick={onClose}>{t('misc.confirm')}</Button>
              )}
            </Col>
          </Row>
        ) : (
          <>
            {getActionWindow()}
          </>
        )}
      

    </Modal>
  )
}

export default EditPlanModal





