import { useState, useEffect } from 'react';
import {validate as validateValues} from '../services/validationService';

const useForm = (callback, validationRules) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasAttempted, setHasAttempted] = useState(false);
  
  useEffect(() => {    
    if (Object.keys(errors).length === 0 && isSubmitting) {   
      const promise = callback()
      if (promise) {
        promise.then( res => {
          setIsSubmitting(false)
        }).catch( err => {
          setIsSubmitting(false)
        })
      } else {
        setIsSubmitting(false)
      }
    } else {
      setIsSubmitting(false)
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect( () => {
    if( hasAttempted ) {
      setErrors(validateValues(values, validationRules))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  const handleSubmit = (event) => {
    setIsSubmitting(true);
    if (event) event.preventDefault();
    setErrors(validateValues(values, validationRules));   
    setHasAttempted(true);
  };

  const handleChange = (event) => {
    event.persist();
    // get the value - taking into account checkboxes
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value
    
    // get the name
    let n = event.target.name 

    // if this is a simple assign, just assign it..
    if( !n.includes('.') ) {
      setValues({ ...values, [event.target.name]: value });
    // if this if a nested object, deep merge
    } else {
      // create the nested object
      // split name string to array, reverse array so deepest nest first
      n = n.split('.')
      n = n.reverse()
      // create temp object, then loop through n array and create nested obj
      let obj = {} 
      obj[ n[0] ] = value

      // loop through all but the first in array - set above 
      for( var i = 1; i < n.length; i++ ) {
        const temp = {}
        temp[n[i]] = obj
        obj = temp
      }

      // now, deep merge new object into values
      const nv = deepMerge(values, obj);
      setValues( nv )
    }
  };

  const validate = (rules = null) => {
    const validationRuleset = rules || validationRules
    const errors = validateValues(values, validationRuleset)
    if ( Object.keys(errors).length > 0) {
      setErrors(validateValues(values, validationRuleset))
      return false;
    } else {
      return true;
    }
  }

  const deepMerge = (target, ...sources) => {
    for (let source of sources) {
      for (let k in source) {
        let vs = source[k], vt = target[k]
        if (Object(vs) === vs && Object(vt) === vt) {
          target[k] = deepMerge(vt, vs)
          continue
        }
        target[k] = source[k]
      }
    }
    return target
  }

  const generateData = (nameArr, value, index) => {
    let dataArr = {}
    
    if ( index !== null ) {

      // create blank or spread existing values 
      if (typeof values[nameArr[0]] === 'object') {
        dataArr = { ...values[nameArr[0]] }
      } else {
        dataArr = {}
      }

      // if theres a second level index
      if (nameArr[1] ) {
        if (typeof dataArr[index] === 'object') {
          dataArr[index] = { 
            ...dataArr[index],
            [nameArr[1]]: value
           }
        } else {
          dataArr[index] = { [nameArr[1]]: value }
        }
      }else {
        dataArr[index] = value
      }
      return dataArr
    }
  }

  const handleDynamicChange = (event) => {
    if ( event && event.type !== 'toggle')
      event.persist();
    
    let nameArr = event.target.name.split('.');

    if ( event.type === 'toggle') {
      setValues(
        { 
          ...values,
          [nameArr[0]]: generateData(nameArr, event.target.checked, event.arrayid)
        }
      )
    } else if ( event.target.files ) {
      setValues(
        { 
          ...values,
          [nameArr[0]]: generateData(nameArr, event.target.files[0], event.target.getAttribute('arrayid'))
        }
      )
    } else {
      setValues(
        { 
          ...values,
          [nameArr[0]]: generateData(nameArr, event.target.value, event.target.getAttribute('arrayid'))
        }
      )
    }
  }

  const resetValues = (data={}) => {
    setHasAttempted(false)
    setValues(data)
    setErrors({})
  }

  const setDefaults = (data) => {
    setValues(values => ({ ...values, ...data }))
  }

  const handleServerError = (errors) => {
    const errorObject = errors.reduce((errorObj, error) => {
      errorObj[error.field] = error.errors[0]
      return errorObj
    }, {})
    
    setErrors(errorObject)
  }

  return {
    handleChange,
    handleDynamicChange,
    handleSubmit,
    isSubmitting,
    values,
    setValues,
    resetValues,
    setDefaults,
    errors,
    validate,
    handleServerError
  }
};

export default useForm;