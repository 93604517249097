import React from 'react'
import {Row, Col} from '../../components/Grid/Grid'
import Card from '../../components/Card/Card'
import InfoBlock from '../../components/InfoBlock/InfoBlock'
import Button from '../../components/Button/Button'
import Modal from '../../components/Modal/Modal'
import ProgressBar from '../../components/ProgressBar/ProgressBar'
import Badge from '../../components/Badge/Badge'
import EditPlanModal from './modals/EditPlanModal'
import CreatePlanModal from '../../layouts/connectivity/PlansLayout/modals/CreatePlanModal'
import {ReactComponent as ActiveIcon} from '../../assets/icons/greenRadar.svg'
import {ReactComponent as InactiveIcon} from '../../assets/icons/redRadar.svg'
import useModal from '../../hooks/useModal';
import {toCurrency} from '../../helpers/currency'
import productService from '../../services/productService'
import { toReadableData } from '../../helpers/data'
import styles from './PlanCard.module.scss'
import { useAuth } from '../../contexts/AuthContext'
import { useTranslation } from 'react-i18next'

const PlanCard = ({planData, onUpdatePlan}) => {
  const [isManageShowing, toggleManage] = useModal()
  const [isUpgradeShowing, toggleUpgrade] = useModal()
  const [isConfirmShowing, toggleConfirm] = useModal()
  const {activeAccount} = useAuth()
  const {t} = useTranslation()

  const handlePlanChange = (success=false) => {
    if (success) {
      onUpdatePlan()
    }
    toggleManage()
  }

  const handleUpgradeSelected = () => {
    onUpdatePlan()
    toggleUpgrade()
    toggleManage()
  }

  const handleModalToggle = () => {
    toggleManage()
    toggleUpgrade()
  }

  const handleCancelUpgrade = () => {
    toggleManage()
    toggleConfirm()
  }

  const confirmPlanCancel = () => {
    onUpdatePlan()
    toggleManage()
  }

  const confirmUpgradeCancel = () => {
    productService.cancelUpgrade(planData.id).then( response => {
      if (response.ok) onUpdatePlan()
      toggleConfirm()
      toggleManage()
    })
  }

  const confirmRevokeCancel = () => {
      onUpdatePlan()
      toggleManage()
  }

  const getNotification = () => {
    if (planData.cancellation && planData.cancellation.queued) {
      return <Badge type='warning' content={t('plans.transfer.cancellationQueued')} />
    } else if (planData.upgrade && planData.upgrade.queued) {
      return <Badge type='success' content={t('plans.transfer.upgradeQueued')} />
    }
    return 
  }

  const getConfirmationModal = () => {

    const args = {
      title: 'CANCEL PLAN',
      content: 'Are you sure you want to cancel this plan?',
      buttonText: 'CANCEL PLAN',
      action: confirmPlanCancel
    }

    if (planData.upgrade && planData.upgrade.queued) {
      args.title = t('plans.edit.upgradeCancel.cancelHeader')
      args.content = t('plans.edit.cancelUpgradeBody')
      args.buttonText = t('plans.edit.cancelUpgradeBtn')
      args.action = confirmUpgradeCancel
    } else if (planData.cancellation && planData.cancellation.queued) {
      args.title = t('plans.edit.revoke.revokeHeader')
      args.content = t('plans.edit.revoke.revokeBody')
      args.buttonText = t('plans.edit.revokeBtn')
      args.action = confirmRevokeCancel
    }

    return (
      <Modal title={args.title} show={isConfirmShowing} onClose={toggleConfirm}>
        <Row spacer>
          <Col xs="12">
            <small>{args.content}</small>
          </Col>
        </Row>
        <Row>
          <Col align="right">
            <Button variant="link" onClick={toggleConfirm}>{t('misc.return')}</Button>
            <Button onClick={args.action}>{args.buttonText}</Button>
          </Col>
        </Row>
      </Modal>
    )
  }

  return (
    <>
     <Card className={styles.planCard} title={planData.name} subtitle={planData.id} link={getNotification()} fullHeight>
        <div className={styles.planSpacer}>
          <Row>
            <Col xs='6' className={styles.simStatus}><ActiveIcon /> {planData.assetSummary.active} {t('plans.labels.activeSims')}</Col>
            <Col xs='6' className={styles.simStatus}><InactiveIcon /> {planData.assetSummary.suspended} {t('plans.labels.suspendedSims')}</Col>
          </Row>
        </div>
        
        {planData.type === "aggregate" ? (
          <>
            <div className={styles.planSpacer}>
              <Row>
                <Col xs='4'>
                  <InfoBlock label={t('plans.labels.perSimAllowance')} value={toReadableData(planData.initialPoolSizeMb, 'MB')} />
                </Col>
                <Col xs='4'>
                  <InfoBlock label={t('plans.labels.perSimCost')} value={toCurrency(planData.lineRentalCost, planData.currency)} />
                </Col>
                <Col xs='4'>
                  <InfoBlock label={t('plans.labels.overusageCost')} value={`${toCurrency(planData.perMbCost, planData.currency)} / 1MB`} />
                </Col>
              </Row>
            </div>
            <div className={styles.planSpacer}>
              <Row>
                <Col xs='8'>
                  <InfoBlock label={t('plans.labels.combinedAllowance')} value={toReadableData(((planData.assetSummary.active + planData.assetSummary.suspended) * planData.initialPoolSizeMb), 'MB')} />
                </Col>
                <Col xs='4'>
                  <InfoBlock label={t('plans.labels.combinedCost')} value={toCurrency(((planData.assetSummary.active + planData.assetSummary.suspended) * planData.lineRentalCost), planData.currency)} />
                </Col>
                {planData.hasOwnProperty('usageMb') && planData.usageMb !== null ? (
                  <Col xs='12'>
                    <ProgressBar 
                      progress={(planData.assetSummary.active + planData.assetSummary.suspended) > 0 ? (planData.usageMb / ((planData.assetSummary.active + planData.assetSummary.suspended) * planData.initialPoolSizeMb) * 100) : 100} 
                      label={t('misc.used')} 
                      value={(planData.usageMb > ((planData.assetSummary.active + planData.assetSummary.suspended) * planData.initialPoolSizeMb)) ? `${planData.usageMb}MB - Overuse: ${planData.usageMb - ((planData.assetSummary.active + planData.assetSummary.suspended) * planData.initialPoolSizeMb)}MB` : `${planData.usageMb}MB`} 
                    />
                  </Col>
                ) : ('')}
              </Row>
            </div>
          </>
        ) : (
          <div className={styles.planSpacer}>
            <Row>
              <Col xs='6'>
                <InfoBlock label={t('plans.labels.dataPlan')} value={`${toReadableData(planData.initialPoolSizeMb, 'MB')} - ${planData.zone.name}`} />
              </Col>
              <Col xs='6'>
                <InfoBlock label={t('plans.labels.usage')} >
                  {('usageMb' in planData && planData.usageMb !== null) ? (
                    `${toReadableData(planData.usageMb, 'MB')} of ${toReadableData(planData.initialPoolSizeMb, 'MB')} ${t('misc.used')}`
                  ) : (
                    <span className="text-muted">{t('misc.unavailable')}</span>
                  )}
                </InfoBlock>
              </Col>
              <Col xs='6'>
                <InfoBlock label={t('plans.labels.lineRental')} value={`${toCurrency(planData.lineRentalCost, planData.currency)} / ${t('misc.month')}`} />
              </Col>
              <Col xs='6'>
                <InfoBlock label={t('plans.labels.monthlyCost')} value={`${toCurrency(planData.poolCost, planData.currency)} / ${t('misc.month')}`} />
              </Col>
              <Col xs='6'>
                <InfoBlock label={t('plans.labels.overusageCost')} value={`${toCurrency(planData.perMbCost, planData.currency)} / 1MB`} />
              </Col>
              {planData.hasOwnProperty('usageMb') && planData.usageMb !== null ? (
                <Col xs='12'>
                  <ProgressBar 
                    progress={planData.usageMb / planData.initialPoolSizeMb * 100 } 
                    label={t('misc.used')}
                    value={planData.usageMb > planData.initialPoolSizeMb ? `${planData.usageMb}MB - ${t('misc.overuse')}: ${planData.usageMb - planData.initialPoolSizeMb}MB` : `${planData.usageMb}MB`} 
                  />
                </Col>
              ) : ('')}
            </Row>
          </div>
        )}
        <Row className={styles.manageFinalRow}>
          <Col xs='12'>
            <Button fullWidth onClick={toggleManage}>{t('plans.manageHeader')}</Button>
          </Col>
        </Row>
      </Card>

      <EditPlanModal isShowing={isManageShowing} onClose={handlePlanChange} onPlanUpdate={onUpdatePlan} onPlanChange={handleModalToggle} onPlanCancel={confirmPlanCancel} onUpgradeCancel={handleCancelUpgrade} plan={planData} />
      <CreatePlanModal isShowing={isUpgradeShowing} onClose={handleModalToggle} onPlanChange={handleUpgradeSelected} plan={planData} type={activeAccount ? activeAccount.billing.dataPackageType : null}/>
      { getConfirmationModal() }
    </>
  )
}

export default PlanCard