import React, {useState, useEffect} from 'react'
import Table, { TableRow } from '../../components/Table/Table'
import ConfirmButton from '../../components/ConfirmButton/ConfirmButton'
import userService from '../../services/userService'
import useModal from '../../hooks/useModal'
import ConfirmUserDeleteModal from './ConfirmUserDeleteModal'
import Loader from '../../components/Loader/Loader'
import {useAuth} from '../../contexts/AuthContext'
import { useTranslation } from 'react-i18next'

const UserTable = () => {
  const [users, setUsers] = useState(null)
  const [targetUser, setTargetUser] = useState(null)
  const [isShowing, toggle] = useModal()
  const {activeUser, activeAccount} = useAuth()
  const {t} = useTranslation()

  const userHeader = [
    {name: t('settings.users.current.headers.name')},
    {name: t('settings.users.current.headers.email')},
    {name: t('settings.users.current.headers.actions')}
  ]

  useEffect( () => {
    userService.users().then( response => {
      setUsers(response.data)
    })
  },[])

  const handleDeleteUser = user => {
    setTargetUser(user)
    toggle()
  }

  const handleDeleteUserClose = (success=false) => {
    toggle()
    if (success) {
      userService.users().then( response => {
        setUsers(response.data)
      })
    }
  }

  const canRemove = user => {
    if (!activeUser || !activeAccount) return false
    if (activeUser.id === user.id) return false
    if (activeAccount.owner === user.id) return false
    return true
  }

  return (
    <>
      <Table headers={userHeader}>

      {users ? (
          <>
          { users.length > 0 ? (
            users.map ( (user, i) => (
              <TableRow delay={i} key={i}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>
                  {canRemove(user) && (
                    <ConfirmButton variant='a' buttonStyle="danger" icon="delete" data={user} onClick={handleDeleteUser}>{t('settings.users.remove.header')}</ConfirmButton>
                  )}
                </td>
              </TableRow>
            ))
          ) : (
            <tr><td colSpan="3">{t('settings.users.noUsersFound')}</td></tr>
          )}
          </>
        ) : (
          <tr>
            <td><Loader type="text" width={60} /></td>  
            <td><Loader type="text" width={80} /></td>  
            <td><Loader type="text" width={60} /></td>  
          </tr>
        )}
      </Table>
      <ConfirmUserDeleteModal isShowing={isShowing} onClose={handleDeleteUserClose} user={targetUser} />
    </>
  )
}

export default UserTable