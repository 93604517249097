import { request } from './requestService';

export const createOrder = paypalID => {
  const args = {
    method: 'POST',
    body: JSON.stringify({
      paypal_order_id: paypalID
    })
  }
    return request('/paypal/create-order', args)
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default  {
  createOrder
}