import React from 'react'
import {NavLink} from 'react-router-dom'
import styles from './PageHeader.module.scss'
import Container from 'react-bootstrap/Container'
import Heading from '../../components/Heading/Heading'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'


const PageHeader = ({title, subtitle=null, inset=false, dark=false, tabs=false, soft=false, slim=false, breadcrumbs=false, children}) => {
  return (
    <div className={`${styles.main} ${inset ? styles.mainInset : ''} ${dark ? styles.dark : styles.light} ${slim && styles.slim}`}>
      {breadcrumbs && (
        <Container className={styles.crumbs}>
          <Row>
            <Col>
              {breadcrumbs}
            </Col>
          </Row>
        </Container>
      )}
      <Container>
        <Row>
          <Col>
            <Heading type="page">
              {title}
              {subtitle && subtitle}
            </Heading>
          </Col>
          <Col className="d-flex justify-content-end">
            {children}
          </Col>
        </Row>
      </Container>
      { (!inset && tabs) && (
      <Container className={styles.tabs}>
        <Nav className={soft ? `${styles.softTabs} ${styles.tabWrap}` : styles.tabWrap} variant="tabs">
        {tabs.map( (tab, i) => {
          if (soft) {
            return (
              <Nav.Item key={i}>
                <Nav.Link bsPrefix={styles.tab} eventKey={tab.target}>{tab.name}</Nav.Link>
              </Nav.Item>
            )
          } else {
            return (
              <Nav.Item key={i}>
                <NavLink to={tab.target} className={styles.tab}>{tab.name}</NavLink>
              </Nav.Item>
            )
          }
        })}
        </Nav>
      </Container>
      )}
    </div>
  )
}

export default PageHeader