import React from 'react'
import Modal from '../../components/Modal/Modal';
import Input from '../../components/Input/Input'
import useForm from '../../hooks/useForm'
import {Row, Col} from '../../components/Grid/Grid'
import Button from '../../components/Button/Button'
import {toast} from 'react-toastify'
import userService from '../../services/userService';
import { useTranslation } from 'react-i18next';

const InviteUserModal = ({isShowing, toggle, onClose}) => {
  const {t} = useTranslation()

  const validateRules = {
    email: 'required|email',
  }

  const handleInviteAttempt = () => {
    return userService.createInvite(values)
    .then( response => {
      if (response.ok) {
        onClose(true)
        toast.success('User invite sent!')
      } else {
        onClose()
        toast.warning('There was an error sending the invite. Please try again soon')
      }
    })
    .catch( err => {
      console.warn(err)
    })
  }

  const {handleChange, handleSubmit, values, errors, isSubmitting} = useForm(handleInviteAttempt, validateRules);
 
  return (
    <Modal title={t('settings.users.invites.inviteModal.header')} show={isShowing} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs="12">
            <Input name="email" label={t('settings.users.invites.inviteModal.inputLabel')} placeholder={t('settings.users.invites.inviteModal.inputPlaceholder')} onChange={handleChange} value={values.email} error={errors.email} disabled={isSubmitting}/>
          </Col>
          <Col xs="12" align="right">
            <Button type="submit" loading={isSubmitting}>{t('settings.users.invites.inviteModal.sendBtn')}</Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default InviteUserModal;