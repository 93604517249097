import React from 'react'
import {Link} from 'react-router-dom'
import Button from '../../../components/Button/Button'
import { useTranslation } from 'react-i18next'

const SuccessScreen = ({create=false}) => {
  const {t} = useTranslation()

  return (
    <div>
      <h1>{t('misc.account')} {create ? t('misc.created') : t('misc.added')}</h1>
      <p>{t('invite.success')}</p>
    
      <Link to="/login">
        <Button>{t('auth.loginAccount')}</Button>
      </Link>
    </div>
  )
} 

export default SuccessScreen