import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import assetService from '../../services/assetService'
import productService from '../../services/productService'
import PlanSwitcher from './PlanSwitcher'
import {Table, TableRow} from '../../components/Table/Table'
import Loader from '../../components/Loader/Loader'
import VpnIcon from '../../components/VpnIcon/VpnIcon'
import SimFilters from '../SimsFilters/SimsFilters'
import {ReactComponent as UnknownVpnIcon} from '../../components/VpnIcon/icons/unknownIcon.svg'
import {toReadableData} from '../../helpers/data'
import styles from './SimsTable.module.scss'
import {useAuth} from '../../contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import DateTime from 'luxon/src/datetime.js'
import Checkbox from '../../components/Checkbox/Checkbox'

const SimsTable = ({refresh=false}) => {
  const [selected, setSelected] = useState([])
  const [assets, setAssets] = useState(null)
  const [products, setProducts] = useState([])
  const [filters, setFilters] = useState({q: '', productId: '', status: ''})
  const history = useHistory();
  const {activeAccount} = useAuth()
  const {t} = useTranslation()

  const headers = [
    {name: ''},
    {name: t('connectivity.sims.headers.name')},
    {name: t('connectivity.sims.headers.status')},
    {name: t('connectivity.sims.headers.vpn'), align: 'center'},
    {name: t('connectivity.sims.headers.usage'), align: 'center'},
    {name: (activeAccount && activeAccount.billing.dataPackageType === 'single_bundle') ? t('connectivity.sims.headers.expiryDate') : t('connectivity.sims.headers.lastActive'), align: 'center'},
    {name: ((activeAccount && activeAccount.billing.dataPackageType === 'prepay') ? t('connectivity.sims.headers.coverage') : t('connectivity.sims.headers.plan')), align: 'center'},
  ]

  useEffect( () => {
    let isSubscribed = true
    productService.products().then( response => {
      if (response.ok) {
        isSubscribed && setProducts(response.data)
      } else {
        isSubscribed && setProducts([])
      }
    })
  }, [])

  useEffect( () => {
    let isSubscribed = true
    assetService.assets(filters).then( response => {
      if (response.ok) {
        isSubscribed && setAssets(response.data)
      } else {
        isSubscribed && setAssets([])
      }
    })
    return () => isSubscribed = false
  }, [filters, refresh]) 

  const getAssets = args => {
    assetService.assets(args).then( response => {
      if (response.ok) {
        setAssets(response.data)
      }
    })
  }

  const handleRowClick = tgt => {
    history.push({
      pathname: `/connectivity/sims/${tgt.iccid}`,
      state: {name: tgt.name}
    })
  }

  const handleFilter = (newFilters) => {
    setFilters( {
      ...filters,
      ...newFilters
    })
  }
  
  const handleMultiselect = e => {
    
    const current = selected
    const iccid = e.target.value
    
    if ( current.includes(iccid) ) {
      const index = current.indexOf(iccid);
      if (index > -1) {
        current.splice(index, 1);
      }
    } else {
      current.push(iccid)
    }
    
    setSelected([ ...current ])
  }

  const getAssetStatus = asset => {

    if( activeAccount.billing.dataPackageType === 'single_bundle' ) { 
      if((DateTime.fromISO(asset.subscription.expiresAt) < DateTime.now() ) ) return 'expired';

      if( activeAccount ) {
        // if less than 5% data remaining...
        if( (process.env.REACT_SINGLE_BUNDLE_DATA_LIMIT || 524288000) - asset.dataUsed < ( ((process.env.REACT_SINGLE_BUNDLE_DATA_LIMIT || 524288000) / 100 * 5)) ) return 'nearing_data_limit';
      }
    }

    return asset.status;

  }

  return (
    <>
      <SimFilters selected={selected} products={products} onFilter={handleFilter} />
      <Table 
        headers={headers}
        search
        noHover
        filters={filters}
        pagination={assets && assets.pagination ? assets.pagination : null}
        onChange={getAssets}
        footerNote={t('connectivity.sims.footerNote')}
      >
        {assets ? (
          <>
            {assets.assets && assets.assets.length > 0 ? (
              <>
                {assets.assets && assets.assets.map ( (asset, assIndex) => (
                  <TableRow key={asset.iccid} delay={assIndex}>
                    <td align='center'>
                      {(activeAccount && activeAccount.billing.dataPackageType === 'single_bundle') &&
                        <Checkbox onChange={handleMultiselect} value={asset.iccid} selected={selected.includes(asset.iccid)} disabled={(asset.status !== 'inactive' && (asset.subscription.expiresAt && (DateTime.fromISO(asset.subscription.expiresAt) > DateTime.now() )))} />
                      }
                    </td>
                    <td className={styles.simname} onClick={() => handleRowClick({iccid: asset.iccid, name: asset.name})}>{asset.name}</td>
                    
                    {(activeAccount && activeAccount.billing.dataPackageType === 'single_bundle') ? (
                      <td className={(asset.subscription.expiresAt && (DateTime.fromISO(asset.subscription.expiresAt) < DateTime.now() )) ? styles.expired : styles[asset.status]}>{ (asset.subscription.expiresAt && (DateTime.fromISO(asset.subscription.expiresAt) < DateTime.now() )) ? t('misc.expired') : t(`misc.${asset.status}`) }</td>
                    ) : (
                      <td className={styles[asset.status]}>{t(`misc.${asset.status}`)}</td>                    
                    )}

                    {asset.status !== 'inactive' ? (
                      <>
                        <td align='center'><VpnIcon status={asset.vpn.active} /></td>
                        <td className="text-nowrap">{ asset.dataUsed === null ? ('-') : toReadableData(asset.dataUsed)} {(activeAccount && activeAccount.billing.dataPackageType === 'single_bundle') ? ' / '+ toReadableData(process.env.REACT_SINGLE_BUNDLE_DATA_LIMIT || 524288000) : '' }</td>
                      </>
                    ) : (
                      <>
                        <td align="center"><UnknownVpnIcon /></td>
                        <td>-</td>
                      </>
                    )}

                    {(activeAccount && activeAccount.billing.dataPackageType === 'single_bundle') ? (
                      <td>{ (!asset.product || asset.subscription.expiresAt == null) ? ('-') : DateTime.fromISO(asset.subscription.expiresAt).toLocaleString(DateTime.DATETIME_FULL) }</td>
                    ) : (
                      <td>{ asset.lastActive == null ? ('-') : DateTime.fromISO(asset.lastActive).toLocaleString(DateTime.DATETIME_FULL) }</td>
                    )}

                    <td align="right">
                      <PlanSwitcher iccid={asset.iccid} products={products} currentProduct={asset.product.id} name={asset.product.name || null} status={getAssetStatus(asset)} queued={(asset.transfer && asset.transfer.queued) ? asset.transfer.productId : false} updateTable={getAssets} />
                    </td>
                  </TableRow>
                ))}
              </>
            ) : (
              <tr>
                <td className="text-center" colSpan={headers.length}>
                  {t('connectivity.sims.noRowsFound')}
                </td>
              </tr>
            )}
          </>
          ) : (
            <tr>
              {headers.map( (ele, i) => <td key={i}><Loader type="text" width={100} /></td> )}
            </tr>
          )}
      </Table>
    </>
  )
}

export default SimsTable