import React, {useState} from 'react'
import Modal from '../../../components/Modal/Modal'
import Button from '../../../components/Button/Button'
import { Row, Col } from '../../../components/Grid/Grid'
import apiService from '../../../services/apiService'
import Heading from '../../../components/Heading/Heading'
import {toast} from 'react-toastify'
import { useTranslation } from 'react-i18next'

const DeleteApiKeyModal = ({show, onClose, apiKey}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {t} = useTranslation()

  const handleDeleteAttempt = () => {
    setIsSubmitting(true)
    return apiService.deleteKey(apiKey).then(response => {
      if (response.ok) {
        onClose(true)
        toast.success(t('toasts.apiKeyDelete'))
      } else {
        setIsSubmitting(false)
        toast.success(t('toasts.apiKeyDeleteError'))
      }
    })
  }

  return (
    <Modal title="Delete Api Key" show={show} onClose={onClose}>
      <Row>
        <Col xs='12'>
          <Heading>{t('api.delete.header')}</Heading>
          <p>{t('api.delete.body')}</p>
          <p>{t('api.delete.continue')}</p>
        </Col>
        <Col xs='12' align="right">
          <Button type='submit' loading={isSubmitting} onClick={handleDeleteAttempt}>{t('api.delete.confirm')}</Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default DeleteApiKeyModal