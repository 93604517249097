import React, {useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container'
import {Row, Col} from '../../components/Grid/Grid'
import PageHeader from '../../components/PageHeader/PageHeader';
import ProductListCard from '../../components/ProductListCard/ProductListCard'
import { useTranslation } from 'react-i18next';
import styles from './StoreLayout.module.scss'
import productData from '../../assets/data/products.json'
import Icon from '../../components/Icon/Icon';


const StoreLayout = () => {
  const [products, setProducts] = useState(null)
  const {t} = useTranslation()

  useEffect( () => {
    setTimeout( () => {
      setProducts( productData.products )
    }, 2000)
  }, [])

  return (
    <>
      <PageHeader title={t('store.pageTitle')} inset />
      <Container>
        <Row spacer="large" className="justify-content-center">
          { products ? products.map( product => (
            <Col xs='6' key={product.id}>
              <ProductListCard product={product} />
            </Col>
          ))
          :
          (
            <>
              <Col xs='6'>
                <ProductListCard product={null} />
              </Col>
              <Col xs='6'>
                <ProductListCard product={null} />
              </Col>
            </>
          ) }
        </Row>
      </Container>
          
      <div className={styles.storeBottom}>
        <Container>
          <Row>
            <Col xs={6}>
              <span className={styles.top}>{ t('store.ad.top') }</span>  
              <h3 className={styles.main}>{ t('store.ad.main') }</h3>  
              
              <a href="https://luner.io/coverage/" target="_blank" rel="noopener noreferrer" className={styles.tagline}><span>{ t('store.ad.tagline') }</span> <Icon type="go-light" /> </a>  
            </Col>
          </Row>
        </Container>
      </div>
    </>    
  )
}

export default StoreLayout