export const addCookie = (cookie, data, expires = null) => {
  let cookieText = `${cookie}=${data}`

  if (expires) {
    let expiration = `;expires=${expires}`
    cookieText = cookieText.concat(expiration)
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'production' ) {
    cookieText = cookieText + ';domain=.luner.io;path=/'
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'staging' ) {
    cookieText = cookieText + ';domain=.staging.luner.io;path=/'
  }
  
  document.cookie = cookieText;
}

export const getCookie = (cname) => {

  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export const removeCookie = (cname) => {

  let cookie = cname + '=;'

  if (process.env.REACT_APP_ENVIRONMENT === 'production' ) {
    cookie = cookie + 'domain=.luner.io;path=/;';
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'staging' ) {
    cookie = cookie + 'domain=.staging.luner.io;path=/;';
  }

  cookie = cookie + "expires=Thu, 01 Jan 1970 00:00:00 GMT";
  document.cookie = cookie
  return "";
}

export const removeAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; ++i) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

      let cookieText = name + '=;'

      if (process.env.REACT_APP_ENVIRONMENT === 'production' ) {
        cookieText = cookieText + 'domain=.luner.io;path=/;';
      }

      if (process.env.REACT_APP_ENVIRONMENT === 'staging' ) {
        cookieText = cookieText + 'domain=.staging.luner.io;path=/;';
      }

      cookieText = cookieText + "expires=Thu, 01 Jan 1970 00:00:00 GMT";
      document.cookie = cookieText
  }
}


export const removeAllAuthCookies = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; ++i) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

      let cookieText = name + '=;'

      if (process.env.REACT_APP_ENVIRONMENT === 'production' ) {
        cookieText = cookieText + 'domain=.luner.io;path=/;';
      }

      if (process.env.REACT_APP_ENVIRONMENT === 'staging' ) {
        cookieText = cookieText + 'domain=.staging.luner.io;path=/;';
      }

      if ( ['LunerTempToken', 'LunerAPIToken'].includes(name)) {
        cookieText = cookieText + "expires=Thu, 01 Jan 1970 00:00:00 GMT";
        document.cookie = cookieText
      }
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  addCookie,
  removeCookie,
  getCookie,
  removeAllCookies,
  removeAllAuthCookies,
}