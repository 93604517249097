import React, {useEffect, useState} from 'react'
import {Alert} from 'react-bootstrap'
import Modal from '../../components/Modal/Modal';
import {Row, Col} from '../../components/Grid/Grid'
import Heading from '../../components/Heading/Heading'
import Input from '../../components/Input/Input'
import useForm from '../../hooks/useForm'
import styles from './ActivateSimModal.module.scss'
import Button from '../../components/Button/Button'
import assetService from '../../services/assetService'
import productService from '../../services/productService'
import {toBytes} from '../../helpers/data'
import {useAuth} from '../../contexts/AuthContext'

import useModal from '../../hooks/useModal';
import Loader from '../../components/Loader/Loader';
import TopUpBalanceModal from '../TopUpBalanceModal/TopUpBalanceModal'
import { Trans, useTranslation } from 'react-i18next';
import { toCurrency } from '../../helpers/currency';

const ActivateSimModal = ({isShowing, toggle, onClose}) => {

  const [products, setProducts] = useState([])
  const {t} = useTranslation()

  const validateRules = {
    name: 'required',
    iccid: 'required|min:19|max:20',
    dataLimit: '',
  }

  const handleActivateAttempt = () => {
    const data = toBytes(values.dataLimit, values.dataLimitMultiplier)  
    const args = { ...values }

    delete args.dataLimitMultiplier
    
    if (data) args.dataLimit = data

    if ( args.status === 'inactive') delete args.productId; 

    return assetService.provision(args)
    .then( response => {
      if (response.ok) onClose(true)
    })
    .catch( err => {
      console.warn(err)
    })
  }

  const handleToggleTopup = e => {
    toggleActivate()
    toggleTopup()
  }

  const { handleChange, handleSubmit, values, errors, setDefaults, isSubmitting } = useForm(handleActivateAttempt, validateRules);
 
  const [isActivateModalShowing, toggleActivate, setActivateShowing] = useModal()
  const [isTopupModalShowing, toggleTopup, setTopupShowing] = useModal()

  useEffect( () => {
    setActivateShowing(isShowing)
    
    const defaults = {
      name: '',
      status: 'active',
      dataLimitMultiplier: 'MB'
    }
    setDefaults(defaults)

    productService.products().then( response => {
      if (response.ok) {
        setProducts(response.data)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing])

  const handleClose = () => {
    setActivateShowing(false)
    setTopupShowing(false)
    onClose()
  }

  const {activeAccount} = useAuth()

  return (
    <>
      <Modal size={(values.status === 'active' && activeAccount && ((activeAccount.billing.balance && activeAccount.billing.balance > 0) || activeAccount.billing.dataPackageType !== 'prepay')) ? "xl" : 'md'} title={t('dashboard.activate.addButton')} show={isActivateModalShowing} onClose={handleClose}>
        
        {activeAccount ? (
          <>
             {((activeAccount.billing.balance && activeAccount.billing.balance > 0) || activeAccount.billing.dataPackageType !== 'prepay') ? (
              <form onSubmit={handleSubmit}>
                <Row spacer='small'>
                  <Col xs={values.status === 'active' ? "6" : "12"}>
                    <Heading type="sub" noMargin="top">{t('dashboard.activate.header')}</Heading>
                    <p className={styles.small}><Trans i18nKey='dashboard.activate.body' components={{bold: <strong />}} /></p>
                    <Input name="name" label={t('dashboard.activate.name')} placeholder={t('dashboard.activate.namePlaceholder')} onChange={handleChange} value={values.name} error={errors.name} />
                    <Input type="number" name="iccid" label={t('dashboard.activate.iccid')} placeholder={t('dashboard.activate.iccidPlaceholder')} onChange={handleChange} value={values.iccid} error={errors.iccid} />
                    <Input type="select" name="status" label={t('dashboard.activate.status')} placeholder={t('dashboard.activate.statusPlaceholder')} helpText={t('dashboard.activate.statusHelper')} onChange={handleChange} value={values.status} error={errors.status}>
                      <option value='active'>{t('misc.active')}</option>
                      <option value='inactive'>{t('misc.inactive')}</option>
                    </Input>
                  </Col>
                  {values.status === 'active' && (
                    <Col xs="6">
                      <Heading type="sub" noMargin="top">{t('dashboard.activate.planHeader')}</Heading>
                  <p className={styles.small}><trans i18nKey='dashboard.activate.planBody' components={{bold: <strong/>}} /></p>
                      <Input type="select" name="productId" label={t('dashboard.activate.planInput.label')} placeholder={t('dashboard.activate.planInput.placeholder')} onChange={handleChange} value={values.productId} error={errors.productId}>
                        <option value>{t('dashboard.activate.planInput.optionPlaceholder')}</option>
                        { products.map ( prod => (
                          <option key={prod.id} value={prod.id}>{prod.name} {(prod.type==="prepay" && activeAccount) ? `(${toCurrency(prod.perMbCost, activeAccount.billing.currency)}/MB)` : ''}</option>
                        ))}
                      </Input>
                      <Row>
                        <Col>
                          <Input type="number" name="dataLimit" label={t('dashboard.activate.dataLimitInput.label')} placeholder={t('dashboard.activate.dataLimitInput.placeholder')} helpText={t('dashboard.activate.dataLimitInput.helper')} onChange={handleChange} value={values.dataLimit} error={errors.dataLimit} />
                        </Col>
                        <Col xs="4">
                          <Input type="select" name="dataLimitMultiplier" label onChange={handleChange} value={values.dataLimitMultiplier} error={errors.dataLimitMultiplier}>
                            <option value='MB'>MB</option>
                            <option value='GB'>GB</option>
                          </Input>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Alert variant='warning'><small><Trans i18nKey="connectivity.sims.inactive.warning" components={{bold: <b/>}} /></small></Alert>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col align='right'>
                    <Button loading={isSubmitting} className="text-uppercase" type="submit">{t('dashboard.activate.addButton')}</Button>
                  </Col>
                </Row>
              </form>
            ) : (
              <>
              <Alert variant='warning'><Trans i18nKey="connectivity.sims.inactive.noCredit" components={{bold: <b/>}} /></Alert>
              <div className="text-right">
                <Button variant="link" onClick={handleClose}>{t('misc.return')}</Button>
                <Button onClick={handleToggleTopup}>{t('dashboard.topUpBalance')}</Button>
              </div>
              </>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Modal>

      <TopUpBalanceModal isShowing={isTopupModalShowing} onClose={handleClose} />
    </>
  ) 
}

export default ActivateSimModal;