import React, { useState } from 'react';
import { toCurrency } from '../../helpers/currency';
import Card, {CardBreak} from '../Card/Card';
import Button from '../Button/Button';
import { Row, Col } from '../Grid/Grid';
import Loader from '../Loader/Loader';
import billingService from '../../services/billingService';
import { toast } from 'react-toastify';
import orderService from '../../services/orderService';
import { Accordion, AccordionItem } from '../Accordion/Accordion';
import styles from './ProductPurchaseBlock.module.scss'
import {useTranslation} from 'react-i18next'
import QtyInput from '../QtyInput/QtyInput';
import accountService from '../../services/accountService';
import { useAuth } from '../../contexts/AuthContext';
import classnames from 'classnames';
import { PayPalButtons } from '@paypal/react-paypal-js';
import paypalOrderService from '../../services/paypalOrderService';
import { Form } from 'react-bootstrap';

const ProductPurchaseBlock = ({product, qty, order=null, createOrder=null, onQtyChange, loading, setLoading, newAddressDetails={}, onError=null, review=false}) => {
  const {t} = useTranslation()
  const {activeAccount} = useAuth()
   
  const handleGenerateOrder = () => {
    setLoading(true)
    if (!product) return
    const params = {
      source: "app",
      products: [
        {
          id: product.id,
          quantity: qty
        }
      ]
    }
  
    billingService.generateOrder(params).then( response => {
      if ( response.ok && createOrder ) {
        createOrder(response.data)
      }
      setLoading(false)
    })
  }

  const [error, setError] = useState();
  const handleCreateOrder = (data, actions) => {
    return new Promise((resolve, reject) => {
      orderService.orderPayment(order.id).then(response => {
        if (!response.data?.paypal_order_id) reject();
        const paypalID = response.data.paypal_order_id;
        paypalOrderService.createOrder(paypalID)
          .then(res => {
            resolve(res.data.id);
          })
          .catch(err => {
            toast.warning('There was an issue placing your order. Please try again soon')
            setLoading(false)
            reject();
          })
      });        
    });
  };
  const handlePaymentStarted = (data, actions) => {
    setLoading(true)
    return new Promise(async (resolve, reject) => {
      const newDetails = {}
      let errors = {}
      let shippingResp = null
      let billingResp = null

      if ( !newAddressDetails ) {
        resolve();
        return actions.resolve();
      }

      if ( newAddressDetails.shipping ) {
        try {
          shippingResp = await accountService.updateAddress(activeAccount.billing.deliveryAddress.id, newAddressDetails.shipping)
          if ( !shippingResp.ok ) {
            toast.error( t('toasts.shippingAddressError') )
            errors = { ...errors, shipping: shippingResp.data.errors }
          } else {
            newDetails.billing = { ...activeAccount.billing, deliveryAddress: shippingResp.data }
          }
        } catch {
          toast.error( t('toasts.shippingAddressError') )
        }
      }
      if ( newAddressDetails.billing ) {
        try {
          billingResp = await accountService.updateAddress(activeAccount.billing.billingAddress.id, newAddressDetails.shipping)
          if ( !billingResp.ok ) {
            toast.error( t('toasts.billingAddressError') )
            errors = { ...errors, billing: shippingResp.data.errors }
          } else {
            newDetails.billing = { ...activeAccount.billing, billingAddress: billingResp.data }
          }
        } catch {
          toast.error( t('toasts.billingAddressError') )
        }
      }

      if ( ( billingResp && !billingResp.ok ) || ( shippingResp && !shippingResp.ok ) ) {
        setLoading(false)
        if (onError) onError(errors)
        reject();
        return actions.reject();
      } else {
        resolve();
        return actions.resolve();
      }
    });
  }
  const handlePaymentError = () => {
    setLoading(false);
    setError(t('paypal.error.default'));
  }
  const handlePaymentCancel = () => {
    setLoading(false);
    setError(t('paypal.cancel.default'));
  }
  const handlePaymentApproved = (data, actions) => {
    const processPaymentURL = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_PROCESS_PAYMENT_ENDPOINT}?paypal-order-id=${data.orderID}`;
    window.location.href = processPaymentURL;
  }

  if (!review) {
    return (
      <Card>
        {!order ? (
          <>
            <Row spacer>
              <Col xs={12}>
                <h2 className={styles.title}>{product && product.title ? product.title : <Loader type="text" />}</h2>
                <span className={styles.subtitle}>{activeAccount && activeAccount.billing.currency && product && product.price ? `${toCurrency(product.price[activeAccount.billing.currency], activeAccount.billing.currency)} ${t('order.product.perSIM')}` : <Loader width={40} type="text" />}</span>
                {product && product.excerpt && (
                <div className={styles.description}>
                  {product.excerpt}
                </div>
                )}
              </Col>
            </Row>
            <Row spacer align="center">
              <Col xs={6}>
                <QtyInput value={qty} min={1} disabled={loading} onChange={onQtyChange} />
              </Col>
              <Col xs={6} className={styles.totals}>
                <span>{ t('store.total') }:</span> { activeAccount && activeAccount.billing.currency && product ? <span className={styles.price}>{toCurrency( qty * product.price[activeAccount.billing.currency], activeAccount.billing.currency )}</span> : <Loader /> }
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Button fullWidth onClick={handleGenerateOrder} loading={loading}>Checkout</Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
          <Row spacer="small">
            <Col xs={12}>
              <h2 className={styles.title}>{product && product.title ? product.title : <Loader type="text" />}</h2>
              <span className={styles.subtitle}>{activeAccount && activeAccount.billing.currency && product && product.price ? `${toCurrency(product.price[activeAccount.billing.currency], activeAccount.billing.currency)} ${t('order.product.perSIM')}` : <Loader type="text" />}</span>
              <hr />
              { order.shipments.map( (shipment, si) => shipment.orderLines.map( (orderline, oi) => (
                <div className={styles.orderDetail} key={`${si}-${oi}`}>
                  <span>{ `${orderline.quantity} x ${orderline.name}` }</span><span>{(activeAccount && activeAccount.billing.currency) ? toCurrency(orderline.subTotal, activeAccount.billing.currency) : <Loader type="text"/>  }</span>
                </div>
              )))}
              <div className={styles.orderDetail}>
                <span>{ t('store.shipping') }</span><span>{ (activeAccount && activeAccount.billing.currency) ? toCurrency( order.shipments.reduce( (acc, shipment) => acc + shipment.delivery.price, 0), activeAccount.billing.currency) : <Loader type="text"/> }</span>
              </div>
              <div className={styles.orderDetail}>
                <span>{ t('order.summary.vat') }</span><span>{ toCurrency( order.vatTotal ) }</span>
              </div>
              <hr />
              <div className={styles.orderDetail}>
                <span>{ t('store.orderTotal') }</span><span>{ toCurrency( order.total ) }</span>
              </div>               
              <CardBreak />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {error && (
                <Form.Text className="text-center text-danger mt-0 mb-2">{error}</Form.Text>
              )}
              <PayPalButtons
                onClick={handlePaymentStarted}
                onCancel={handlePaymentCancel}
                onError={handlePaymentError}
                createOrder={handleCreateOrder}
                onApprove={handlePaymentApproved}
              />

              {/* <Button fullWidth onClick={handlePaymentSubmit} loading={loading}>{ t('store.buyNow') } - { toCurrency(order.total) }</Button> */}
            </Col>
          </Row>
          </>
        )}
      </Card>
    )
  } else {
    return (
      <>
        <Accordion card justHide initial='shipment-review-0'>
          {order && order.shipments.map ( (shipment, i) => (
          <AccordionItem title={ `${t('misc.shipment')} #${i+1}` } subtitle={`${t('store.orderTotal')}: ${toCurrency(shipment.invoice.total, activeAccount && activeAccount.billing.currency)}`} item={`shipment-review-${i}`}>
              <hr />
              {shipment.orderLines.map( orderline => (
                <div className={styles.orderDetail}>
                  <span>{ `${orderline.quantity} x ${orderline.name}` }</span><span>{(activeAccount && activeAccount.billing.currency) ? toCurrency(orderline.subTotal, activeAccount.billing.currency) : <Loader type="text"/> }</span>
                </div>
              ))}
              <hr />
              <div className={styles.orderDetail}>
                <span>{ t('store.shipping') }</span><span>{(activeAccount && activeAccount.billing.currency) ? toCurrency( order.shipments.reduce( (acc, shipment) => acc + shipment.delivery.price, 0), activeAccount.billing.currency) : <Loader className="mb-0" type="text" />}</span>
              </div>
              <div className={styles.orderDetail}>
                <span>{ t('order.summary.vat') }</span><span>{(activeAccount && activeAccount.billing.currency) ?  toCurrency( order.vatTotal, activeAccount.billing.currency) : <Loader className="mb-1" type="text" />}</span>
              </div>
              <div className={classnames(styles.orderDetail, 'mb-0')}>
                <span>{ t('store.orderTotal') }</span><span>{(activeAccount && activeAccount.billing.currency) ? toCurrency( order.total, activeAccount.billing.currency) : <Loader className="mb-0" type="text" />}</span>
              </div>
          </AccordionItem>
          ))}
        </Accordion>

        {!order && (
          <Card>
            <p>no rodmsnkjd</p>

          </Card>
        )}
      </>
    )
  }     
}

export default ProductPurchaseBlock