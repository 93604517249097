import React from 'react';
import styles from './ProgressBar.module.scss'

const ProgressBar = ({progress, label=null, value=null}) => {
  return (
    <div className={styles.outer}>
      <div className={styles.inner} style={{width:`${progress}%`}}></div>
      <span className={styles.text}>{label && `${label}: `}{value}</span>
    </div>
  )
}

export default ProgressBar