import i18nService from './i18nService'

export const validate = (data, rules) => {
  const errorArr = {}

  // if there's any rules set..
  if (rules) {
  
    // loop through them
    Object.entries(rules).forEach( ruleEntry => {
      // separating rule key and values for ease of reading
      let dataType = null
      const ruleKey = ruleEntry[0]
      const ruleList = ruleEntry[1]
      // split rules separated by |
      const keyRules = ruleList.split('|')

      // foreach rule, if there's not already an error registered loop through and check
      keyRules.forEach( KR => {
        // splits seperate rules by : for things like 'min:10' etc
        let rule = KR.split(':')

        if(!errorArr[ruleKey]) {

          switch (rule[0]) {
            case 'required':
              if ((ruleKey in data  === false) || data[ruleKey] === "") {
                errorArr[ruleKey] = i18nService.t('validation.required', {'ruleKey': ruleKey})
              }
              break;
            case 'exact':
                if (data[ruleKey] !== rule[1]) {
                  errorArr[ruleKey] = i18nService.t('validation.exact', {'phrase': rule[1]})
                }
              break;
            case 'matches':
                if (rule[1] in data) {
                  if (data[rule[1]] !== data[ruleKey]) {
                    errorArr[ruleKey] = i18nService.t('validation.matches', {'phrase': rule[1]})
                  }
                }
              break;
            case 'email':
              dataType = 'email'
              const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g           
              if ((ruleKey in data ) && !emailRegex.test(data[ruleKey]) ) {
                errorArr[ruleKey] = i18nService.t('validation.email')
              }
              break;
            case 'min':
              if (ruleKey in data) {
                let contentSize  
                let errorText  
                let minSize
                if (dataType === 'number') {
                  contentSize = parseFloat(data[ruleKey])
                  errorText = i18nService.t('validation.minNumber', {'min': rule[1]})
                  minSize = parseFloat(rule[1])
                } else {
                  contentSize = data[ruleKey].length
                  errorText = i18nService.t('validation.minLength', {'min': rule[1]})
                  minSize = rule[1]
                }

                if (contentSize < minSize) {
                  errorArr[ruleKey] = errorText
                }
              }
              break;
            case 'max':         
            if (ruleKey in data) {
              let contentSize  
              let errorText  
              let maxSize
              
              if (dataType === 'number') {
                contentSize = parseFloat(data[ruleKey])
                errorText = i18nService.t('validation.maxNumber', {'max': rule[1]})
                maxSize = parseFloat(rule[1])
              } else {
                contentSize = data[ruleKey].length
                errorText = i18nService.t('validation.maxLength', {'max': rule[1]})
                maxSize = rule[1]
              }

              if (contentSize > maxSize) {
                errorArr[ruleKey] = errorText
              }
            }
              break;
            case 'number':         
              dataType = 'number'
              if ((ruleKey in data ) && isNaN(data[ruleKey])) {
                errorArr[ruleKey] = i18nService.t('validation.number', {'ruleKey': rule[1]})
              }
              break;
            default:
              break;
          }
        }
      })
    })  
  }

  // return error object
  return errorArr;
}