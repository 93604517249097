import React, { useState, useEffect } from 'react'
import RBTable from 'react-bootstrap/Table'
import styles from './Table.module.scss'
import Loader from '../Loader/Loader'
import { motion, useAnimation } from "framer-motion";
import { useLocation} from "react-router";
import { useTranslation } from 'react-i18next';

export const Table = ({headers, pagination, footerNote=false, sizing=null, basic=false, noHover=false, filters={}, search=false, onChange=()=>{}, children}) => {
  const [args, setArgs] = useState( {page: 1, limit: 10} )
  const [paginating, setPaginating] = useState(false)

  let location = useLocation();
  const {t} = useTranslation()

  useEffect( () => {
    if (location.search) {
      const trimmed = location.search.substr(1)
      const paramPairs = trimmed.split('&');
      const obj = paramPairs.reduce(function(acc, cur, i) {
        let split = cur.split('=')
        acc[split[0]] = split[1];
        return acc;
      }, {});

      setArgs({
        ...args,
        ...obj
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect( () => {
    setPaginating(false)
  }, [children])
  
  const handlePrevPage = () => {
    if (args.page > 1) {
      setArgs({ ...filters, ...args, page: args.page - 1})
      onChange({ ...filters, ...args, page: args.page - 1})
      setPaginating(true)
    }
  }
  const handleNextPage = () => {
    if (args.page < pagination.totalPages) {
      setArgs({ ...filters, ...args, page: args.page + 1})
      onChange({ ...filters, ...args, page: args.page + 1})
      setPaginating(true)
    }
  }

  const getAlignment = alignment => {
    if (alignment === 'center') {
      return `${styles.align} ${styles.center}`
    } else if (alignment === 'right') {
      return `${styles.align} ${styles.right}`
    }
    return null;
  }

  const getContent = () => {
    if (!children && !pagination) {
      return (
        <tr>
          { headers.map( header => (
            <td key={header.name} className={header.hasOwnProperty('align') ? getAlignment(header.align) : '' }>
              <Loader type="text" />
            </td>
          ))}
        </tr>
      )
    } else if (pagination && pagination.totalCount === 0 ) {
      return (
        <tr>
          <td colSpan={headers.length}>{t('misc.noResultsFound')}</td>
        </tr>
      )
    } else {
      return children
    }
  }

  const getStyles = () => {
    let baseStyles = styles.table

    if (basic) {
      baseStyles = `${styles.table} ${styles.tableBasic}`
    }

    if (sizing === 'large') {
      return `${baseStyles} ${styles.tableLarge}`
    } else if (sizing === 'small') {
      return `${baseStyles} ${styles.tableSmall}`
    } else {
      return baseStyles
    }
  }

  const getPaginationText = () => {
    let from = (args.page * args.limit) - (args.limit - 1) 
    let to = args.page * args.limit
 
    if (pagination.totalCount < to) {
      to = pagination.totalCount
    }

    return `${from} - ${to} ${t('misc.of')} ${pagination.totalCount}`
  }

  return (
    <RBTable className={getStyles()} striped={!basic} responsive borderless hover={noHover ? false : !basic}>
      <thead className={styles.thead}>
        <tr>
          { headers.map( header => (
            <th key={header.name} className={header.hasOwnProperty('align') ? getAlignment(header.align) : '' }>{header.name}</th>
          )) }
        </tr>
      </thead>
      <tbody>
        { getContent() }
      </tbody>
      { (footerNote || (pagination && pagination.totalPages > 1)) && (
      <tfoot>
        <tr>
          <td colSpan={headers.length} className={styles.footerWrap}>
            <div className={styles.footer}>
              <span className={styles.footerContent}>
                {footerNote || ''}
              </span>
              { (pagination && pagination.totalPages > 1) && (
                <div className={styles.footerPaginate}>
                  <span className={`${styles.paginatePrev} ${(paginating || args.page === 1) && styles.paginateInactive}`} onClick={!paginating ? handlePrevPage : undefined}>  </span>
                  <span className={`${styles.paginateText} ${paginating && styles.paginateInactive}`}> { getPaginationText() } </span>
                  <span className={`${styles.paginateNext} ${(paginating || args.page === pagination.totalPages) && styles.paginateInactive}`} onClick={!paginating ? handleNextPage : undefined}>  </span>
                </div>
              )}
            </div>
          </td>
        </tr>
      </tfoot>
      )}
    </RBTable>
  )
}

export const TableRow = ({value=null, onClick=null, children, delay=0}) => {
  const controls = useAnimation()

  useEffect(() => {
    controls.start(i => ({
      opacity: 1,
      y: 0,
      transition: { delay: delay * 0.05 },
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = e => {
    if (onClick) onClick(value)
  }

  return <motion.tr initial={{opacity: 0, y: -20}} delay={delay} animate={controls} onClick={handleClick} className={onClick && styles.clickable}>{children}</motion.tr>
}

export const NoDataRow = ({loading=false, cols=1, text=null}) => {
  return(
    <tr>
      { loading ? [...Array(cols)].map((e, i) => (
          <td key={i}><Loader type="text" /></td>
        )) 
      : (
        <td className={styles.noData} colSpan={cols}>{text}</td>
      )}
    </tr>
  )
}

export default Table