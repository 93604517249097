import React from 'react';
import Container from 'react-bootstrap/Container'
import {Row, Col} from '../../components/Grid/Grid';
import Card from '../../components/Card/Card';
import BigText from '../../components/BigText/BigText'
import InfoBlock from '../../components/InfoBlock/InfoBlock'
import Button from '../../components/Button/Button'
import ActivateSimsSplash from '../../components/ActivateSimsSplash/ActivateSimsSplash'
import {useAuth} from '../../contexts/AuthContext'
import {toCurrency} from '../../helpers/currency'
import Loader from '../../components/Loader/Loader'
import { Link } from 'react-router-dom';
import AlertModals from '../../containers/AlertModals/AlertModals'
import useModal from '../../hooks/useModal';
import TopUpBalanceModal from '../../containers/TopUpBalanceModal/TopUpBalanceModal';
import StoreAdvert from '../../components/StoreAdvert/StoreAdvert.js'
import { useTranslation } from 'react-i18next';

const HomeLayout = () => {
  const {activeAccount} = useAuth();
  const [isTopUpModalShowing, toggleTopUpModal] = useModal()

  const { t } = useTranslation();

  return (
    <>
      <ActivateSimsSplash accountType={activeAccount ? activeAccount.billing.dataPackageType : null} />
      <Container>
        <Row>
          <Col xs="8">
            {(activeAccount && activeAccount.billing.dataPackageType === 'prepay') && (
              <Card section title={t('dashboard.balanceHeader')}>
                  <Row align="bottom">
                    <Col>
                      <BigText value={activeAccount ? toCurrency(activeAccount.billing.balance, activeAccount.billing.currency) : null} label={t('settings.billing.balance')} />
                    </Col>
                    <Col align='right'>
                      <Button varient="primary" onClick={toggleTopUpModal}>+ {t('dashboard.topUpBalance')}</Button>
                    </Col>
                  </Row>
              </Card>
            )}
              
            <Card title={t('dashboard.accDetails.header')} link={<Link to="/settings/account">{t('dashboard.accDetails.editAccountDetails')} &gt;&gt;</Link>}>
              <InfoBlock label={t('dashboard.accDetails.accId')} value={activeAccount ? activeAccount.id : <Loader type="text" width={50} /> } />
              <InfoBlock label={t('dashboard.accDetails.accName')} value={activeAccount ? activeAccount.name : <Loader type="text" width={25} /> } />
              <InfoBlock label={t('dashboard.accDetails.vpnStatus')} value={activeAccount ? (activeAccount.vpn.active ? <span className='text-success'>{t('misc.active')}</span> : <span className='text-danger'>{t('misc.inactive')}</span>) : <Loader type="text" width={25} /> } />
            </Card>
          </Col>
          <Col xs="4">
            <StoreAdvert />
          </Col>
        </Row>
      </Container>
      {isTopUpModalShowing && (
        <TopUpBalanceModal isShowing={isTopUpModalShowing} onClose={toggleTopUpModal} />
      )}
      <AlertModals accepted={['topup']} />
    </>    
  )
}

export default HomeLayout