import React from 'react'
import styles from './Footer.module.scss'
import Container from 'react-bootstrap/Container'
import {Row, Col} from '../../components/Grid/Grid'
import {NavLink} from 'react-router-dom'
import {ReactComponent as LunerLogo} from '../../assets/images/lunerLogoLight.svg'
import { useTranslation, Trans } from 'react-i18next'
import LanguageSelect from '../../components/LanguageSelect/LanguageSelect'

const Footer = () => {
  const {t} = useTranslation()

  return ( 
    <footer className={styles.wrap}>
      <Container>
        <Row align="center" spacer="small">
          <Col xs='4' align="left">
            <div className={styles.logo}><LunerLogo /></div>
          </Col>
          <Col xs='8'>
            <nav className={styles.nav}>
              <NavLink exact to="/" className={styles.navItem} activeClassName={styles.activeNavItem}>{t('footer.nav.dashboard')}</NavLink>
              <NavLink to="/connectivity" className={styles.navItem} activeClassName={styles.activeNavItem}>{t('footer.nav.connectivity')}</NavLink>
              <NavLink to="/api" className={styles.navItem} activeClassName={styles.activeNavItem}>{t('footer.nav.api')}</NavLink>
              <NavLink to="/invoices" className={styles.navItem} activeClassName={styles.activeNavItem}>{t('footer.nav.invoices')}</NavLink>
              <LanguageSelect />
            </nav>
          </Col>
        </Row>
        <Row className={styles.copy}>
          <Col xs='4' align="left">
            <small>{t('footer.versionLabel')} { process.env.REACT_APP_VERSION_NUMBER }</small>
          </Col>
          <Col xs='8' align="right">
            <small>
              <Trans 
                i18nKey="footer.copyright"
                components={
                  { 
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    terms: <a href="https://luner.io/luner-customer-terms-and-conditions.pdf" target="_blank" rel="noopener noreferrer" />, 
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    sla: <a href="https://luner.io/luner-customer-sla.pdf" target="_blank" rel="noopener noreferrer" />
                  }
                }
              />
            </small>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer