import React from 'react'
import {Container, Alert} from 'react-bootstrap'
import {Row, Col} from '../../../../../components/Grid/Grid'
import SimStatusSwitch from '../../../../../components/SimStatusSwitch/SimStatusSwitch'
import BigText from '../../../../../components/BigText/BigText'
import Button from '../../../../../components/Button/Button'
import Card from '../../../../../components/Card/Card'
import Heading from '../../../../../components/Heading/Heading'
import { toReadableData } from '../../../../../helpers/data'
import useModal from '../../../../../hooks/useModal'
import DataLimitModal from './modals/DataLimitModal/DataLimitModal'
import RebootModal from './modals/RebootModal/RebootModal'
import SendAnSms from '../../../../../components/SendAnSms/SendAnSms'
import { Trans, useTranslation } from 'react-i18next'
import { useAuth } from '../../../../../contexts/AuthContext'

const SimControls = ({asset, onAssetUpdate}) => {
  const [isRebootShowing, toggleReboot] = useModal();
  const [isDataLimitsShowing, toggleDataLimits] = useModal();
  const {t} = useTranslation();
  const {activeAccount} = useAuth();

  const handleDataLimitsClose = (result=null) => {
    toggleDataLimits();
    if (result) {
      onAssetUpdate(result);
    }
  }

  return (
    <>
      <Container>
        <Row>
          <Col xs="4">
            <Card className={'mb-3'}>
              <Row spacer>
                <Col xs="12">
                  <Heading>{t('simOverview.controls.status')}</Heading>
                  <p><small>{t('simOverview.controls.statusBody')}</small></p>
                </Col>
                <Col xs="12">
                  <SimStatusSwitch asset={asset} onSwitch={onAssetUpdate} />
                </Col>
              </Row>
            </Card>
            {(activeAccount && activeAccount.billing.dataPackageType !== 'single_bundle') && (
            <Card className={'mb-3'}>
              <Row align='top'>
                <Col>
                  <Heading>{t('misc.dataLimit')}</Heading>
                </Col>
                <Col align='right'>
                  <Button variant="a" className="text-uppercase" onClick={toggleDataLimits}>{t('simOverview.controls.editLimit')}</Button>
                </Col>
              </Row>
              <Row spacer>
                <Col xs='12'>
                  <p><small>{t('simOverview.controls.dataLimitBody')}</small></p>
                </Col>
                <Col xs='12'>
                  <BigText value={ toReadableData(asset.dataLimit) }></BigText>
                </Col>
              </Row>
            </Card>
            )}
            <Card className={'mb-3'}>
              <Row align='top'>
                <Col xs="12">
                  <Heading>{t('simOverview.controls.reboot')}</Heading>
                  <p><small>{t('simOverview.controls.rebootBody')}</small></p>
                </Col>
                <Col xs="12" className={'mb-2'}>
                  <Button variant="danger" className="text-uppercase" onClick={toggleReboot}>{t('simOverview.controls.rebootButton')}</Button>
                </Col>
                <Col xs="12">
                  <Alert style={{marginBottom: 0}} variant='warning'>
                    <p style={{marginBottom: 0}}><small><Trans i18nKey="simOverview.controls.rebootWarning" components={{ bold: <b/> }} /></small></p>
                  </Alert>
                </Col>
              </Row>
              
            </Card>
          </Col>
          <Col xs="8">
            <Card title={t('simOverview.controls.sms.header')}>
              <SendAnSms iccid={asset.iccid} />
            </Card>
          </Col>
        </Row>
      </Container>
      <RebootModal show={isRebootShowing} onClose={toggleReboot} iccid={asset.iccid} />
      <DataLimitModal show={isDataLimitsShowing} onClose={handleDataLimitsClose} asset={asset ? asset : null} />
    </>
  )
}

export default SimControls