import React, { useContext, useState, useEffect } from 'react'
import authService from '../services/authService'
import accountService from '../services/accountService'
import userService from '../services/userService'
import cookieService from '../services/cookieService'
import history from '../helpers/history'
import { useTranslation } from 'react-i18next'

const AuthContext = React.createContext(false)

const AuthProvider = props => {
  const [ isInDashboard, setIsInDashboard] = useState(false)
  const [ activeUser, setActiveUser ] = useState(null)
  const [ activeAccount, setActiveAccount ] = useState(null) 
  const {i18n} = useTranslation()

  const [userLanguage, setUserLanguage] = useState('en')
  
  useEffect( () => {  
    if (cookieService.getCookie('LunerAPIToken')) {
      accountService.getActiveAccount().then( response => {
        if (response.ok) setActiveAccount(response.data)
      })
    }

    if (cookieService.getCookie('LunerTempToken')) {
      userService.getActiveUser().then( response => {
        if (response.ok) setActiveUser(response.data)
      })
    }
  }, [])

  // deals with language setting
  useEffect( () => {
    
    // if there's a logged in user
    if (activeUser && activeUser.language) {
      // if the users language is different to app language (stop unnesseccary refreshes)
      if (activeUser.language !== userLanguage) {
        setUserLanguage(activeUser.language)

        // if there's not a cookie, set one
        if (!cookieService.getCookie('LunerLang')) {
          const twoYear = new Date(new Date().setFullYear(new Date().getFullYear() + 2))
          cookieService.addCookie('LunerLang', activeUser.language, twoYear)
        }
      } 
    // else, if theres a language cookie
    } else if (cookieService.getCookie('LunerLang')) {
      setUserLanguage(cookieService.getCookie('LunerLang'))
    // try using the browser language
    } else {
      const l = window.navigator.userLanguage || window.navigator.language
      if (['en', 'es'].includes(l)) {
        setUserLanguage(l)
      }
    }

  }, [activeUser, userLanguage])


  useEffect( () => {
    i18n.changeLanguage(userLanguage)
  }, [i18n, userLanguage])

  const userLogin = (data) => {

    return authService.login(data).then(res => {
      if ( res.ok ) {
        userService.getActiveUser().then(response => {          
          if (response.ok) setActiveUser(response.data)
        })
        return {sucess: true}
      } else {
        return {sucess: false, message: 'an unknown error occurred'}
      }
    })
  }

  const accountLogin = accountId => {
    setActiveAccount(true)
    return authService.requestAccountSwitch(accountId).then( response => {
      if (response.ok) {
        accountService.getActiveAccount().then(response => {
          if (response.data) {
            setActiveAccount(response.data)
          }
        })
        return {sucess: true}
      } else {
        setActiveAccount(null)
        return {sucess: false, message: 'an unknown error occurred'}
      }
    }).catch( err => {
      setActiveAccount(null)
    })
  }
  
  const logout = () => {
    return new Promise( async (resolve, reject) => {
      await authService.logout()
      cookieService.removeCookie('LunerTempToken')
      cookieService.removeCookie('LunerAPIToken')
      setActiveUser(false)
      history.push('/login')

      setTimeout(() => {
        resolve()
      }, 200)
    })
  }   
  
  const loggedIn = () => cookieService.getCookie('LunerAPIToken') ? true : false
  const preloggedIn = () => cookieService.getCookie('LunerTempToken') ? true : false
  
  const switchAccounts = () => {
    const key = cookieService.getCookie('LunerAPIToken')
    cookieService.addCookie('LunerTempToken', key)
    cookieService.removeCookie('LunerAPIToken')
    setActiveAccount(null)
    history.push('/select-account')
  }

  // return the context provider with the logged in state and toggable functions
  return (
    <AuthContext.Provider value={{preloggedIn, loggedIn, isInDashboard, activeUser, activeAccount, setActiveUser, setActiveAccount, setIsInDashboard, userLogin, accountLogin, switchAccounts, logout, userLanguage, setUserLanguage}} {...props} />
  )
}

// custom hook to access the above 
// use (for eg.) const { login } = useAuth()
const useAuth = () => useContext(AuthContext)

export {AuthProvider, useAuth}