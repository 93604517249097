import React from 'react';
import styles from './Badge.module.scss'
import {ReactComponent as SuccessBadge} from './icons/successIcon.svg'
import {ReactComponent as WarningBadge} from './icons/warningIcon.svg'

const Badge = ({type, content}) => {

  const badge = {
    success: <SuccessBadge />,
    warning: <WarningBadge />,
  }
  return (
    <span className={`${styles.badge} ${styles[type]}`}>{badge[type]} <span>{content}</span></span>
  )
}

export default Badge