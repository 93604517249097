import React from 'react'
import RBModal from 'react-bootstrap/Modal'
import Button from '../../components/Button/Button'
import Heading from '../../components/Heading/Heading'
import styles from './Modal.module.scss'

const Modal = ({show=false, title=null, onClose, children, banner=null, size=null, fixed=false}) => {
  if (fixed) {
    return (
      <RBModal.Dialog size={size}>
        <RBModal.Header className={styles.header}>
          <RBModal.Title className={styles.headerTitle}>{title}</RBModal.Title>
        </RBModal.Header>
        <RBModal.Body>{children}</RBModal.Body>
      </RBModal.Dialog>
    )
  } else {    
    return (
      <RBModal dialogClassName={styles.modalMain} size={size} show={show} onHide={onClose}>
        { title && (
          <RBModal.Header className={styles.header}>
            <RBModal.Title className={styles.headerTitle}>{title}</RBModal.Title>
            <Button icon="delete" variant="a" style={{color:'#ad8bc7', fill:'#ad8bc7'}} size="sm" onClick={onClose} aria-label="close"></Button>
          </RBModal.Header>
        )}
        {banner && (
          <div className={styles[banner.type]}>
            <Heading type="banner">{banner.text}</Heading>
          </div>
        )}
        <RBModal.Body>{children}</RBModal.Body>
      </RBModal>
    )
  }
}

export const ModalSection = ({type, className, size=null, children}) => {
  
  const classes = [
    styles.modalSection,
    styles['modalSection--' + type],
    styles['modalSection--' + size],
    className
  ]
  
  return (
    <div className={classes.join(' ')}>
      {children}
    </div>
  )
}

export default Modal