import React from 'react';
import {Switch, Route} from 'react-router-dom'
import {useAuth} from '../../contexts/AuthContext'
import PageHeader from '../../components/PageHeader/PageHeader'
import Container from 'react-bootstrap/Container'
import Tab from 'react-bootstrap/Tab'

import Account from './tabs/Account/Account'
import Billing from './tabs/Billing/Billing'
import Users from './tabs/Users/Users'
import { useTranslation } from 'react-i18next';

const SettingsLayout = () => {
  const {activeAccount} = useAuth()
  const {t} = useTranslation()

  const tabs = [
    {name: t('settings.tabs.account'), target: '/settings/account'},
    {name: t('settings.tabs.billing'), target: '/settings/billing'},
    {name: t('settings.tabs.users'), target: '/settings/users'},
  ]

  return (
    <>
      <Tab.Container mountOnEnter defaultActiveKey="sim-info">
        <PageHeader title={activeAccount ? activeAccount.name : t('header.nav.myAccount')} tabs={tabs} />
        <Container>
          <Switch>
            <Route path="/settings/account" render={() => <Account />} />
            <Route path="/settings/billing" render={() => <Billing />} />
            <Route path="/settings/users" render={() => <Users />} />
          </Switch>
        </Container>
      </Tab.Container>
    </>    
  )
}

export default SettingsLayout