import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import styles from './ConnectivityMenu.module.scss'
import navStyles from '../Header.module.scss'
import {NavLink} from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import useModal from '../../../hooks/useModal'
import TopUpBalanceModal from '../../TopUpBalanceModal/TopUpBalanceModal'
import Loader from '../../../components/Loader/Loader'
import { toCurrency } from '../../../helpers/currency'
import { useAuth } from '../../../contexts/AuthContext'
import { useTranslation } from 'react-i18next'

const ConnectivityMenu = () => {
  const [isTopupModalOpen, toggleModal] = useModal();
  const match = useRouteMatch({
    path: '/connectivity/sims',
  })

  const {activeAccount} = useAuth()
  const {t} = useTranslation()

  return (
    <div className={styles.menu}>
      <Container>
        <div className={styles.submenu}>
          <div className={styles.subLeft}>
            <nav className={navStyles.nav}>
              <NavLink exact to="/connectivity/sims" className={navStyles.navItem} activeClassName={navStyles.activeNavItem}>{t('header.nav.submenu.sims')}</NavLink>
              {(activeAccount && activeAccount.billing.dataPackageType !== 'prepay' && activeAccount.billing.dataPackageType !== 'single_bundle') && (
                <NavLink exact to="/connectivity/plans" className={navStyles.navItem} activeClassName={navStyles.activeNavItem}>{t('header.nav.submenu.plans')}</NavLink>
              )}
              {/* <NavLink exact to="/connectivity/data-records" className={navStyles.navItem} activeClassName={navStyles.activeNavItem}>Data Records</NavLink> */}
              <NavLink exact to="/connectivity/order-sims" className={navStyles.navItem} activeClassName={navStyles.activeNavItem}>{t('header.nav.submenu.order')}</NavLink>
            </nav>
          </div>

          { (match && activeAccount && activeAccount.billing.dataPackageType === 'prepay') && (
            <div className={styles.subRight}>
              <span className="pr-3">{t('header.nav.submenu.balanceLabel')}: { activeAccount ? toCurrency(activeAccount.billing.balance, activeAccount.billing.currency) : <Loader className='px-2' variant='white' /> }</span>
              <Button className="h-100" onClick={toggleModal}>{t('header.nav.submenu.topUpButton')}</Button>
            </div>
          )}
          
        </div>
      </Container>

      <TopUpBalanceModal isShowing={isTopupModalOpen} onClose={toggleModal} />

    </div>
  )
}


export default ConnectivityMenu