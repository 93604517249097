import {request} from './requestService'

export const getActiveUser = body => {
  return request(`/users/me`)
}

export const update = body => {
  const args = {
    method: 'PUT',
    body: JSON.stringify(body)
  }

  return request(`/users/me`, args)
}

export const updatePassword = body => {
  const args = {
    method: 'PUT',
    body: JSON.stringify(body)
  }

  return request(`/users/me/password`, args)
}

export const users = () => {
  return request('/users')
}

export const removeUser = id => {
  const args = {
    method: 'DELETE'
  }
  return request(`/users/${id}`, args)
}

export const invites = () => {
  return request('/invites')
}

export const getInvite = token => {
  return request(`/invites/${token}`)
}

export const createInvite = body => {
  const args = {
    method: 'POST',
    body: JSON.stringify(body)
  }
  return request(`/invites`, args)
}

export const removeInvite = token => {
  const args = {
    method: 'DELETE'
  }
  return request(`/invites/${token}`, args)
}

export const acceptInvite = (token, body=null) => {
  const args = {
    method: 'POST'
  }
  if (body) args.body = JSON.stringify(body)
  return request(`/invites/${token}`, args)
}

export const resetPassword = (body, token) => {
  const args = {
    method: 'POST',
    body: JSON.stringify(body)
  }
  return request(`/auth/resetPassword/${token}`, args)
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  getActiveUser,
  update,
  updatePassword,
  users,
  removeUser,
  invites,
  getInvite,
  createInvite,
  removeInvite,
  acceptInvite,
  resetPassword
}