import React, {useRef, useState} from 'react'
import Modal from '../../components/Modal/Modal';
import {Row, Col} from '../../components/Grid/Grid'
import Input from '../../components/Input/Input'
import BigText from '../../components/BigText/BigText'
import accountService from '../../services/accountService'
import styles from './TopUpBalanceModal.module.scss'
import {useAuth} from '../../contexts/AuthContext'
import Loader from '../../components/Loader/Loader'
import {toCurrency} from '../../helpers/currency'
import {validate} from '../../services/validationService';
import { useTranslation, Trans } from 'react-i18next';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import paypalOrderService from '../../services/paypalOrderService';
import { Form } from 'react-bootstrap';


const TopUpBalanceModal = ({isShowing, toggle, onClose}) => {
  const {activeAccount} = useAuth()
  const {t} = useTranslation()
  const [{ isPending }] = usePayPalScriptReducer();

  const validateRules = {
    amount: 'number|min:5'
  }

  const getCurrencySymbol = () => {
    if (!activeAccount) return ' ';

    switch (activeAccount.billing.currency) {
      case 'USD':
        return '$'
      case 'EUR':
        return '€'
      default:
        return '£'
      
    }
  }

  const [value, setValue] = useState();
  const valueRef = useRef();
  valueRef.current = value;

  const handleCreateOrder = (data, actions) => {

    const args = {
      amount: valueRef.current * 100,
    }
    
    return new Promise(async (resolve, reject) => {
      accountService.topup(args)
        .then(response => {
          
          if (!response.data?.paypal_order_id) return undefined;
            const paypalID = response.data.paypal_order_id;

            paypalOrderService.createOrder(paypalID)
              .then(res => {
                resolve(res.data.id);
              })
              .catch(err => {
                return undefined;
              })
          })
          .then(error => {
            return undefined;
          })
    });
  };
   
  const [validationErrors, setValidationErrors] = useState({});
  const [error, setError] = useState();
  const handlePaymentStarted = () => {
    setError(undefined);
    setValidationErrors({});

    const errors = validate(
      {amount: parseFloat(valueRef.current)},
      validateRules
    );

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);      
      return false;
    }  
  }
  const handlePaymentError = () => setError(t('paypal.error.default'));
  const handlePaymentCancel = () => setError(t('paypal.cancel.default'));
  const handlePaymentApproved = (data, actions) => {
    const processPaymentURL = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_PROCESS_PAYMENT_ENDPOINT}?paypal-order-id=${data.orderID}`;
    window.location.href = processPaymentURL;
  }

  return (
    <Modal size="lg" title={t('account.topup.header')} show={isShowing} onClose={onClose}>
      {/* <form onSubmit={handleSubmit}> */}
        <Row spacer="small">
          <Col xs="12">
            <p className={styles.small}>{t('account.topup.description')}</p>
            <p className={styles.small}>
              <Trans
                i18nKey='account.topup.minimum'
                components={{ amt: activeAccount ? toCurrency(500, activeAccount.billing.currency) : <Loader /> }}
              />
            </p>
          </Col>
          <Col xs="6">
            <BigText label={t('account.topup.currentBalance')} value={ activeAccount ? toCurrency(activeAccount.billing.balance, activeAccount.billing.currency) : <Loader inline /> } />
          </Col>
          <Col xs="6">
            <Input type="number" prepend={getCurrencySymbol()} name="amount" label={t('account.topup.value')} placeholder={t('account.topup.valuePlaceholder')} onChange={(e) => {setValue(e.target.value)}} value={value} error={validationErrors.amount || null} />
          </Col>
        </Row>
        {isPending ? (
          <Loader />
        ) : (
          <>
            {error && (
              <Form.Text className="text-center text-danger mb-2">{error}</Form.Text>
            )}
            <PayPalButtons
              onClick={handlePaymentStarted}
              onCancel={handlePaymentCancel}
              onError={handlePaymentError}
              createOrder={handleCreateOrder}
              onApprove={handlePaymentApproved}
            />
          </>
          
        )}
      {/* </form> */}
    </Modal>
  )
}

export default TopUpBalanceModal