import React, {useState, useEffect} from 'react'
import GoogleMapReact from 'google-map-react'
import Loader from '../Loader/Loader'
import styles from './LocationMap.module.scss'
import assetService from '../../services/assetService'
import Heading from '../../components/Heading/Heading'
import {motion} from 'framer-motion'
import InfoBlock from '../InfoBlock/InfoBlock'
import Card from '../../components/Card/Card'
import {Row, Col} from '../../components/Grid/Grid'
import { useTranslation } from 'react-i18next'

const LocationMap = ({iccid}) => {
  const [loc, setLoc] = useState(null)
  const {t} = useTranslation()

  const anims = {
    hide: {y: -20, opacity: 0},
    show: {y: 0, opacity: 1}
  }

  useEffect( () => {
    if (iccid) {
      assetService.location(iccid).then( response => {
        let location = null
        if (response.data.location) {
          location = response.data.location
          if (location.lat) location.lat = parseFloat(location.lat)
          if (location.lng) location.lng = parseFloat(location.lng)
        }
        setLoc(location)
      })
    }
  }, [iccid])

  const mapOptions = (map) => {
    return {
      fullscreenControl: false, 
      streetViewControl: false,
      zoomControlOptions: {
        position: map.ControlPosition.TOP_RIGHT
      },
      mapTypeControl: false,
      styles: [
        {
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#e0e0e0"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#f5eff9"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#edd9fd"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#edd9fd"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.business",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#edd9fd"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#ebebeb"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#d8e8eb"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ]}
  }
  
  return(
    <div className={styles.mapWrap}>
      
      { !loc &&  <Card fullHeight><Col xs="12" align="center"><Loader /></Col></Card>}
      
      { (loc && loc.lat && loc.lng) && (
        <>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyAADKuPoyPeznSGAUoEXXpKmOspo2VJdOo'
            }}
            defaultZoom={12}
            center={loc}
            options={mapOptions}>
            <div className={styles.mapIcon}  lat={loc.lat} lng={loc.lng} text=''></div>
          </GoogleMapReact>
          <div className={styles.mapInfo}>
            <InfoBlock label='LAT:' value={loc.lat} />
            <InfoBlock noMargin label='LNG:' value={loc.lng} />
          </div>
        </>
      )}
      
      {(loc && (!loc.lat || !loc.lng)) && (
        <Card fullHeight>
          <motion.div variants={anims} initial='hide' animate='show' className={styles.notFound}>
            <Row align="center">
              <Col xs="12" align="center">
                <Heading>{t('simOverview.info.location.header')}</Heading>
              </Col>
              <Col xs="12" align="center">
                <span>{t('simOverview.info.location.body')}</span>
              </Col>
            </Row>
          </motion.div>
        </Card>
      )}

    </div>
  )
}

export default LocationMap