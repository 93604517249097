import React from 'react';
import Container from 'react-bootstrap/Container'
import {Row, Col} from '../../components/Grid/Grid'
import Button from '../../components/Button/Button'
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const NotFoundLayout = () => {
  const {t} = useTranslation()

  return (
    <Container>
      <Row spacer></Row>
      <Row spacer>
        <Col xs="12" align="center">
          <h1>{t('auth.notFoundHeader')}</h1>
        </Col>
        <Col xs="12" align="center">
          <p>{t('auth.notFoundBody')}</p>
        </Col>
      </Row>
      <Row spacer>
        <Col xs='12' align='center'>
          <Link to="/">
            <Button>{t('misc.returnHome')}</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

export default NotFoundLayout