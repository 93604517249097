import React, {useState, useEffect} from 'react';
import {Row, Col} from '../../components/Grid/Grid'
import Input from '../../components/Input/Input'
import styles from './SimFilters.module.scss'
import Icon from '../../components/Icon/Icon'
import Button from '../../components/Button/Button'
import useDebounce from '../../hooks/useDebounce'
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import AddSimDataModal from '../AddSimDataModal/AddSimDataModal';
import useModal from '../../hooks/useModal';

const SimsFilters = ({selected = [], filters=true, search=true, products, onFilter}) => {
  const [simFilters, setSimFilters] = useState({productId: '', status: '', q: ''})
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 600);
  const {t} = useTranslation()
  const [showAddData, toggleAddData] = useModal()
  const {activeAccount} = useAuth()

  useEffect( () => {
    const newFilters = {
      ...simFilters, 
      q: debouncedSearchTerm
    }
    setSimFilters(newFilters)
    onFilter(newFilters)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[debouncedSearchTerm])

  const handleSearchDebounce = e => {
    setSearchTerm(e.target.value)
  }

  const handleFilter = (e) => {
    const newFilters = {
      ...simFilters, 
      [e.target.name]: e.target.value
    }
    setSimFilters(newFilters)
    onFilter(newFilters)
  }

  const handleFilterReset = () => {
    const newFilters = {productId: '', status: '', q: ''}
    setSimFilters(newFilters)
    onFilter(newFilters)
  }
  
  return (
    <div className={styles.filters}>
      <Row className={'justify-content-between'}>
        {!filters && <Col xs='8'></Col>}

        {filters && (
          <>
            <Col xs="6" className={'d-flex align-items-center'}>
              <p className={'mb-0'}>{t('connectivity.sims.filters.label')}: </p>
              { (activeAccount && activeAccount.billing.dataPackageType !== "single_bundle") && (
              <div className={styles.filter}>
                <Input type="select" name="productId" onChange={handleFilter} value={simFilters.productId} className={styles.filter} slim>
                  <option disabled value=''>{t('connectivity.sims.filters.plansPlaceholder')}</option>
                  {products && products.map( prod => (
                    <option key={prod.id} value={prod.id}>{prod.name}</option>
                  ))}
                </Input>
              </div>
              )}
              <div className={styles.filter}>
                <Input type="select" name="status" onChange={handleFilter} value={simFilters.status} className={styles.filter} slim>
                  <option value=''>{t('connectivity.sims.filters.statusPlaceholder')}</option>
                  <option value='active'>{t('connectivity.sims.filters.activeStatus')}</option>
                  <option value='inactive'>{t('connectivity.sims.filters.inactiveStatus')}</option>
                  <option value='suspended'>{t('connectivity.sims.filters.suspendedStatus')}</option>
                </Input>
              </div>
              { (activeAccount && activeAccount.billing.dataPackageType !== "single_bundle") ? (
              <div className={styles.filterReset}>
                  <Button disabled={(!simFilters.q && !simFilters.productId && !simFilters.status)} onClick={handleFilterReset}>{t('connectivity.sims.filters.reset')}</Button>
              </div>
              ) : (
              <div className={styles.filterReset}>
                <Button disabled={selected.length === 0} onClick={toggleAddData}>{ t('connectivity.sims.filters.addData') } ({selected.length} { selected.length === 1 ? t('misc.sim') : t('misc.sims') })</Button>
              </div>
              )}
            </Col>
          </>
        )}
        <Col xs="4" align="right">
          {search && (
            <div className={styles.search}>
              <Icon type='search' iconStyle='primary' />
              <input name="q" className={styles.searchInput} onChange={handleSearchDebounce} placeholder={t('connectivity.sims.filters.searchPlaceholder')} />
            </div>
          )}
        </Col>
      </Row>

      <AddSimDataModal selected={selected} isShowing={showAddData} onClose={toggleAddData} />
    </div>
  )
}

export default SimsFilters