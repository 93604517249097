import React from 'react'

/**
 * Format API returned address, replacing line breaks with HTML
 * 
 * @param String address - the API unformatted address
 */
export const formatAddress = address => {
  const splitAddr = address.split(/\r\n/g)
  let formatted = splitAddr.map( item => {
    return <p className="mb-0">{item}</p>
  }) 

  return formatted
}