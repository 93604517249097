import React, {useState, useEffect} from 'react';
import {Row, Col} from '../../../../components/Grid/Grid'
import UserTable from '../../../../containers/UserTable/UserTable'
import InviteTable from '../../../../containers/InviteTable/InviteTable'
import InviteUserModal from '../../../../containers/InviteUserModal/InviteUserModal'
import Button from '../../../../components/Button/Button'
import Heading from '../../../../components/Heading/Heading';
import useModal from '../../../../hooks/useModal';
import userService from '../../../../services/userService'
import { useTranslation } from 'react-i18next';

const Users = () => {
  const [isInviteUserModalShowing, toggleUserInviteModal] = useModal()
  const {t} = useTranslation()

  const [invites, setInvites] = useState(null)

  const handleUserInviteComplete = (success=false) => {
    if (success) {
      handleInvitesRefresh()
    }

    toggleUserInviteModal()
  }

  const handleInvitesRefresh = () => {
    userService.invites().then( response => {
      setInvites(response.data)
    })
  }

  useEffect( () => {
    handleInvitesRefresh()
  },[])

  return (
    <>
      <Row align="center" spacer="small">
        <Col xs="6">
          <Heading section noMargin>{t('settings.users.current.header')}</Heading>
        </Col>
        <Col align="right" xs="6">
          <Button onClick={toggleUserInviteModal}>{t('settings.users.invites.new')}</Button>
        </Col>
      </Row>
      <Row spacer>
        <Col xs="12">
          <UserTable />
        </Col>
      </Row>
      <Row align="center" spacer="small">
        <Col xs="12">
          <Heading section noMargin>{t('settings.users.invites.header')}</Heading>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <InviteTable invites={invites} onDataRefresh={handleInvitesRefresh} />
        </Col>
      </Row>

      <InviteUserModal isShowing={isInviteUserModalShowing} onClose={handleUserInviteComplete} />
    </>
  )
}

export default Users