import React, { useEffect, useState } from 'react'
import Modal, {ModalSection} from '../../components/Modal/Modal'
import Input from '../../components/Input/Input'
import Button from '../../components/Button/Button'
import CountrySelect from '../../components/CountrySelect/CountrySelect'
import Radio from '../../components/Radio/Radio'
import useForm from '../../hooks/useForm'
import { useTranslation } from 'react-i18next'
import styles from './CreateAccountModal.module.scss'
import accountService from '../../services/accountService'
import { Redirect } from 'react-router'
import { toast } from 'react-toastify'

const CreateAccountModal = ({isShowing, onClose}) => {
  const {t} = useTranslation()
  const [step, setStep] = useState(1)
  const [needsVatNumber, setNeedsVatNumber] = useState(true);

  const validateRules = {}

  const proceed = () => {
    if ( step === 1) {
      const stepOneValidation = {
        name: 'required',
        type: 'required'
      }
  
      if ( validate(stepOneValidation) ) {
        setStep(2);
      }   
    } else {
      handleSubmit();
    }
  }


  const handleCreateAccount = () => {
    const body = {
      account: values
    }
    accountService.createAccount(body).then( res => {
      if (res.ok) {
        setStep(3)
      } else {
        toast.warn(t('toasts.accountCreateError'))
      }
    })
  }

  const {handleChange, handleSubmit, values, errors, setValues, resetValues, validate} = useForm(handleCreateAccount, validateRules);

  useEffect( () => {
    setValues({
      billing: {
        currency: "GBP",
        businessType: "company"
      },
      deliveryAddress: {
        country: "GB"
      },
      billingAddress: {
        country: "GB"
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setStep(1);
    resetValues();
    onClose();
  }

  const handleTypeChange = e => {
    setNeedsVatNumber( (e.target.value !== 'individual') )
    handleChange(e)
  }

  return (
    <Modal title={ t("auth.create.accountName") } show={isShowing} onClose={handleClose}>
      {step === 1 && (
        <p>{ t("auth.create.accNameDesc") }</p>
      )}
      <div className="mb-2">
      {step === 1 && (
        <>
          <ModalSection type="gray">
            <Input name="name" label={t('dashboard.accDetails.accName')} onChange={handleChange} error={errors && errors.name} />
          </ModalSection>

          <h3>{ t("auth.create.accountType") }</h3>
          <p>{ t("auth.create.accTypeDesc") }</p>
          
          <div className={styles.accTypeOptions}>
            <Radio name="type" label={t('auth.create.standard')} selected={values.type} onChange={handleChange} value='single_bundle' />
            <Radio name="type" label={t('auth.create.PAYG')} selected={values.type} onChange={handleChange} value='prepay' />
          </div>
          { (errors && errors.type) && (
            <small class="text-muted form-text">
              {errors.type}
            </small>
          )}
        </>
      )}

      { step === 2 && (
        <>
          <h3>Account Details</h3>
          <Input type='select' name="billing.currency" label={t('auth.create.labels.currency')} onChange={handleChange} error={(errors && errors.billing) && errors.billing.currency}>
            <option value="GBP">GBP</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
          </Input>
          <Input type='select' name="billing.businessType" label={t('auth.create.labels.businessType')} onChange={handleTypeChange} error={(errors && errors.billing) && errors.billing.businessType}>
            <option value="company">Company</option>
            <option value="individual">Individual</option>
          </Input>
          { needsVatNumber && (
            <Input name="billing.vatNumber" label={t('auth.create.labels.vatNumber')} onChange={handleChange} error={(errors && errors.billing) && errors.billing.vatNumber} />
          )}
          
           <h3>{ t('account.address.shipping.new') }</h3>
            <Input name="deliveryAddress.name" label={t('account.address.labels.name')} placeholder="Enter your name or company here..." onChange={handleChange} error={(errors && errors.deliveryAddress) && errors.deliveryAddress.name} />
            <Input name="deliveryAddress.line1" label={t('account.address.labels.lineOne')}  onChange={handleChange} error={(errors && errors.deliveryAddress) && errors.deliveryAddress.line1} />
            <Input name="deliveryAddress.line2" label={t('account.address.labels.lineTwo')} onChange={handleChange} error={(errors && errors.deliveryAddress) && errors.deliveryAddress.line2} />
            <Input name="deliveryAddress.city" label={t('account.address.labels.city')}  onChange={handleChange} error={(errors && errors.deliveryAddress) && errors.deliveryAddress.city} />
            <Input name="deliveryAddress.stateRegion" label={t('account.address.labels.state')}  onChange={handleChange} error={(errors && errors.deliveryAddress) && errors.deliveryAddress.stateRegion} />
            <Input name="deliveryAddress.postalCode" label={t('account.address.labels.postcode')}  onChange={handleChange} error={(errors && errors.deliveryAddress) && errors.deliveryAddress.postalCode} />
            <CountrySelect name="deliveryAddress.country" label={t('account.address.labels.country')}  onChange={handleChange} error={(errors && errors.deliveryAddress) && errors.deliveryAddress.country} />
            
            <h3>{ t('account.address.billing.new') }</h3>
            <Input name="billingAddress.name" label={t('account.address.labels.name')} placeholder="Enter your name or company here..." onChange={handleChange} error={(errors && errors.billingAddress) && errors.billingAddress.name} />
            <Input name="billingAddress.line1" label={t('account.address.labels.lineOne')}  onChange={handleChange} error={(errors && errors.billingAddress) && errors.billingAddress.line1} />
            <Input name="billingAddress.line2" label={t('account.address.labels.lineTwo')} onChange={handleChange} error={(errors && errors.billingAddress) && errors.bilbillingAddressling.line2} />
            <Input name="billingAddress.city" label={t('account.address.labels.city')}  onChange={handleChange} error={(errors && errors.billingAddress) && errors.billingAddress.city} />
            <Input name="billingAddress.stateRegion" label={t('account.address.labels.state')}  onChange={handleChange} error={(errors && errors.billingAddress) && errors.billingAddress.stateRegion} />
            <Input name="billingAddress.postalCode" label={t('account.address.labels.postcode')}  onChange={handleChange} error={(errors && errors.billingAddress) && errors.billingAddress.postalCode} />
            <CountrySelect name="billingAddress.country" label={t('account.address.labels.country')}  onChange={handleChange} error={(errors && errors.billingAddress) && errors.billingAddress.country} />
        </>
      )}

      {step === 3 && (
        <Redirect to="/" />
      )}
      </div>

      <Button fullWidth onClick={proceed}>{ t('misc.continue') }</Button>

    </Modal>
  )
}

export default CreateAccountModal