import React, {useState} from 'react';
import {Row, Col} from '../../../components/Grid/Grid';
import Card from '../../../components/Card/Card';
import Button from '../../../components/Button/Button'
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal/Modal'
import userService from '../../../services/userService';
import useModal from '../../../hooks/useModal';
import { Trans, useTranslation } from 'react-i18next';

const ExistingUserSignup = ({invite, onProgress}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isRejectShowing, toggle] = useModal()
  const {t} = useTranslation()

  const handleInviteProcessAttempt = () => {
    setIsSubmitting(true)
    return userService.acceptInvite(invite.token).then( response => {
      if (response.ok) {
        onProgress('addition')
      } else {
        toast.error(t('toasts.createAccountError'))
      }
      setIsSubmitting(false)
    })
  }

  const handleRejectInvite = () => {
    userService.removeInvite(invite.token).then( response => {
      if (response.ok) {
        onProgress('reject')
      } else {
        toast.error(t('toasts.rejectInviteError'))
      }
    })
  }

  return (
    <>
      <Modal show={true} size={'md'} fixed title={t('invite.existingHeader')}>
        <div className={'text-left'}>
          <Row spacer>
            <Col xs="12">
              <p>
              <Trans i18nKey="invite.existingBody" values={{ acc: invite.account.name}} />

              </p>
              <Card productCard='active' slim title={`${t('invite.youreInvited')}:`}>
                <h3>{invite.account.name}</h3>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12" align='right'>
              <Button loading={isSubmitting} onClick={toggle} variant="link">{t('invite.reject')}</Button>
              <Button loading={isSubmitting} onClick={handleInviteProcessAttempt}>{t('invite.accept')}</Button>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal size="lg" title={t('invite.rejectModal.header')} show={isRejectShowing} onClose={toggle}>
        <p>{t('invite.rejectModal.body')}</p>
        <p>{t('invite.rejectModal.continue')}</p>
        <Row>
          <Col xs='12' align="right">
            <Button onClick={toggle} variant="link">{t('misc.cancel')}</Button>
            <Button onClick={handleRejectInvite}>{t('invite.reject')}</Button>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default ExistingUserSignup