import React from 'react';
import {ReactComponent as SwitchIcon} from '../../assets/icons/switchIcon.svg'
import {ReactComponent as SettingsIcon} from '../../assets/icons/settingsIcon.svg'
import {ReactComponent as ProfileIcon} from '../../assets/icons/userIcon.svg'
import {ReactComponent as PowerIcon} from '../../assets/icons/powerIcon.svg'
import {ReactComponent as SearchIcon} from '../../assets/icons/searchIcon.svg'
import {ReactComponent as GoIcon} from '../../assets/icons/doubleArrow.svg'
import {ReactComponent as GoLightIcon} from '../../assets/icons/doubleArrowLight.svg'
import {ReactComponent as PlusIcon} from '../../assets/icons/plusIcon.svg'
import {ReactComponent as RemoveIcon} from '../../assets/icons/RemoveIcon.svg'

import styles from './Icon.module.scss'

const Icon = ({type, iconStyle=null}) => {

  switch (type) {
    case 'switch':
      return <SwitchIcon className={styles[iconStyle]} />
    case 'settings':
      return <SettingsIcon className={styles[iconStyle]} />
    case 'profile':
      return <ProfileIcon className={styles[iconStyle]} />
    case 'power':
      return <PowerIcon className={styles[iconStyle]} />
    case 'search':
      return <SearchIcon className={styles[iconStyle]} />
    case 'go':
      return <GoIcon className={styles[iconStyle]} />
    case 'go-light':
      return <GoLightIcon className={styles[iconStyle]} />
    case 'plus':
      return <PlusIcon className={styles[iconStyle]} />
    case 'remove':
      return <RemoveIcon className={styles[iconStyle]} />
    default:
      return null
  }
}

export default Icon