import React from 'react'
import Button from '../Button/Button'
import styles from './ConfirmButton.module.scss'

const ConfirmButton = ({
  buttonStyle=null,
  size='sm',
  icon=null,
  variant='link',
  data,
  onClick,
  children
}) => {

  const handleClick = () => {
    onClick(data)
  }

  const generateStyles = () => {
    if (buttonStyle) {
      switch (buttonStyle) {
        case 'danger':
          return styles.danger
        case 'warning':
          return styles.warning
        case 'white':
          return styles.white
        default:
          return
      }
    }
  }

  return (
    <Button variant={variant} className={generateStyles()} size={size} icon={icon} onClick={handleClick}>{children}</Button>
  )
}

export default ConfirmButton