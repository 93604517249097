import React, {useState, useEffect} from 'react'
import {Table, TableRow} from '../../components/Table/Table'
import billingService from '../../services/billingService'
import {toReadableDateTime} from '../../helpers/data'
import {toCurrency} from '../../helpers/currency'
import Button from '../../components/Button/Button'
import PayInvoiceButton from '../../components/PayInvoiceButton/PayInvoiceButton'
import {toast} from 'react-toastify'
import Loader from '../../components/Loader/Loader'
import { useTranslation } from 'react-i18next'

const InvoiceTable = () => {
  const [downloading, setDownloading] = useState(null)
  const [invoices, setInvoices] = useState([])
  const [args, setArgs] = useState({})
  const {t} = useTranslation()

  const handleArgsChange = newArgs => {
    setArgs( {...args, ...newArgs})
  }

  const headers = [
    {name: t('invoices.list.headers.number')},
    {name: t('invoices.list.headers.date')},
    {name: t('invoices.list.headers.status')},
    {name: t('invoices.list.headers.amount')},
    {name: t('invoices.list.headers.actions')}
  ]

  useEffect( () => {
    billingService.invoices(args).then( response => {
      setInvoices(response.data)
    })
  },[args])

  const handleDownloadInvoice = e => {
    setDownloading(e.target.value)
    billingService.downloadInvoice(e.target.value)
    .then( response => {
      if (response.ok) {
        window.open(response.data.redirect_url, '_blank')
      }

      if (response.status === 404) {
        toast.error(t('toasts.generateInvoiceError'))
      }
      setDownloading(null)
    })
  }

  return (
    <>
      <Table headers={headers} pagination={invoices ? invoices.pagination : null} onChange={handleArgsChange}>
        {(invoices && invoices.results) && invoices.results.map ((invoice, invIndex) => (
          <TableRow delay={invIndex} key={invoice.id}>
            <td>{invoice.number}</td>
            <td style={{width:'99%'}}>{toReadableDateTime(invoice.createdAt)}</td>
            <td>{invoice.paid ? t('invoices.list.paid') : t('invoices.list.unpaid')}</td>
            <td>{toCurrency(invoice.total, invoice.currency)}</td>
            <td className="text-nowrap">
              <Button size="sm" icon="download" value={invoice.id} onClick={handleDownloadInvoice}>{downloading === invoice.id ? <Loader className='ml-4' /> : t('misc.download')}</Button>
              {invoice.paid === false &&
                <PayInvoiceButton invoice={invoice} />
              }
            </td>
          </TableRow>
        ))}
      </Table>
    </>
  )
}

export default InvoiceTable