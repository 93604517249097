import React, {useState, useEffect} from 'react'
import {useLocation} from "react-router-dom";
import TopupResultModal from './modals/TopupResultModal'
import BundleResultModal from './modals/BundleResultModal'
import InvoicePaymentModal from './modals/InvoicePaymentModal'
import useModal from '../../hooks/useModal'

const AlertModals = ({accepted}) => {
  const[parsed, setParsed] = useState([])
  const [isShowing, toggleModal] = useModal()
  const location = useLocation()
  const params = location.search
  
  useEffect( () => {
    const trimmed = params.substr(1)
    const paramPairs = trimmed.split('&');
    const parsedParams = paramPairs.map( pair => {
      return pair.split('=')
    })

    parsedParams.filter( param => {
      return accepted.indexOf(param[0]) !== -1
    })

    if (parsedParams.length > 0) {
      setParsed(parsedParams[0])
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  switch (parsed[0]) {
    case 'topup':
      return <TopupResultModal isShowing={!isShowing} onClose={toggleModal} success={parsed[1] === 'payment-success' ? true : false} />;
    case 'status':
      return <InvoicePaymentModal isShowing={!isShowing} onClose={toggleModal} success={parsed[1] === 'payment-success' ? true : false} />;
    case 'single-bundle-payment':
      return <BundleResultModal isShowing={!isShowing} onClose={toggleModal} success={parsed[1] === 'payment-success' ? true : false} />;
    default: 
      return null;
  }
}

export default AlertModals