import {request} from './requestService';
import {generateParameters} from './requestService'

export const cards = () => {
  return request('/billing/cards')
}

export const addCard = body => {
  const args = {
    method: 'POST',
    body: JSON.stringify(body)
  }
  return request('/billing/cards', args)
}

export const invoices = args => {
  return request(`/billing/invoices${generateParameters(args)}`)
}

export const generateOrder = body => {
  const args = {
    method: 'POST',
    body: JSON.stringify(body)
  }
  return request('/shop/order', args)
}

export const downloadInvoice = id => {
  return request(`/billing/invoices/${id}/generate`)
}

export const payInvoice = (id) => {
  const args = {
    method: 'POST'
  }
  return request(`/billing/invoices/${id}/pay`, args)
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  cards,
  addCard,
  invoices,
  generateOrder,
  downloadInvoice,
  payInvoice
}