import React from 'react'
import Modal from '../../../components/Modal/Modal'
import Heading from '../../../components/Heading/Heading'
import Button from '../../../components/Button/Button'
import {Row, Col} from '../../../components/Grid/Grid'
import { useTranslation, Trans } from 'react-i18next'

const TopupResultModal = ({isShowing, onClose, success}) => {
  const {t} = useTranslation()

  const successText = {
    title: t('account.topup.attempt.success.header'),
    header: t('account.topup.attempt.success.description'),
    body: t('account.topup.attempt.success.body'),
    
  }
  const failureText = {
    title: t('account.topup.attempt.failure.header'),
    header: t('account.topup.attempt.failure.description'),
    body: null
  }

  let text = (success===true) ? successText : failureText

  return (
    <Modal size="lg" title={text.title} show={isShowing} onClose={onClose}>
      <Heading>{text.header}</Heading>
      {success ? (
        <p>{text.body}</p>
      ) : (
        <p>
          <Trans
            i18nKey="account.topup.attempt.failure.body"
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            components={{ helpLink: <a href="mailto:support@luner.io"/> }}
          />
        </p>
      )}

      <Row>
        <Col align="right">
          <Button size="lg" className="text-uppercase" onClick={onClose}>{t('misc.returnHome')}</Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default TopupResultModal