export const queryParams = queryString => {
  const trimmed = queryString.substr(1)
  const paramPairs = trimmed.split('&');
  const obj = paramPairs.reduce(function(acc, cur, i) {
    let split = cur.split('=')
    acc[split[0]] = split[1];
    return acc;
  }, {});

  return obj;
}

export const queryParam = (queryString, tgtParam) => {
  const trimmed = queryString.substr(1)
  const paramPairs = trimmed.split('&');
  const obj = paramPairs.reduce(function(acc, cur, i) {
    let split = cur.split('=')
    acc[split[0]] = split[1];
    return acc;
  }, {});

  if (obj[tgtParam] !== null) return obj[tgtParam]
  return null;
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {queryParams, queryParam}