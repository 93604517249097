import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom'
import './index.scss';
import App from './containers/App/App';
import Loader from './components/Loader/Loader';
import { AuthProvider } from './contexts/AuthContext';
import {ToastContainer} from 'react-toastify'
import history from './helpers/history'

// multi-language needs bundled
import './services/i18nService';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const paypalConfig = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "GBP",
  intent: "capture",
};

ReactDOM.render(
<AuthProvider>
  <PayPalScriptProvider options={paypalConfig}>
    <Router history={history}>
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    </Router>
  </PayPalScriptProvider>
  <ToastContainer closeOnClick autoClose={3000} />
</AuthProvider>, document.getElementById('root'));