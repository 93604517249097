import React, {useState} from 'react';
import Card from '../../components/Card/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button'
import useForm from '../../hooks/useForm';
import {Link} from 'react-router-dom'
import Alert from 'react-bootstrap/Alert';
import AuthService from '../../services/authService';
import { useTranslation } from 'react-i18next';

const PasswordResetLayout = () => {
  const [alert, setAlert] = useState(null)
  const {t} = useTranslation()
  
  // handle errors
  const handleResetAttemptError = e => {
    setAlert(e.data.message)
  }
  
  const handleResetAttempt = () => {
    return AuthService.resetPasswordRequest(values).then(() => {
        setAlert(t())
    }).catch( err => {
      handleResetAttemptError(err)
    })
  }
    
  const validateRules = {
    email: 'required|email',
  }

  const { handleChange, handleSubmit, values, errors, isSubmitting } = useForm(handleResetAttempt, validateRules);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={7}>
          <Card title={t('auth.forgotPassword')}>
            <Form onSubmit={handleSubmit} method='POST'>
              <Input type="email" name="email" label={t('misc.email')} placeholder={t('misc.email')} onChange={handleChange} value={values.email} error={errors.email}/>
              <Form.Row className="text-right">
                <Col> 
                <Link to="/login"><Button disabled={isSubmitting} variant="link">{t('auth.login')}</Button></Link>
                  <Button loading={isSubmitting} type="submit" variant="primary">{t('auth.resetPassword')}</Button>
                </Col>
              </Form.Row>
              { alert && (
                <Alert className="mt-3 mb-0" variant={'warning'}>{alert}</Alert>
              )}
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default PasswordResetLayout;