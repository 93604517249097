import React from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import EditProfileModal from '../../../containers/EditProfileModal/EditProfileModal'
import useModal from '../../../hooks/useModal'
import { useAuth } from '../../../contexts/AuthContext'
import Icon from '../../../components/Icon/Icon'
import styles from './HeaderUserPanel.module.scss'
import { useTranslation } from 'react-i18next'

const HeaderUserPanel = ({user}) => {

  const {logout, switchAccounts} = useAuth()
  const [isProfileModalOpen, toggleProfileModal] = useModal()
  const history = useHistory()
  const {t} = useTranslation()

  const handleLogout = e => {
    logout().then( () => {
      history.push('/login')
    })
  }

  const handleSwitch = () => {
    switchAccounts();
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div ref={ref} className={styles.toggle} onClick={onClick}>
      {children}
    </div>
  ));

  return (
    <>
      <Dropdown alignRight>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
          <h5 className={styles.userPanelName}>{user.name}</h5>
          <h6 className={styles.userPanelCompany}>{user.company}</h6>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item className={styles.item} eventKey='1' onClick={handleSwitch}>
            <span>{t('header.userPanel.switch')}</span> <Icon type='switch' iconStyle='primary' />
          </Dropdown.Item>
          <NavLink to="/settings">
            <Dropdown.Item className={styles.item} as='div' eventKey='2'>
              <span>{t('header.userPanel.settings')}</span> <Icon type='settings' iconStyle='primary'/>
            </Dropdown.Item>
          </NavLink>
          <Dropdown.Item className={styles.item} eventKey='3' onClick={toggleProfileModal}>
            <span>{t('header.userPanel.profile')}</span> <Icon type='profile' iconStyle='primary'/>
          </Dropdown.Item>
          <Dropdown.Item className={styles.item} eventKey='4' onClick={handleLogout}>
            <span>{t('header.userPanel.logout')}</span> <Icon type='power' iconStyle='primary'/>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <EditProfileModal isShowing={isProfileModalOpen} onClose={toggleProfileModal} />
    </>
  )
}

export default HeaderUserPanel