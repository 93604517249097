import DateTime from 'luxon/src/datetime.js'

/**
 * translate byte quantities to the most sane human readable format
 * @param {*} mbs - the qty of MBs to translate
 * @param {*} forceSize - force which factor (MB/GB/TB etc) the function will return in
 * @param {*} decimals - the amount of decimal places allowed
 * @returns {string} - formatted size string
 */
export const toReadableData = (bytes, forceSize = '', decimals = 2) => {
  if (bytes === 0) return '0 MB'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  const forceSizeIndex = sizes
    .map(size => size.toLowerCase())
    .indexOf(forceSize.toLowerCase())

  const useSize = forceSizeIndex !== -1 ? sizes[i + forceSizeIndex] : sizes[i]
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + useSize
}

/**
 * translate MB quantities to the most sane human readable format
 * @param {*} mbs - the qty of MBs to translate
 * @param {*} forceSize - force which factor (MB/GB/TB etc) the function will return in
 * @param {*} decimals - the amount of decimal places allowed
 * @returns {string} - formatted size string
 */
// TODO: This could be removed if we can consistently use bytes on the API
export const toReadableMbData = (mbs, forceSize = '', decimals = 2) => {
  const bytes = mbs * 1024 * 1024
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  const forceSizeIndex = sizes
    .map(size => size.toLowerCase())
    .indexOf(forceSize.toLowerCase())
  const useSize = forceSizeIndex !== -1 ? sizes[i + forceSizeIndex] : sizes[i]
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + useSize
}

/**
 * translate a numeric count of GB, MB or KB to bytes
 * @param {number} amount - the quantity of GB/MB/KB
 * @param {'GB' | 'MB' | 'KB'} factor - GB, MB or KB
 * @returns {number} - the amount of bytes
 */
export const toBytes = (amount, factor) => {
  if (!amount) return
  let conversionRate
  switch ( factor ) {
    case 'GB':
      conversionRate = 1073741824
      break
    case 'MB':
      conversionRate = 1048576
      break
    case 'KB':
      conversionRate = 1024
      break
    default:
      return
  }
  return amount * conversionRate
}

/**
 * translate endpoint datetime to frontend displayable format
 * @param {string} datetime - the ISO dateTime from an endpoint
 * @param {string | null} format - if endpoint datetime is standard ISO leave null, else pass in a luxon format string to match datetime format
 * @param {string} length - what format of date to return - DATE_SHORT/DATETIME_MED/TIME_SIMPLE etc, see Luxon docs for full list
 * @returns {string} nicely formatted datetime string
 */
export const toReadableDateTime = (datetime, format=null, length='DATETIME_FULL') => {
  if (!format) {
    return DateTime.fromISO(datetime).toLocaleString(DateTime[length])
  }
  return DateTime.fromFormat(datetime, format).toLocaleString(DateTime[length])
}