import React, {useEffect} from 'react'
import Modal from '../../../../../../../components/Modal/Modal'
import {Row, Col} from '../../../../../../../components/Grid/Grid'
import Input from '../../../../../../../components/Input/Input'
import Button from '../../../../../../../components/Button/Button'
import assetService from '../../../../../../../services/assetService'
import {toast} from 'react-toastify'
import useForm from '../../../../../../../hooks/useForm'
import {toBytes} from '../../../../../../../helpers/data'
import { toReadableData } from '../../../../../../../helpers/data'
import { useTranslation } from 'react-i18next'

const DataLimitModal = ({show, onClose, asset}) => {
  const {t} = useTranslation()

  useEffect( () => {    
    let dataLimit = null
    if (asset) {
      dataLimit = toReadableData(asset.dataLimit)
      dataLimit = dataLimit.split(' ');
    }

    const defaults = {
      iccid: asset ? asset.iccid : '',
      limit: dataLimit ? dataLimit[0] : '',
      dataLimitMultiplier: dataLimit ? dataLimit[1] : 'MB',
    }
    setDefaults(defaults)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset])

  const validateRules = {
    iccid: 'required',
    limit: 'required',
    dataLimitMultiplier: 'required'
  }

  const handleEditAttempt = args => {
    const body = {
      iccid: asset ? asset.iccid : null,
        dataLimit: toBytes(values.limit, values.dataLimitMultiplier)
    }
    return assetService.updateAsset(asset ? asset.iccid : null, body).then(response => {
      if (response.ok) {
        onClose(response.data)
        toast.success(t('toasts.dataLimitSet'))
      } else {
        if (response.data.errors.dataLimit) {
          toast.warning(response.data.errors.dataLimit[0])
        } else {
          toast.warning(t('toasts.dataLimitError'))
        }
      }
    })
  }

  const { handleChange, handleSubmit, isSubmitting, values, setDefaults, errors } = useForm(handleEditAttempt, validateRules)

  return (
    <Modal title={t('simOverview.info.editDataLimit')} show={show} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs='7'>
            <Input className="mb-0" type="number" name="limit" label={`${t('simOverview.info.dataLimitLabel')}:`} onChange={handleChange} value={values.limit} error={errors.limit} />
          </Col>
          <Col xs='5'>
            <Input className="mb-0" type="select" name="dataLimitMultiplier" label onChange={handleChange} value={values.dataLimitMultiplier} error={errors.dataLimitMultiplier}>
              <option value='MB'>MB</option>
              <option value='GB'>GB</option>
            </Input>
          </Col>
          <Col xs='12'>
            <span className="d-inline-block mb-2 text-danger">{ t('simOverview.info.dataLimitText') }</span>
          </Col>
        </Row>
        <Row>
          <Col xs='12' align="right">
            <Button className="text-uppercase" type='submit' loading={isSubmitting}>{t('misc.updateChanges')}</Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default DataLimitModal