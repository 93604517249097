import React from 'react';
import styles from './StatusWindow.module.scss';
import classnames from 'classnames';

const StatusWindow = ({children, type=null}) => {
  return (
    <div className={classnames(styles.window, type ? styles['window-'+type] : null) }>
      {children}
    </div>
  )
}

export default StatusWindow