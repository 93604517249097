
import React, {useEffect, useState} from 'react'
import {AnimatePresence, motion} from 'framer-motion'
import styles from './Accordion.module.scss'
import classnames from 'classnames'

export const Accordion = ({initial=-1, children, card=false, onChange=null, justHide=false}) => {
  const [active, setActive] = useState(initial)

  useEffect( () => {
    if (onChange) onChange(active)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  return (
    <div>
      { React.Children.map( children, child => React.cloneElement(child, { onClick: setActive, activeItem: active, justHide: justHide, card: card } ) ) }
    </div>
  )
}

export const AccordionItem = ({item, title=null, subtitle=null, activeItem, justHide, startOpen=false, disabled=false, onClick, card=false, small=false, children}) => {
  
  const accordionItemAnims = {
    out: {height: 0},
    in: {height: 'auto'},
  }

  const handleClick = () => onClick(item)

  return (
    <div className={classnames(styles.item, card && styles.card, item === activeItem && styles.active, !children && styles.noChildren, disabled && styles.disabled)}>
      <div className={styles.header} onClick={!disabled ? handleClick : null}>
        <span className={styles.radio}></span>
        
        <span className={styles.titleWrap}>
          <span className={styles.title}>{title || 'item'}</span>
          {subtitle && (
            <span className={styles.subtitle}>{subtitle}</span>
          )}
        </span>
      </div>
      
      <ConditionalAnimate needed={!justHide}>
        {((children && justHide) || (children && item === activeItem)) && (
          <motion.div style={{overflow:'hidden'}} variants={accordionItemAnims} key={item} initial={activeItem ? 'in' : 'out'} animate={ (children && item === activeItem) ? 'in' : 'out' } exit={ !justHide ? 'out' : undefined} >
            <div className={`${styles.body} ${small && styles.smallBody}`}>
              {children}
            </div>
          </motion.div>
        )}
      </ConditionalAnimate>
    </div>
  )
}

const ConditionalAnimate = ({needed, children}) => {
  if (needed) {
    return (
      <AnimatePresence>
        {children}
      </AnimatePresence>
    )
  } else {
    return children
  }
}