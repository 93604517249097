import React, { useEffect, useState } from 'react';
import {Row, Col} from '../../../../../components/Grid/Grid'
import Button from '../../../../../components/Button/Button'
import {Table, TableRow, NoDataRow} from '../../../../../components/Table/Table'
import reportService from '../../../../../services/reportService'
import useModal from '../../../../../hooks/useModal';
import Modal from '../../../../../components/Modal/Modal'
import Heading from '../../../../../components/Heading/Heading'
import VpnIcon from '../../../../../components/VpnIcon/VpnIcon'
import { toReadableData, toReadableDateTime } from '../../../../../helpers/data';
import { downloadCDRData } from '../../../../../helpers/exports';
import { useTranslation } from 'react-i18next';

const SimCdrs = ({ asset, iccid }) => {
  const [busy, setBusy] = useState('initial')
  const [cdrs, setCdrs] = useState(null)
  const [isShowing, toggle] = useModal();
  const [args, setArgs] = useState({iccid: asset.iccid, page: 1, limit: 10, q: ''})
  const {t} = useTranslation()

  const headers = [
    {name: t('simOverview.cdrs.headers.start')},
    {name: t('simOverview.cdrs.headers.end')},
    {name: t('simOverview.cdrs.headers.vpn')},
    {name: t('simOverview.cdrs.headers.ip')},
    {name: t('simOverview.cdrs.headers.usage')},
    {name: t('simOverview.cdrs.headers.mcc')},
    {name: t('simOverview.cdrs.headers.mnc')},
    {name: t('simOverview.cdrs.headers.network')}
  ]

  const refreshCDRs = () => {
    setBusy('refreshing')
    reportService.getCdrData(args).then( response => {
      if (response.ok) {
        setCdrs(response.data)
      }
      setBusy(null)
    })
  }

  useEffect( () => {
    reportService.getCdrData(args).then( response => {
      if (response.ok) {
        setBusy(null)
        setCdrs(response.data)
      }
    })
  }, [args])
  

  const getTableContents = () => {
    if (busy) {
      return <NoDataRow cols={headers.length} loading />
      //return <tr><td colSpan={headers.length}>loading</td></tr>

    } else if (cdrs && cdrs.results.length > 0) {
      return cdrs.results.map ( (cdr, i) => (
        <TableRow delay={i} key={i}>
          <td>{toReadableDateTime(cdr.data.startTime)}</td>
          <td>{toReadableDateTime(cdr.data.endTime)}</td>
          <td align='center'>
            <VpnIcon status={asset.vpn.ip === cdr.data.ipAddress} />
          </td>
          <td>{cdr.data.ipAddress}</td>
          <td>{toReadableData(cdr.dataUsed)}</td>
          <td>{cdr.data.servingNetwork.mcc}</td>
          <td>{cdr.data.servingNetwork.mnc}</td>
        </TableRow>
      ))
      
    } else {
      return  <NoDataRow cols={headers.length} text={t('simOverview.cdrs.noCdrsFound')} />
    }   
  }

  const handleTableUpdate = newArgs => {
    setArgs( {...args, ...newArgs} )
  }

  const handleExport = (e) => {
    setBusy('exporting')
    downloadCDRData({iccid: asset.iccid}).then(res => {
      setBusy(null)
    })
  }
  
  const handleRefresh = (e) => {
    refreshCDRs()
  }

  return (
    <>
      <Row align='center' spacer>
        <Col>
          <Heading type="section" noMargin> {asset.name} {t('misc.cdrs')}</Heading>
        </Col>
        <Col align='right'>
          <Button disabled={busy} loading={busy==='exporting'} variant="link" icon="download" className="text-uppercase" onClick={handleExport} >{t('simOverview.cdrs.export')}</Button>
          <Button disabled={busy} loading={busy==='refreshing'} variant="primary" className="text-uppercase" onClick={handleRefresh}>{t('simOverview.cdrs.refresh')}</Button>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Table headers={headers} sizing='small' pagination={cdrs ? cdrs.pagination : null} onChange={handleTableUpdate} >
            { getTableContents() }
          </Table>
          <Modal show={isShowing} onClose={toggle} />
        </Col>
      </Row>
    </>   
  )
}

export default SimCdrs