import React from 'react';
import RBCard from 'react-bootstrap/Card';
import styles from './AccountCard.module.scss';
import { Trans } from 'react-i18next';

const AccountCard = ({title, id, verified=false, type, onClick}) => {

  const handleClick = () => onClick(id, verified);

  return (
    <RBCard onClick={handleClick} className={styles.card}>
      <RBCard.Body>
        {title && (
          <RBCard.Title className={styles.title}>{title}</RBCard.Title>
        )}
        <p className={styles.accountid}>{id}</p>
        <p className={styles.accounttype}>{type}</p>
        {!verified && (
          <p className={styles.accountid}>
            <Trans i18nKey="auth.accountLocked" components={{ 
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              podLink: <a href="mailto:support@luner.io" /> 
            }} />
          </p>
        )}
      </RBCard.Body>
    </RBCard>
  )
}

export default AccountCard;