import React, {useEffect} from 'react'
import Modal from '../../components/Modal/Modal';
import Input from '../../components/Input/Input'
import useForm from '../../hooks/useForm'
import Button from '../../components/Button/Button'
import Heading from '../../components/Heading/Heading'
import userService from '../../services/userService'
import {useAuth} from '../../contexts/AuthContext'
import {toast} from 'react-toastify'
import {Row, Col} from '../../components/Grid/Grid'
import { useTranslation } from 'react-i18next';

const EditUserModal = ({isShowing, toggle, onClose}) => {
  const {activeUser, setActiveUser} = useAuth()
  const {t} = useTranslation()

  useEffect( () => {
    setValues({
      name: activeUser ? activeUser.name : '',
      email: activeUser ? activeUser.email : '',
      current: '',
      password: '',
      password_confirmation: ''
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUser])

  const validateRules = {
    name: 'required',
    email: 'required|email',
    current: 'string',
    password: 'string',
    password_confirmation: 'string|matches:password',
  }

  const handleUpdateAttempt = async () => {

    let passwordResponse = null

    const profileBody = {
      name: values.name,
      email: values.email
    }
    
    // only attempt password change if the users entered a new password
    if ( values.password ) {
      const passwordBody = {
        current: values.current,
        password: values.password,
        password_confirmation: values.password_confirmation
      }
      passwordResponse = await userService.updatePassword(passwordBody)
    }
    
    const profileResponse = await userService.update(profileBody)
    
    // if new password entered
    if ( values.password !== '' ) {
      if (profileResponse.ok && passwordResponse.ok) {
          onClose()
          toast.success('User and password updated!')
          setActiveUser(profileResponse.data)
      } else if (profileResponse.ok) {
        toast.warning('User updated, but password could not be updated')
        if (passwordResponse.data.message) {
          toast.update('ERR_400_FALLBACK_OVERRIDE', { render: passwordResponse.data.message })
        }
      } else {
        toast.warning('Password updated, error updating user')
        if (profileResponse.data.message) {
          toast.update('ERR_400_FALLBACK_OVERRIDE', { render: profileResponse.data.message })
        }
      }
    // else if just updating info
    } else {
      if (profileResponse.ok) {
        onClose()
        toast.success('User updated!')
        setActiveUser(profileResponse.data)
      } else {
        toast.warning('There was an error updating your profile')
      }
    }
  }

  const {handleChange, handleSubmit, values, errors, setValues, isSubmitting} = useForm(handleUpdateAttempt, validateRules);
 
  return (
    <Modal title={t('account.edit.title')} show={isShowing} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Row spacer="small">
          <Col xs="12">
            <Input name="name" label={t('misc.username')} placeholder={t('account.edit.usernamePlaceholder')} onChange={handleChange} value={values.name} error={errors.name} disabled={isSubmitting}/>
            <Input name="email" label={t('misc.email')} placeholder={t('account.edit.emailPlaceholder')} onChange={handleChange} value={values.email} error={errors.email} disabled={isSubmitting}/>
          </Col>
        </Row>
        <Row spacer="small">
          <Col xs="12">
            <Heading noMargin>{`${t('account.edit.passwordHeader')}:`}</Heading>
            <small className="text-muted">{t('account.edit.passwordSubheader')}</small>
          </Col>
        </Row>
        <Row spacer="small">
          <Col xs="12">
            <Input type="password" name="current" label={t('account.edit.passwordLabel')} placeholder={t('account.edit.passwordPlaceholder')} onChange={handleChange} value={values.current} error={errors.current} disabled={isSubmitting}/>
            <Input type="password" name="password" label={t('account.edit.newPasswordLabel')} placeholder={t('account.edit.newPasswordPlaceholder')} onChange={handleChange} value={values.password} error={errors.name} disabled={isSubmitting}/>
            <Input type="password" name="password_confirmation" label={t('account.edit.confirmPasswordLabel')} placeholder={t('account.edit.confirmPasswordPlaceholder')} onChange={handleChange} value={values.password_confirmation} error={errors.password_confirmation} disabled={isSubmitting}/>
          </Col>
        </Row>
        <Row>
          <Col align='right'>
            <Button type="submit" className="text-uppercase" loading={isSubmitting}>{t('account.edit.updateButton')}</Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default EditUserModal;