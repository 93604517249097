import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import PageHeader from '../../../components/PageHeader/PageHeader'
import Container from 'react-bootstrap/Container'
import Button from '../../../components/Button/Button'
import Tab from 'react-bootstrap/Tab'
import SimInformation from './tabs/SimInformation/SimInformation'
import SimCdrs from './tabs/SimCdrs/SimCdrs'
import assetService from '../../../services/assetService'
import SimControls from './tabs/SimControls/SimControls';
// import SimAnalytics from './tabs/SimAnalytics/SimAnalytics';
import Loader from '../../../components/Loader/Loader';
import Icon from '../../../components/Icon/Icon';
import useModal from '../../../hooks/useModal';
import SimNameModal from './modal/SimNameModal/SimNameModal'
import { useTranslation } from 'react-i18next';

const SimOverviewLayout = ({match, location, name}) => {
  const [asset, setAsset] = useState(null)
  const [isSimNameModalShowing, toggleSimNameModal] = useModal()
  const {t} = useTranslation()

  useEffect( () => {
    setAsset(null)
    assetService.single(match.params.iccid).then( response => {
      if (response.ok) {
        setAsset(response.data)
      }
    })
  }, [match.params.iccid])

  const tabs = [
    {name: t('simOverview.tabs.info'), target: 'sim-info'},
    {name: t('simOverview.tabs.cdrs'), target: 'sim-cdrs'},
    // {name: 'Analytics', target: 'analytics'},
  ]

  const activeTabs = [
    {name: t('simOverview.tabs.info'), target: 'sim-info'},
    {name: t('simOverview.tabs.cdrs'), target: 'sim-cdrs'},
    {name: t('simOverview.tabs.controls'), target: 'sim-controls'},
    // {name: 'Analytics', target: 'analytics'},
  ]

  const handleSimNameClose = data => {
    toggleSimNameModal()
    if (data) {
      setAsset( {
        ...asset,
        ...data
      })
    }
  }
  const handleAssetUpdate = newStatus => {
    setAsset( {
      ...asset,
      ...newStatus
    })
  }

  const getPageTitle = () => {
    if (asset) {
      return asset.name
    } else if (location.state && location.state.name) {
      return location.state.name
    } else {
      return <Loader />
    }
  }

  const crumbs = <><Link to="/connectivity/sims">{t('misc.sims')}</Link><span>/</span><span>{getPageTitle()}</span></>

  return (
    <>
      <Tab.Container mountOnEnter defaultActiveKey="sim-info">
        <PageHeader title={getPageTitle()} subtitle={<Button onClick={toggleSimNameModal} variant="a">{t('simOverview.info.editName')} <Icon type="go" iconStyle="link" /></Button>} tabs={asset ? ((asset.status !== 'inactive') ? activeTabs : tabs) : null} soft breadcrumbs={crumbs} />
        <Container>
          <Tab.Content>
            <Tab.Pane eventKey="sim-info">
              <SimInformation asset={asset} iccid={match.params.iccid} onAssetUpdate={handleAssetUpdate} />
            </Tab.Pane>
            <Tab.Pane eventKey="sim-cdrs">
              <SimCdrs asset={asset} iccid={match.params.iccid} />
            </Tab.Pane>
            <Tab.Pane eventKey="sim-controls">
              <SimControls asset={asset} onAssetUpdate={handleAssetUpdate} />
            </Tab.Pane>
            {/* <Tab.Pane eventKey="analytics">
              <SimAnalytics asset={asset} iccid={match.params.iccid}  />
            </Tab.Pane> */}
          </Tab.Content>
        </Container>

        <SimNameModal isShowing={isSimNameModalShowing} onClose={handleSimNameClose} asset={asset} />
      </Tab.Container>
    </>    
  )
}

export default SimOverviewLayout