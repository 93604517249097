import React from 'react';
import Modal from '../../../../components/Modal/Modal'
import {Row, Col} from '../../../../components/Grid/Grid'
import Heading from '../../../../components/Heading/Heading'
import Button from '../../../../components/Button/Button'
import { useTranslation } from 'react-i18next';

const PlanTypeModal = ({type, isShowing, onReturn, onClose}) => {
  const {t} = useTranslation()

  const content = {
    aggregate: {
      title: t('plans.types.agg.header'),
      content: t('plans.types.agg.body')
    },
    prepay: {
      title: t('plans.types.pre.header'),
      content: t('plans.types.pre.body')
    },
    postpay: {
      title: t('plans.types.post.header'),
      content: t('plans.types.post.body')
    }
  }
  return (
    <Modal title={t('plans.types.header')} show={isShowing} onClose={onClose}>
      <Row>
        <Col xs='12'>
          <Heading type="sub">{content[type] ? content[type].title : t('plans.types.yours')}</Heading>
          <div>
            <p>{content[type] ? content[type].content : t('plans.types.body')}</p>
          </div>
        </Col>
        <Col xs='12' align='right'>
          <Button onClick={onReturn}>{t('misc.close')}</Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default PlanTypeModal