import React from 'react';
import Container from 'react-bootstrap/Container'
import {Row, Col} from '../../components/Grid/Grid';
import Button from '../../components/Button/Button'
import Heading from '../../components/Heading/Heading'
import PageHeader from '../../components/PageHeader/PageHeader'
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

const OrderFailureLayout = () => {
  const {t} = useTranslation()

  return (
    <>
      <PageHeader title="Payment Failure" />
      <Container className="py-4">
        <Row>
          <Col xs="12">
            <Heading>{t('order.error.header')}</Heading>
            
            <p>
              <Trans i18nKey='order.error.body' components={{ 
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                helpLink: <a href="mailto:support@luner.io"/> 
              }} />
            </p>
            <Link to="/">
              <Button>{t('misc.returnHome')}</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </>    
  )
}

export default OrderFailureLayout