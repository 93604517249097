import React, {useEffect, useState} from 'react'
import Modal, {ModalSection} from '../../components/Modal/Modal';
import Input from '../../components/Input/Input'
import Icon from '../../components/Icon/Icon'
import Alert from 'react-bootstrap/Alert';
import useForm from '../../hooks/useForm'
import styles from './AddSimModal.module.scss'
import Button from '../../components/Button/Button'
import assetService from '../../services/assetService'
import { useTranslation } from 'react-i18next';

const AddSimModal = ({isShowing, toggle, onClose}) => {

  const [submitting, setSubmitting] = useState(false)
  const [addedSims, setAddedSims] = useState([])
  const [reqResponses, setReqResponses] = useState([])
  const {t} = useTranslation()

  const validateRules = {
    name: 'required',
    iccid: 'required|min:19|max:20',
  }

  const handleAddSim = () => {
    const currentSims = [ ...addedSims ];
    currentSims.push( values );
    setAddedSims(currentSims)

    resetValues({
      name: '',
      iccid: ''
    })
  }

  const { handleChange, handleSubmit, values, errors, resetValues, setDefaults, isSubmitting } = useForm(handleAddSim, validateRules);

  useEffect( () => {
    
    const defaults = {
      name: '',
      iccid: '',
    }
    setDefaults(defaults)
    setReqResponses([])
    setAddedSims([])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing])

  const handleConfirm = () => {
    setSubmitting(true);

    const promises = []
    
    addedSims.forEach( sim => {
      promises.push( assetService.provision(sim) )
    })
  
    Promise.allSettled(promises)
    .then( response => {
      setReqResponses(response)
      if ( response.filter(res => !res.value.ok).length === 0 ) {
        onClose(true)
      }
      setSubmitting(false);
    })
    .catch( err => {
      setReqResponses(err)
      setSubmitting(false);
    })
  }

  const handleRemove = e => {
    const currentSims = [ ...addedSims ];
    currentSims.splice(e.target.value, 1);
    setAddedSims(currentSims)
  }
  
  return (
    <Modal title={t('dashboard.activate.title')} show={isShowing} onClose={onClose}>
        
      <ModalSection className={styles.spacer} type="gray">
        <Input name="name" label={t('dashboard.activate.name')} placeholder={t('dashboard.activate.namePlaceholder')} onChange={handleChange} value={values.name} error={errors.name ?? null} />
        <Input type="number" name="iccid" label={t('dashboard.activate.iccid')} placeholder={t('dashboard.activate.iccidPlaceholder')} onChange={handleChange} value={values.iccid} error={errors.iccid ?? null} />
      </ModalSection>

      <div className={styles.spacer}>
        <Button fullWidth loading={isSubmitting} onClick={handleSubmit}>{ addedSims.length > 0 ? t('dashboard.activate.addMore') : t('dashboard.activate.addSim') }</Button>
      </div>
        
      <div className={styles.addedSimsContainer}>

        {addedSims.map((sim, key) => (
          <div key={sim.iccid + key} className={styles.addSimBlock}>
            <div>
              <span className={styles.addSimTitle}>{ sim.name }</span>
              <span className={styles.addSimIccid}>{ sim.iccid }</span>
            </div>
            <Button variant="a" value={key} onClick={handleRemove}>
              <Icon type="remove" />
            </Button>
          </div>
        ))}
      </div>
      { reqResponses.filter(res => !res.value.ok).length > 0 && (
        <Alert variant='warning'>{ t('dashboard.activate.requestFailure') }</Alert>
      )}

        <div className="text-right">
          <Button variant="link" onClick={onClose}>{t('misc.return')}</Button>
          <Button onClick={handleConfirm} loading={submitting} disabled={addedSims.length === 0}>{t('misc.confirm')}</Button>
        </div>
      </Modal>
  ) 
}

export default AddSimModal;