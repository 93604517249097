import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container'
import {Row, Col} from '../../components/Grid/Grid'
import {Table, TableRow} from '../../components/Table/Table'
import apiService from '../../services/apiService'
import Card from '../../components/Card/Card'
import Button from '../../components/Button/Button'
import PageHeader from '../../components/PageHeader/PageHeader'
import Popover from '../../components/Popover/Popover'
import useModal from '../../hooks/useModal';
import CreateApiKeyModal from './modals/CreateApiKeyModal'
import Heading from '../../components/Heading/Heading';
import DeleteApiKeyButton from '../../components/DeleteApiKeyButton/DeleteApiKeyButton'
import {toast} from 'react-toastify'
import {toReadableDateTime} from '../../helpers/data'
import { downloadAPIData } from '../../helpers/exports';
import { useTranslation } from 'react-i18next';

const ApiLayout = () => {
  const [keys, setKeys] = useState(null)
  const [logs, setLogs] = useState([])
  const [args, setArgs] = useState({})
  const [busy, setBusy] = useState(null)
  const [isCreateKeyShowing, toggleCreateKeyModal] = useModal()
  const {t} = useTranslation()
  
  const keyHeaders = [
    {name: t('api.keys.headers.name')},
    {name: t('api.keys.headers.key')},
    {name: t('api.keys.headers.copy'), align: 'center'},
    {name: t('api.keys.headers.delete'), align: 'center'}
  ]

  const logHeaders = [
    {name: t('api.logs.headers.key')},
    {name: t('api.logs.headers.method')},
    {name: t('api.logs.headers.date')},
    {name: t('api.logs.headers.status')},
    {name: t('api.logs.headers.endpoint')}
  ]

  useEffect( () => {
    apiService.logs(args).then( response => {
      if (response.ok) { 
        setLogs(response.data)
      } else {
        toast.error('Error getting API Logs')
        setLogs([])
      }
    })
  },[args])

  useEffect( () => {
    apiService.keys().then( response => {
      if (response.ok) {
        setKeys(response.data)
      } else {
        toast.error('Error getting API Key data.')
        setKeys([])
      }
    })
  }, [])

  const handleApiKeyCopy = e => {
    const temp = document.createElement("input");
    document.body.appendChild(temp);
    temp.setAttribute('value', e.target.value);
    temp.select();
    document.execCommand("copy");
    document.body.removeChild(temp);
  }

  const handleApiKeyCreate = e => {
    toggleCreateKeyModal()
    apiService.keys().then( response => {
      if (response.ok) {
        setKeys(response.data)
        toast.success('Key successfully created')
      } else {
        toast.error('Error creating key')
      }
    })
    .catch( err => {
      console.warn(err);
    })
  }

  const refreshKeys = () => {
    apiService.keys().then( response => {
      setKeys(response.data)
    })
  }
  
  const refreshLogs = () => {
    setBusy('refreshing')
    apiService.logs(args).then( response => {
      if (response.ok) { 
        setLogs(response.data)
      } else {
        toast.error('Error getting API Logs')
        setLogs([])
      }
      setBusy(null)
    })
  }

  const handleLogsUpdate = newArgs => {
    setArgs( {...args, ...newArgs} )
  }

  const handleExport = () => {
    setBusy('exporting')
    downloadAPIData({filename: 'Luner-API-logs.csv'}).then( res => {
      setBusy(null)
    })
  }

  return (
    <>
      <PageHeader title={t('api.pageTitle')} inset dark>
        <Button variant="primary" onClick={toggleCreateKeyModal}>{t('api.keys.new')}</Button>
      </PageHeader>
      <Container>
        <Row>
          <Col xs="12">
            <section>
              <Card title={t('api.keys.header')}>
                {/* TODO: no pagination on keys endpoint so we fake it - get it put on the endpoint */}
                <Table basic headers={keyHeaders} pagination={keys && {totalCount: keys.length}} sizing='small'>
                  {keys && keys.map ( (asset, i) => (
                    <TableRow key={asset.apiKey} delay={i}>
                      <td>{asset.name}</td>
                      <td>{asset.apiKey}</td>
                      <td align="center"> <Popover text={t('misc.copied')}><Button variant="a" icon='copy' value={asset.apiKey} onClick={handleApiKeyCopy}></Button></Popover> </td>
                      <td align="center"> <DeleteApiKeyButton apiKey={asset.apiKey} onSuccess={refreshKeys}></DeleteApiKeyButton> </td>
                    </TableRow>
                  ))}
                </Table>
              </Card>
            </section>
            <section>
              <Row align="center" spacer="small">
                <Col>
                  <Heading noMargin>{t('api.logs.header')}</Heading>
                </Col>
                <Col align='right'>
                  <Button disabled={busy} loading={busy==='exporting'} variant="link" icon="download" onClick={handleExport} >{t('api.logs.export')}</Button>
                  <Button disabled={busy} loading={busy==='refreshing'} variant="primary" onClick={refreshLogs}>{t('api.logs.refresh')}</Button>
                </Col>
            </Row>
              <Table headers={logHeaders} pagination={logs.pagination || null} onChange={handleLogsUpdate} sizing='small'>
                {logs.results && logs.results.map ( (asset, i) => (
                  <TableRow key={asset.dateTime+asset.apiKey} delay={i}>
                    <td>{asset.apiKey}</td>
                    <td>{asset.method}</td>
                    <td>{toReadableDateTime(asset.dateTime)}</td>
                    <td><span className={(asset.statusCode > 199 && asset.statusCode < 300) ? "text-success" : "text-danger"}>{asset.statusCode}</span></td>
                    <td>{asset.endpoint}</td>
                  </TableRow>
                ))}
              </Table>
            </section>
            
            <CreateApiKeyModal isShowing={isCreateKeyShowing} onClose={toggleCreateKeyModal} onSuccess={handleApiKeyCreate} />
          </Col>
        </Row>
      </Container>   
    </>
  )
}

export default ApiLayout