import React from 'react'
import styles from './ProductHeader.module.scss'
import Container from 'react-bootstrap/Container'
import Button from '../Button/Button'
import Loader from '../Loader/Loader'
import {toCurrency} from '../../helpers/currency'
import {useTranslation} from 'react-i18next'
import classnames from 'classnames'
import QtyInput from '../QtyInput/QtyInput'
import { useAuth } from '../../contexts/AuthContext'

const ProductHeader = ({product={}, loading=false, disabled=false, qty, onChange, onReset}) => {
  const {t} = useTranslation()
  const {activeAccount} = useAuth()

  return (
    <div className={ classnames(styles.main, (product && product.background ? styles.withBackground : '')) }>
      {product && product.background ? <div className={styles.background} style={{ 'backgroundImage': `linear-gradient(to right, white 1%, transparent 40%), url('${product.background}')`,  }}></div> : ''}
      <Container className="position-relative">
        <div className={styles.content}>
          <h2 className={styles.title}>{product && product.title ? product.title : <Loader type="text" />}</h2>
          <span className={styles.subtitle}>{activeAccount && activeAccount.billing.currency && product && product.price ? `${toCurrency(product.price[activeAccount.billing.currency], activeAccount.billing.currency)} ${t('order.product.perSIM')}` : <Loader width={20} type="text" />}</span>
          {product && product.excerpt && (
            <div className={styles.description}>
              {product.excerpt}
            </div>
          )}
          <div className="pt-3 d-flex align-items-center">
            <div className="d-inline mr-2">
              <QtyInput inline value={qty} min={1} disabled={disabled || loading} onChange={onChange} />
            </div>
            {disabled && (
              <Button onClick={onReset}>{ t('store.updateCart') }</Button>
            )}
          </div>

        </div>
        {product && product.image && (
          <div className={styles.imgWrap}>
            <img className={styles.image} src={product.image} alt={product.title} />
          </div>
        )}
      </Container>
    </div>
  )
}

export default ProductHeader
