import React from 'react'
import RBRow from 'react-bootstrap/Row'
import RBCol from 'react-bootstrap/Col'
import styles from './Grid.module.scss'

export const Row = props => {
  const generateClasses = () => {
    const classnames = []

    if (props.className) {
      classnames.push(props.className)
    }
    if (props.spacer) {
      switch (props.spacer) {
        case 'large':
          classnames.push(styles.spacerLarge)
          break
        case 'small':
          classnames.push(styles.spacerSmall)
          break
          default:
          classnames.push(styles.spacer)
          break
      }
    }
    if (props.align) {
      switch (props.align) {
        case 'center':
          classnames.push('align-items-center')
          break
        case 'bottom':
          classnames.push('align-items-end')
          break
        case 'top':
          classnames.push('align-items-start')
          break
        default:
          classnames.push(styles.spacer)
          break
      }
    }

    return classnames.join(' ')
  }

  return <RBRow className={generateClasses()} noGutters={props.noGutters || false} >{props.children}</RBRow>
}

export const Col = props => {

  const generateClasses = () => {
    const classnames = []

    if (props.className) {
      classnames.push(props.className)
    }

    if (props.align) {
    //  classnames.push('d-flex')
      switch (props.align) {
        case 'left':
          classnames.push('justify-content-end')
          break
        case 'center':
          classnames.push('justify-content-center')
          break
        case 'right':
            classnames.push('d-flex justify-content-end')
          break
        default:
          break
      }
    }

    return classnames.join(' ')
  }

  return <RBCol className={generateClasses()} { ...props}>{props.children}</RBCol>
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  Row,
  Col
}