import React, {useState} from 'react'
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Button/Button'
import userService from '../../services/userService';
import { Row, Col } from '../../components/Grid/Grid';
import { useTranslation } from 'react-i18next';

const ConfirmUserDeleteModal = ({isShowing, toggle, onClose, user}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {t} = useTranslation()

  const handleConfirm = () => {  
    setIsSubmitting(true)  
    return userService.removeUser(user.id)
    .then( response => {
      if (response.ok) {
        onClose(true);
      }
      setIsSubmitting(false)  
    })
  }

  return (
    <Modal title={t('settings.users.remove.header')} show={isShowing} onClose={onClose}>
      <Row>
        <Col xs="12">
          <p>{t('settings.users.remove.body')}</p>
        </Col>
        <Col xs="12" align="right">
          <Button disabled={isSubmitting} variant="secondary" onClick={onClose}>{t('misc.cancel')}</Button>
          <Button loading={isSubmitting} onClick={handleConfirm}>{t('misc.confirm')}</Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default ConfirmUserDeleteModal;