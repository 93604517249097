import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import AccountCard from '../../components/AccountCard/AccountCard';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../../components/Button/Button'
import Heading from '../../components/Heading/Heading'
import useModal from '../../hooks/useModal';
import {useAuth} from '../../contexts/AuthContext';
import AuthService from '../../services/authService';
import {useHistory} from 'react-router-dom'
import Loader from '../../components/Loader/Loader'
import {queryParam} from '../../helpers/url'
import {motion, AnimatePresence} from 'framer-motion'
import { useTranslation } from 'react-i18next';
import CreateAccountModal from '../../containers/CreateAccountModal/CreateAccountModal';
import { toast } from 'react-toastify';

const SelectAccountLayout = () => {

  const [selected, setSelected] = useState(null)
  const [accounts, setAccounts] = useState(null)
  const [isShowing, toggleModal] = useModal()
  const history = useHistory()
  const location = useLocation()
  const {t} = useTranslation()

  useEffect(() => {
    setIsInDashboard(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAccountError = e => {
    setSelected(null)
  }

  const handleAccountClick = (accountId, verified) => {
    
    if (!verified) {
      toast(t('toasts.verifyAccount'))
      return;
    }
    
    if (!accountId) return
    setSelected(accountId)
    accountLogin(accountId).then(res => {

      if (location.search) {
        const redirect = queryParam(location.search, 'redirect');
        if (redirect !== null) {
          switch (redirect) {
            case 'cart':
              window.location.href = process.env.REACT_APP_WEB_CART_URL
              break;
            case 'web':
              window.location.href = process.env.REACT_APP_WEBSITE_URL
              break;
            case 'marketplace':
              window.location.href = process.env.REACT_APP_MARKETPLACE_URL
              break;
            default:
              break;
          }
        } else {
          setActiveAccount(null)
          history.push('/')
        }
      } else {
        setActiveAccount(null)
        history.push('/')
      }
      
    }).catch(handleAccountError)
  }

  useEffect(() => {
    setAccounts(null);
    AuthService.getAuthAccounts().then(response => {
      if (response.ok) {
        setAccounts(response.data)
      }
    })
  }, [])

  const { accountLogin, setActiveAccount, setIsInDashboard } = useAuth();

  const accountType = type => {
    switch (type) {
      case 'prepay':
        return t('misc.prepay')
      case 'postpay':
        return t('misc.postpay')
      case 'single_bundle':
        return t('misc.singleBundle')
      case 'aggregate':
        return t('misc.aggregate')
      default:
        return null
    }
  }

  const animationConfig = {
    list: {
      transition: {
        staggerChildren: 0.3,
      }
    },
    hiddenItem: {
      opacity: 0.1,
      y: -10,
    },
    item: {
      opacity: 1,
      y: 0,
    },
    notSelected: {
      height: 0,
      opacity: 0,
    }
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={7} className="text-center">
          <Heading>{t('auth.selectAccount')}:</Heading>
        </Col>
        <Col xs={7}>
          {accounts ? (
            <>
            <motion.div variants={animationConfig} animate={'list'}>
              <AnimatePresence>
                {accounts.map( acc => {
                  if (!selected || selected === acc.accountId) {
                    return (
                      <motion.div key={acc.accountId} variants={animationConfig} animate='item' exit="notSelected" initial={'hiddenItem'}>
                        <AccountCard onClick={handleAccountClick} verified={acc.kycVerified} title={acc.name} id={acc.accountId} type={accountType(acc.billing.dataPackageType)} />
                      </motion.div>
                    ) 
                  } else {
                    return null
                  }
                })}
              </AnimatePresence>
            </motion.div>
            {selected && (
              <motion.div variants={animationConfig} animate='item' initial={'hiddenItem'}>
                <p className='mb-3 text-center d-flex justify-content-center align-items-center'><Loader type="spinner" size="sm" inline/> <span className="ml-1">{t('auth.loggingIn')}</span></p>
              </motion.div>
            )}
            <Button disabled={selected} icon="add-fill" fullWidth onClick={toggleModal}>{ t('auth.createAccount') }</Button>
            </>
          ) : (
            <Row>
              <Col align="center">
                <Loader />
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <CreateAccountModal isShowing={isShowing} onClose={toggleModal} />
    </Container>
  )
}

export default SelectAccountLayout;