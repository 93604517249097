import React, {useState} from 'react'
import assetService from '../../services/assetService'
import styles from './SearchBar.module.scss'
import { useEffect } from 'react'
import {motion, AnimatePresence} from 'framer-motion'
import {useHistory} from 'react-router-dom'
import Icon from '../Icon/Icon'
import {ReactComponent as ActiveIcon} from '../../assets/icons/greenRadar.svg'
import {ReactComponent as InactiveIcon} from '../../assets/icons/redRadar.svg'
import {ReactComponent as UnknownIcon} from '../../assets/icons/unknownRadar.svg'
import useDebounce from '../../hooks/useDebounce'
import { useTranslation } from 'react-i18next'

const SearchBar = () => {
  const [attempted, setAttempted] = useState(false)
  const [term, setTerm] = useState('')
  const debouncedSearchTerm = useDebounce(term, 300);
  const [matches, setMatches] = useState([])
  const handleSearch = e => setTerm(e.target.value)

  const history = useHistory()
  const {t} = useTranslation()

  useEffect( () => {
    let isSubscribed = true
    if ( debouncedSearchTerm !== '') {
      assetService.assets({q: debouncedSearchTerm, limit: 4}).then( response => {
        if (response.ok) {
          isSubscribed && setMatches(response.data.assets)
          isSubscribed && setAttempted(true)
        } else {
          isSubscribed && setMatches([])
          isSubscribed && setAttempted(false)
        }
      })
    } else {
      setMatches([])
    }
    
    return () => isSubscribed = false
  }, [debouncedSearchTerm])
  
  useEffect( () => {
    if (attempted) {
      setTimeout( () => {
        setAttempted(false)
      }, 2000)
    }
  }, [attempted])

  const anims = {
    hidden: {y: -20, opacity: 0},
    show: {y: 0, opacity: 1, transition: {duration: .1}},
    hide: {y:-5, height:0, opacity: 0, transition: {duration: .1}},
  }

  const handleSimNavigate = tgt => {
    setTerm('');

    history.push({
      pathname: `/connectivity/sims/${tgt.iccid}`,
      state: {name: tgt.name}
    })
  }

  const getIcon = status => {
    switch (status) {
      case 'active':
        return <ActiveIcon />
      case 'inactive':
        return <InactiveIcon />
      default:
        return <UnknownIcon />
    }
  }

  return(
    <div className={styles.wrap}>
      <Icon type="search" iconStyles="light" />
      <input className={styles.searchInput} placeholder={t('header.search.placeholder')} onChange={handleSearch} value={term} />
      <div className={styles.results}>
        <AnimatePresence exitBeforeEnter>
          { (matches.length > 0) ? (
            <motion.div key='matches' variants={anims} initial='hidden' animate='show' exit='hide'>
              {matches.map ( match => (
                <div className={styles.result} key={match.iccid} onClick={() => handleSimNavigate(match)}>
                  <div className={styles.status}>
                    { getIcon(match.status) }
                  </div>
                  <div className={styles.info}>
                    <p className={styles.name}>{match.name}</p>
                    <p className={styles.identifier}>{match.iccid}</p>
                  </div>
                </div>
              ))}
            </motion.div>
        )
        : attempted && (
          <motion.div className={styles.result} key='no-results' variants={anims} initial='hidden' animate='show' exit='hide'>
              <div>
                <p className={styles.name}>{t('header.search.noResults')}</p>
              </div>
            </motion.div>
        )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default SearchBar