import React from 'react'
import Button from '../Button/Button'
import useModal from '../../hooks/useModal'
import PayInvoiceModal from '../../containers/PayInvoiceModal/PayInvoiceModal'
import { useTranslation } from 'react-i18next'

const PayInvoiceButton = ({invoice, children}) => {

  const [isShowing, toggle] = useModal()
  const {t} = useTranslation()
  
  return (
    <>
      <Button size="sm" onClick={toggle}>{t('invoices.list.pay')}</Button> 
      <PayInvoiceModal isShowing={isShowing} onClose={toggle} invoice={invoice} />
    </>
  )

}

export default PayInvoiceButton 