import cookieService from "./cookieService"
import {toast} from 'react-toastify'
import { logout } from "./authService"
import history from '../helpers/history'

import i18nService from './i18nService'

const generateToasts = res => {
  const {status} = res

  // Toast ID 'ERR_XXX_FALLBACK_OVERRIDE' - this allows us to update/override these default toasts elsewhere
  switch (status) {
    case 500: //
      toast.error(i18nService.t('toasts.serverError'), {
        toastId: 'ERR_500_FALLBACK_OVERRIDE'
      })
      return true
    case 400:
      toast.error(res.message || i18nService.t('toasts.notFoundError'), {
        toastId: 'ERR_400_FALLBACK_OVERRIDE'
      })
      return true
    case 429:
      toast.error(i18nService.t('toasts.tooManyRequestsError'), {
        toastId: 'ERR_429_FALLBACK_OVERRIDE'
      })
      return true
    case 503: // POD error - grab 'the request service is unavailable
      toast.error(i18nService.t('toasts.requestServiceError'), {
        toastId: 'ERR_503_FALLBACK_OVERRIDE'
      })
      return true
    default:
      return false
  }
}

export const request = async (url, args) => {
  const defaultArgs = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'x-api-key': cookieService.getCookie('LunerAPIToken') || cookieService.getCookie('LunerTempToken'),
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  const requestArgs = { ...defaultArgs, ...args}
  let response = await fetch( process.env.REACT_APP_API_BASE_URL.concat(url), requestArgs);
  
  if (response.status === 401 && (cookieService.getCookie('LunerAPIToken') || cookieService.getCookie('LunerTempToken'))) {
    cookieService.removeCookie('LunerTempToken')
    cookieService.removeCookie('LunerAPIToken')
    await logout()
    history.push(`/login`)
    
    toast.error(i18nService.t('toasts.unauthenticatedError'), {
      toastId: 'UNAUTHENTICATED'
    })
  }
  
  if (response.status < 200 || response.status > 299) {
    generateToasts(response)
  }

  let responseData = await response.text()
  responseData = responseData.length ? JSON.parse(responseData) : {}
  return { status: response.status, ok: response.ok, data: responseData}
}
  
export const generateParameters = (params) => {
  const reducer = (accumulator, currentValue) => accumulator.concat(`&${currentValue[0]}=${currentValue[1]}`);
  if (params) {
    let paramString = Object.entries(params).reduce( reducer, '' );
    return paramString.replace(paramString.charAt(0), '?')
  } else {
    return ''
  }
}