import { request } from "./requestService"

export const sendSMS = (iccid, body) => {
  const args = {
    method: 'POST',
    body: JSON.stringify(body)
  }
  return request(`/assets/${iccid}/sms`, args)
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  sendSMS
}