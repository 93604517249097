import React from 'react'
import {ReactComponent as ActiveIcon} from './icons/activeIcon.svg'
import {ReactComponent as InactiveIcon} from './icons/inactiveIcon.svg'
import {ReactComponent as UnknownIcon} from './icons/unknownIcon.svg'

export const VpnIcon = ({status}) => {
  
  switch (status) {
    case true: 
      return <ActiveIcon />
    case false:
      return <InactiveIcon />
    default:
      return <UnknownIcon />
  }
}

export default VpnIcon