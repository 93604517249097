import React from 'react'
import useModal from '../../hooks/useModal'
import LanguageSelectModal from './modals/LanguageSelectModal/LanguageSelectModal'
import styles from './LanguageSelect.module.scss'
import { useAuth } from '../../contexts/AuthContext'
import { useTranslation } from 'react-i18next'

const LanguageSelect = () => {
  const [isShowing, toggle] = useModal()
  const {userLanguage, setActiveUser} = useAuth()
  const {t} = useTranslation()

  const handleClick = e => {
    e.preventDefault()
    toggle()
  }
  
  return (
    <>
      <button 
        className={`${styles.button} ${styles[userLanguage]}`} 
        onClick={handleClick}
      >
        {t('footer.language.buttonText')}
      </button>
      <LanguageSelectModal isShowing={isShowing} onClose={toggle} language={userLanguage} setUser={setActiveUser} />
    </>
  )

}

export default LanguageSelect 