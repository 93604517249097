import React from 'react';
import Container from 'react-bootstrap/Container'
import {Link} from 'react-router-dom'
import PageHeader from '../../components/PageHeader/PageHeader'
import {Row, Col} from '../../components/Grid/Grid'
import InvoiceTable from '../../containers/InvoiceTable/InvoiceTable'
import Heading from '../../components/Heading/Heading';
import Button from '../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import AlertModals from '../../containers/AlertModals/AlertModals'

const InvoicesLayout = () => {
  const {t} = useTranslation()

  return (
    <>
      <PageHeader title={t('invoices.pageTitle')}>
        <Link to="/settings/billing">
          <Button variant="light">{t('invoices.editBilling')}</Button>
        </Link>
      </PageHeader>
      <Container>
        <Row align="center" spacer="small">
          <Col xs="12">
            <Heading section noMargin>{t('invoices.list.header')}</Heading>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <InvoiceTable />
          </Col>
        </Row>
      </Container>

      <AlertModals accepted={['status']} />
    </>
  )
}

export default InvoicesLayout