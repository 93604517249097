import React, {useState} from 'react';
import {Link, Redirect} from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import {Row, Col} from '../../components/Grid/Grid';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import useForm from '../../hooks/useForm';
import {motion, AnimatePresence} from 'framer-motion'
import userService from '../../services/userService';
import { useTranslation } from 'react-i18next';

const NewPasswordLayout = ({match, location}) => {
  const [complete, setComplete] = useState(false)
  const [alert, setAlert] = useState(null)
  const {t} = useTranslation()

  const anims = {
    initial: {y: -20, opacity: 0},
    show: {y: 0, opacity: 1},
    slowershow: {y: 0, opacity: 1, transition: {duration: .6}},
    hide: {y: 20, opacity: 0},
  }

  const validateRules = {
    password: 'required|string',
    password_confirmation: 'required|string|matches:password',
  }

  const handleResetAttempt = () => {
    const body = {password: values.password, password_confirmation: values.password_confirmation}
    return userService.resetPassword(body, match.params.token).then( response => {
      if (response.ok) {
        setComplete(true);
      } else {
        setAlert(t('toasts.resetPasswordError'))
      }
    }).catch( err => {
      setAlert(t('toasts.resetPasswordError'))
    })
  }

  const { handleChange, handleSubmit, values, errors, isSubmitting } = useForm(handleResetAttempt, validateRules)

  return ( 
    <Container>
      <Row className="d-flex justify-content-center">
        <Col xs="6">
          <AnimatePresence exitBeforeEnter>
              {match.params.token ? (
                <>
                { !complete ? (
                  <motion.div variants={anims} key='incomplete' initial='initial' animate='show' exit='hide'>
                    <p className="text-center">{t('auth.passwordReset.header')}</p>
                    <Form onSubmit={handleSubmit}>
                      <Input type="password" name="password" label={t('auth.passwordReset.inputLabel')} onChange={handleChange} value={values.password} error={errors.password} autocomplete='new-password' />
                      <Input type="password" name="password_confirmation" label={t('auth.passwordReset.confirmLabel')} onChange={handleChange} value={values.password_confirmation} error={errors.password_confirmation} autocomplete='new-password' />
                      <Form.Row className="text-right">
                        <Col> 
                          <Button loading={isSubmitting} type="submit" variant="primary">{t('auth.passwordReset.confirmBtn')}</Button>
                        </Col>
                      </Form.Row>
                      { alert && (
                        <Alert className="mt-3 mb-0" variant={'warning'}>{alert}</Alert>
                      )}
                    </Form>
                  </motion.div>
                ) : (
                  <motion.div variants={anims} key='complete' initial='initial' animate='slowershow' exit='hide'>
                    <h3>{t('auth.passwordReset.success.header')}</h3>
                    <p>{t('auth.passwordReset.success.body')}</p>
                    <Link to="/login"><Button>{t('auth.loginAccount')}</Button></Link>
                  </motion.div>
                )}
                </>
              ) : (
                <Redirect to="/login" />
              )}
            
          </AnimatePresence>   
        </Col>
      </Row>
    </Container>
  )
}

export default NewPasswordLayout