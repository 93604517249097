import React from 'react';
import Form from 'react-bootstrap/Form';
import styles from './Radio.module.scss';
import classnames from 'classnames';

const Radio = ({name, label, value, inline=false, onChange, selected}) => {
  return (
    <label className={ classnames( [styles.wrap, (inline && styles.inline)] ) }>
      <Form.Check className={styles.check} checked={selected === value} onChange={onChange} type="radio" name={name} value={value} />
      <span>{label}</span>
    </label>
  )
}

export default Radio