import React from 'react'
import {Tabs as RBTabs } from 'react-bootstrap'
import classnames from 'classnames'
import styles from './Tabs.module.scss'

export const Tabs = props => {
  return (
    <RBTabs {...props} className={classnames(props.className, styles.tabs)}>
      {props.children}
    </RBTabs>
  )
}

export const Tab = ({title, className=null}) => {
  return (
        <h1>dejwkd</h1>
  )
}

export const TabContent = ({children}) => {
  return (
    <div className={styles.tabContent}>
      {children}
    </div>
    )
}