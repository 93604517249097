import React from 'react'
import {Link} from 'react-router-dom'
import Button from '../../../components/Button/Button'

const RejectScreen = () => {
  return (
    <div>
      <h1>Invite Rejected</h1>
      <p>You have rejected the invite to the account. To be invited again you will have to contact the account owner.</p>
    
      <a href='mailto:support@luner.io'>Contact us</a>

      <Link to="/login">
        <Button>Login to your account</Button>
      </Link>
    </div>
  )
} 

export default RejectScreen