import React from 'react';
import RBCard from 'react-bootstrap/Card';
import styles from './Card.module.scss'

const Card = ({className=null, title=null, subtitle=null, link=false, section=false, productCard=null, fullHeight=false, slim=false, onClick=null, children}) => {

  const generateClasses = () => {
    const classes = [styles.card]
    if (className) {
      classes.push(className)
    }
    if (section) {
      classes.push(styles.sectionCard)
    }
    if (fullHeight) {
      classes.push(styles.fullHeight)
    }
    if (onClick) {
      classes.push(styles.clickable)
    }
    if (productCard === 'noQueue') {
      classes.push(styles.noQueue)
    }
    if (productCard === 'active') {
      classes.push(styles.activeProduct)
    }
    if (productCard === 'upgrade') {
      classes.push(styles.upgradeProduct)
    }
    if (productCard === 'cancelled') {
      classes.push(styles.cancelledProduct)
    }
    return classes.join(' ')
  }

  return (
    <RBCard className={generateClasses()} onClick={onClick}>
      <RBCard.Body className={`${styles.cardBody} ${slim && styles.slim}`}>
        {(title || link) && (
          <div className={`${styles.header} ${(title && subtitle) && styles.withSub}`}>
            { title && (
              <RBCard.Title className={slim ? styles.slimTitle : styles.title}>{title}</RBCard.Title>
            )}
            { link && (
              <div className={styles.link}>
                { link }
              </div>
            )}
            { subtitle && (
              <span className={styles.subtitle}>{subtitle}</span>
            )}
          </div>
        )}
          {children}    
      </RBCard.Body>
    </RBCard>
  )
}

export const CardBreak = () => {
  return (
    <div className={styles.cardBreak} />
  )
}

export default Card;