import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'

export const AuthRoute = ({ component: Component, unauthed=false, accountType=null, ...rest }) => {
  // get login state
  const { activeAccount, loggedIn, setIsInDashboard } = useAuth()

  // TODO: duplicate code here, probably a nicer way to write this
  // if there's an account type set
  if (accountType) {
    if (!unauthed) {    
      // got to route if logged in, if not redirect to home (login screen when logged out)
      return (
        <Route {...rest} render={(props) => (
          loggedIn() && (accountType && (activeAccount && activeAccount.billing.dataPackageType === accountType))
          ? <Component {...rest} {...props} />
          : <Redirect to='/login' />
          )} />
          )
          
    // else, we're redirecting if the user IS logged in
    } else {
      setIsInDashboard(true)
      // got to route if logged in, if not redirect to home (login screen when logged out)
      return (
        <Route {...rest} render={(props) => (
          !loggedIn() && (accountType && (activeAccount && activeAccount.billing.dataPackageType === accountType))
            ? <Component {...rest} {...props} />
            : <Redirect to='/' />
        )} />
      )
    }
  }



  // if this is to ensure they're authed
  if (!unauthed) {    
    // got to route if logged in, if not redirect to home (login screen when logged out)
    return (
      <Route {...rest} render={(props) => (
        loggedIn()
        ? <Component {...rest} {...props} />
        : <Redirect to='/login' />
        )} />
        )
  // else, we're redirecting if the user IS logged in
  } else {
    setIsInDashboard(true)
    // got to route if logged in, if not redirect to home (login screen when logged out)
    return (
      <Route {...rest} render={(props) => (
        !loggedIn()
          ? <Component {...rest} {...props} />
          : <Redirect to='/' />
      )} />
    )
  }
}