import React, {useState, useEffect} from 'react';
import {Row, Col} from '../Grid/Grid'
import {Alert} from 'react-bootstrap'
import Button from '../Button/Button'
import Modal from '../Modal/Modal'
import Card from '../Card/Card'
import useModal from '../../hooks/useModal';
import Input from '../Input/Input'
import assetService from '../../services/assetService'
import productService from '../../services/productService'
import useForm from '../../hooks/useForm'
import {toBytes} from '../../helpers/data'
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/AuthContext';
import { Trans, useTranslation } from 'react-i18next';
import { toCurrency } from '../../helpers/currency';
import AddSimDataModal from '../../containers/AddSimDataModal/AddSimDataModal';

const InactiveSimCard = ({iccid, name, onActivate}) => {
  const [isShowing, toggle] = useModal()
  const [products, setProducts] = useState(null)
  const {t} = useTranslation()

  const validateRules = {
    dataLimit: 'required',
    productId: 'required'
  }

  useEffect( () => {
    setDefaults( {
      name: name,
      status: 'active',
      dataLimitMultiplier: 'MB'
    })
    productService.products().then( response => {
      setProducts(response.data)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleActivateSim = () => {
    const data = toBytes(values.dataLimit, values.dataLimitMultiplier)  
    const args = { ...values }
    delete args.dataLimitMultiplier
    if (data) args.dataLimit = data
    
    return assetService.updateAsset(iccid, args)
    .then( response => {
      if (response.ok) {
        toggle()
        toast.success(t('toasts.activateSuccess'))
        onActivate(response.data)
      }
    })
    .catch( err => {
      toast.success(err.message || t('toasts.activateFalure'))
    })
  }

  const {handleChange, handleSubmit, values, errors, setDefaults, isSubmitting} = useForm(handleActivateSim, validateRules);
  const {activeAccount} = useAuth()

  return (
    <>
      <Card section title={t('connectivity.sims.inactive.header')}>

        <Row>
          <Col xs="12">
            <p>{activeAccount && activeAccount.billing.dataPackageType === 'single_bundle' ? t('connectivity.sims.inactive.descriptionSingleBundle') : t('connectivity.sims.inactive.description') }</p>
          </Col>
          <Col xs="12" align="left">
            {activeAccount && activeAccount.billing.dataPackageType === 'single_bundle' ? (
              <Button className='mb-2' onClick={toggle}>{t('connectivity.sims.inactive.addData')}</Button>
            ) : (
              <Button className='mb-2' onClick={toggle}>{t('connectivity.sims.inactive.activate')}</Button>
            )}
          </Col>
          {activeAccount && activeAccount.billing.dataPackageType !== 'single_bundle' && (
          <Col>
            <Alert className='mb-0' variant='warning'><small><Trans i18nKey="connectivity.sims.inactive.warning" components={{bold: <b/>}} /></small></Alert>
          </Col>
          )}
        </Row>
      </Card>

      {activeAccount && activeAccount.billing.dataPackageType !== 'single_bundle' ? (
      <Modal title={t('connectivity.sims.inactive.header')} show={isShowing} onClose={toggle}>
        {((activeAccount.billing.balance && activeAccount.billing.balance > 0) || activeAccount.billing.dataPackageType !== 'prepay') ? (
          <form onSubmit={handleSubmit}>
              <Row spacer="small">
                <Col xs="12">
                  <small>{t('connectivity.sims.inactive.selectPlan')}</small>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Input type="select" name="productId" label={t('connectivity.sims.inactive.selectPlanHeader')} placeholder={t('connectivity.sims.inactive.selectPlaceholder')} onChange={handleChange} value={values.productId} error={errors.productId}>
                    <option value>{t('connectivity.sims.inactive.selectPlaceholder')}</option>
                    { products && products.map ( prod => (
                      <option key={prod.id} value={prod.id}>{prod.name} {(prod.type==="prepay" && activeAccount) ? `(${toCurrency(prod.perMbCost, activeAccount.billing.currency)}/MB)` : ''}</option>
                    ))}
                  </Input>
                </Col>
              </Row>
              <Row spacer="small">
                <Col xs='7'>
                  <Input type="number" name="dataLimit" label={`${t('connectivity.sims.inactive.dataLimitLabel')}:`} onChange={handleChange} value={values.limit} error={errors.dataLimit} />
                </Col>
                <Col xs='5'>
                  <Input type="select" name="dataLimitMultiplier" label onChange={handleChange} value={values.dataLimitMultiplier} error={errors.dataLimitMultiplier}>
                    <option value='MB'>MB</option>
                    <option value='GB'>GB</option>
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col xs="12" align="right">
                  <Button loading={isSubmitting} className="text-uppercase" type="submit">{t('connectivity.sims.inactive.activate')}</Button>
                </Col>
              </Row>
            </form>
        ) : (
          <Alert variant='warning'><small><Trans i18nKey="connectivity.sims.inactive.warning" components={{bold: <b/>}} /></small></Alert>
        )}
      </Modal>
      ) : (
        <AddSimDataModal selected={[iccid]} isShowing={isShowing} onClose={toggle} />
      )}
    </>
  )
}

export default InactiveSimCard