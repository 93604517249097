import React, {useState, useEffect} from 'react';
import { Row, Col } from '../../../../components/Grid/Grid';
import Modal, {ModalSection} from '../../../../components/Modal/Modal'
import Button from '../../../../components/Button/Button'
import Input from '../../../../components/Input/Input'
import InfoBlock from '../../../../components/InfoBlock/InfoBlock';
import Loader from '../../../../components/Loader/Loader';
import productService from '../../../../services/productService'
import useForm from '../../../../hooks/useForm'
import {toReadableMbData} from '../../../../helpers/data'
import {toCurrency} from '../../../../helpers/currency'
import Heading from '../../../../components/Heading/Heading'
import StatusWindow from '../../../../components/StatusWindow/StatusWindow';
import { useTranslation } from 'react-i18next';

const CreatePlanModal = ({isShowing, onClose, onPlanChange, onHelperModal, plan, type}) => {
  const [zones, setZones] = useState([])
  const [dataPackages, setDataPackages] = useState([])
  const {t} = useTranslation()

  const validateRules = {
    zoneId: 'required',
    initialPoolSizeMb: 'required'
  }

  useEffect( () => {
    if (plan) {
      setDefaults({
        name: plan.name,
        zoneId: (plan.upgrade && plan.upgrade.queued) ? plan.upgrade.zone.id : plan.zone.id,
        initialPoolSizeMb: (plan.upgrade && plan.upgrade.queued) ? plan.upgrade.initialPoolSizeMb : plan.initialPoolSizeMb
      })
    }

    productService.getPricing().then( response => {
      if (response.ok) setZones(response.data.zones)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan])

  useEffect( () => {
    setDefaults({name: '', zoneid: '', initialPoolSizeMb: ''})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing])

  useEffect(() => {
    if (plan) {
      const selected = zones.find( zone => parseInt(zone.id) === parseInt(plan.zone.id))
      if (selected && selected.dataPackages) setDataPackages(selected.dataPackages)
    }
  }, [plan, zones])

  const handleSubmitPlan = () => {
    if (plan) {
      return productService.upgradeProduct(plan.id, values).then( response => {
        if (response.ok) {
          onPlanChange()
        }
      })
    } else {
      return productService.createProduct(values).then( response => {
        if (response.ok) {
          onClose(true)
        }
      })
    }
  }

  const handleZoneChange = e => {
    const selected = zones.find( zone => parseInt(zone.id) === parseInt(e.target.value))
    if (!selected || !selected.dataPackages) return []
    setDataPackages(selected.dataPackages)
    handleChange(e)
  }


  const getCost = () => {
    if (!dataPackages || !values.initialPoolSizeMb) return <Loader type="text" />
    const pkg = dataPackages.find(dataPackage => dataPackage.initialPoolSizeMb === parseFloat(values.initialPoolSizeMb) )
    if (!pkg) return <Loader type="text" />
    return <span>{toCurrency(pkg.poolCost, pkg.currency)}</span>
  }

  const getOveruseCost = (append=null) => {
    if (!dataPackages || !values.initialPoolSizeMb) return ''
    const pkg = dataPackages.find(dataPackage => dataPackage.initialPoolSizeMb === parseFloat(values.initialPoolSizeMb) )
    if (!pkg) return <Loader type="text" />
    return <span>{toCurrency(pkg.perMbPrice, pkg.currency)} per MB</span>
  }

  const {handleChange, handleSubmit, values, errors, setDefaults, isSubmitting} = useForm(handleSubmitPlan, validateRules);
  
  return (
    <Modal title={plan ? t('plans.upgrade.header') : t('plans.create.header')} show={isShowing} onClose={onClose}>
      <div>
        <p>{t('plans.create.body')}</p>
      </div>
      <Heading type="sub">{t('plans.create.labels.type')}: {type ? <span>{type} <Button variant="link" size="xs" onClick={onHelperModal}>{t('misc.whatsThis')}</Button></span> : <Loader/>}</Heading>

      <form onSubmit={handleSubmit}>
        <ModalSection type="gray">
          <Row spacer>
            <Col xs="12">
              <Input name="name" label={`${t('plans.create.labels.planName')}`} onChange={handleChange} value={values.name || ''} error={errors.name} disabled={isSubmitting} />
          
              <Input type="select" name="zoneId" label={t('plans.edit.zone.label')} onChange={handleZoneChange} value={values.zoneId  || ''} error={errors.zoneId} disabled={isSubmitting}>
                <option>{t('plans.edit.zone.optionPlaceholder')}</option>
                {zones.map( zone => <option key={zone.id} value={zone.id}>{zone.name}</option> )}
              </Input>

              { type ? (
                <>
                { type === 'postpay' ? (
                  <>
                  <Input type="select" name="initialPoolSizeMb" label={`${t('plans.edit.postpayPlanSize.label')}:`} placeholder={t('plans.edit.postpayPlanSize.placeholder')} onChange={handleChange} value={values.initialPoolSizeMb} error={errors.initialPoolSizeMb} disabled={isSubmitting}>
                    <option>{t('plans.edit.postpayPlanSize.dataPackageSize')}</option>
                    {dataPackages.map( dataPackage => <option key={dataPackage.initialPoolSizeMb} value={dataPackage.initialPoolSizeMb}>{toReadableMbData(dataPackage.initialPoolSizeMb)}</option> )}
                  </Input>
                  <InfoBlock inline label={t('plans.labels.monthlyCost')} value={getCost()} />
                  <InfoBlock inline label={t('plans.labels.overusageCost')} value={getOveruseCost()} />
                  <small>{t('plans.create.footer')}</small>
                  </>
                ) : (
                  <>
                  <Input className="mb-2" type="select" name="initialPoolSizeMb" label={t('plans.edit.aggPlanSize.label')} placeholder={t('plans.edit.aggPlanSize.placeholder')} onChange={handleChange} value={values.initialPoolSizeMb} error={errors.initialPoolSizeMb} disabled={isSubmitting}>
                    <option>{t('plans.edit.aggPlanSize.label')}</option>
                    {dataPackages.map( dataPackage => <option key={dataPackage.initialPoolSizeMb} value={dataPackage.initialPoolSizeMb}>{toReadableMbData(dataPackage.initialPoolSizeMb)}</option> )}
                  </Input>
                  <Heading>{t('plans.edit.costs.header')}</Heading>
                  <ModalSection type='xs'>
                    <InfoBlock inline fullWidth label={t('plans.labels.overusageCost')} value={getOveruseCost()} /> 
                  </ModalSection>
                  <div className="mb-2">
                    <InfoBlock inline fullWidth label={t('plans.labels.monthlyCostPerSim')} value={getCost()} /> 
                  </div>
                  <StatusWindow type="warning">
                    <Heading>{t('plans.edit.aggPlanSize.howDoTheyWork')}</Heading>
                    <p>{t('plans.edit.aggPlanSize.howDoTheyWorkBody')}</p>
                  </StatusWindow>
                  </>
                )}
                </>
              ) : (
                <Row spacer="small">
                  <Col xs='12' align='center'>
                    <Loader />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </ModalSection>
        <Row>
          <Col align="right" xs="12">
            <Button loading={isSubmitting} type='submit'>{plan ? t('plans.upgrade.header') : t('plans.create.header')}</Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default CreatePlanModal