import React, {useEffect} from 'react'
import Input from '../Input/Input'
import Radio from '../Radio/Radio'
import Button from '../Button/Button'
import useForm from '../../hooks/useForm'
import {Row, Col} from '../Grid/Grid'
import smsService from '../../services/smsService'
import styles from './SendAnSms.module.scss'
import {toast} from 'react-toastify'
import { useTranslation } from 'react-i18next'

const SendAnSms = ({iccid}) => {
  const {t} = useTranslation()

  useEffect( () => {
    setDefaults({
      dcs: '0'
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateRules = {
    message: 'required|string|max:160'
  }

  const handleSendSMS = () => {
    const data = {
      ...values,
      dcs: parseInt(values.dcs)
    }
    return smsService.sendSMS(iccid, data).then( response => {
        if (response.ok) {
          toast.success(t('toasts.smsSuccess'))
        } else {
          toast.error(t('toasts.smsFailure'))
        }
    })
  }

  const {handleChange, handleSubmit, values, errors, setDefaults, isSubmitting} = useForm(handleSendSMS, validateRules);

  return (
    <div>
      
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs="12">
            <label htmlFor='dcs'>{`${t('simOverview.controls.sms.typeLabel')}:`}</label>
            <Row spacer="small">
              <Col xs="12">
                <Radio name="dcs" label={t('simOverview.controls.sms.type.standard')} selected={values.dcs} onChange={handleChange} value='0' inline />
                <Radio name="dcs" label={t('simOverview.controls.sms.type.flash')} selected={values.dcs} onChange={handleChange} value='240' inline />
              </Col>
            </Row>
          </Col>
          <Col xs="12">
            <Input className={styles.smsArea} type="textarea" name="message" label={`${t('simOverview.controls.sms.messageLabel')}:`} placeholder={t('simOverview.controls.sms.messagePlaceholder')} onChange={handleChange} value={values.message} error={errors.message} disabled={isSubmitting}/>
          </Col>
          <Col xs="12" align="left">
            <Button type="submit" loading={isSubmitting}>{t('simOverview.controls.sms.send')}</Button>
          </Col>
        </Row>
      </form>
    </div>
  )
}

export default SendAnSms