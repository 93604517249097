import React from 'react'
import Loader from '../../components/Loader/Loader';
import {Link} from 'react-router-dom';
import Button from '../../components/Button/Button';
import { toCurrency } from '../../helpers/currency';
import styles from './ProductListCard.module.scss'
import {useTranslation} from 'react-i18next'
import { useAuth } from '../../contexts/AuthContext';

const ProductListCard = ({product}) => {
  const {t} = useTranslation()
  const {activeAccount} = useAuth()
  return (
    <div className={styles.card}>
      <div className={styles.header} style={{'backgroundImage': product && product.background ? `url('${ product.background }')` : null }}>
        {(product && product.image) && (
          <img className={styles.image} src={product.image} alt={product.title} />
        )}
      </div>
      <div className={styles.content}>
        <h3 className={styles.title}>{ product && product.title ? product.title : <Loader type="text" /> }</h3>
        <div className={styles.pricing}>
          <span className={styles.price}>{activeAccount && activeAccount.billing.currency && product && product.price ? `${toCurrency(product.price[activeAccount.billing.currency], activeAccount.billing.currency)} ${t('order.product.perSIM')}` : <Loader type="text"  width={30} />}</span>
        </div>
        <p className={styles.description}>{product && product.excerpt ? product.excerpt : (product ? (product.description.length > 120 ? product.description.substr(0, 118) + '...' : product.description) : <><Loader type="text" /><Loader type="text" /></>) }</p>

        <div className={styles.actions}>
          {product && product.id ? (
            <Link to={`/connectivity/order-sims/${product.id}`}>
              <Button className={styles.action}>Buy Now</Button>
            </Link>
          ) : (
            <Button disabled className={styles.action}>Buy Now</Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductListCard