import React from 'react';
import {Row, Col} from '../../../../components/Grid/Grid'
import Button from '../../../../components/Button/Button'
import useModal from '../../../../hooks/useModal'
import Card from '../../../../components/Card/Card'
import InfoBlock from '../../../../components/InfoBlock/InfoBlock'
import Loader from '../../../../components/Loader/Loader'
import BigText from '../../../../components/BigText/BigText';
import TopUpBalanceModal from '../../../../containers/TopUpBalanceModal/TopUpBalanceModal'
import EditBillingAddressModal from '../../../../containers/EditBillingAddressModal/EditBillingAddressModal'
import { useAuth } from '../../../../contexts/AuthContext';
import {toCurrency} from '../../../../helpers/currency'
import { Trans, useTranslation } from 'react-i18next';

const Billing = () => {
  const [isTopUpShowing, toggleTopUp] = useModal()
  const [isEditBillingShowing, toggleEditBilling] = useModal()
  const {activeAccount} = useAuth()
  const {t} = useTranslation()

  const getVatStatus = () => {
    if (!activeAccount) return <Loader type="text" width={35} />
    if (activeAccount.billing.vatNumber == null) return t('settings.billing.vat.notRegistered')
    if (activeAccount.billing.vatNumber !== null) return 'VAT Registered'
    
    if (activeAccount.billing.vatRate) return <Trans i18nKey="settings.billing.vat.vatApplied" defaults="{{rate}}% applied to products." values={{rate: activeAccount.billing.vatRate}} />
  }

  return (
    <>
      {activeAccount && activeAccount.billing.dataPackageType === 'prepay' && (
        <Card section title={t('dashboard.balanceHeader')}>
          <Row align="bottom">
            <Col xs="6">
              <BigText label={t('settings.billing.balance')} value={activeAccount ? toCurrency(activeAccount.billing.balance, activeAccount.billing.currency) : null} />
            </Col>
            <Col xs="6" align="right">
              <Button variant="primary" onClick={toggleTopUp}>+ {t('header.nav.submenu.topUpButton')}</Button>
            </Col>
          </Row>
        </Card>
      )}
      <Row>
        <Col>
          <Card title={t('settings.billing.billingInfo.header')} link={<Button onClick={toggleEditBilling} variant="a">{t('account.address.edit')}</Button>}>
          <InfoBlock label={`${t('account.address.billing.header')}:`}>
              {activeAccount ? (
                <>
                  {activeAccount.billing.billingAddress.name && <p>{activeAccount.billing.billingAddress.name}</p>}
                  {activeAccount.billing.billingAddress.line1 && <p>{activeAccount.billing.billingAddress.line1}</p>}
                  {activeAccount.billing.billingAddress.line2 && <p>{activeAccount.billing.billingAddress.line2}</p>}
                  {activeAccount.billing.billingAddress.city && <p>{activeAccount.billing.billingAddress.city}</p>}
                  {activeAccount.billing.billingAddress.stateRegion && <p>{activeAccount.billing.billingAddress.stateRegion}</p>}
                  {activeAccount.billing.billingAddress.country && <p>{activeAccount.billing.billingAddress.country}</p>}
                </>
              ) : (
                <>
                  <p><Loader type="text" width={60} /></p>
                  <p><Loader type="text" width={70} /></p>
                  <p><Loader type="text" width={50} /></p>
                  <p><Loader type="text" width={55} /></p>
                  <p><Loader type="text" width={45} /></p>
                  <p><Loader type="text" width={20} /></p>
                </> 
              )}
            </InfoBlock>
            <InfoBlock label={`${t('account.address.billing.contact')}:`} value={activeAccount ? activeAccount.billing.defaultEmail : <Loader type="text" width={50} />} />
          </Card>
        </Col>

        <Col>
          <Card title={t('settings.billing.vat.status')}>
            <p>
              <Trans 
                i18nKey="settings.billing.vat.description"
                components={
                  { 
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    emailLink: <a href="mailto:accounts@luner.io" />
                  }
                }
              />
            </p>              
            <InfoBlock label={`${t('settings.billing.vat.status')}:`} value={getVatStatus()} />
            <InfoBlock label={`${t('settings.billing.vat.number')}:`} value={activeAccount ? activeAccount.billing.vatNumber : <Loader type="text" width={40} />} />
          </Card>
        </Col>
      </Row>

      <EditBillingAddressModal isShowing={isEditBillingShowing} onClose={toggleEditBilling} />
      <TopUpBalanceModal isShowing={isTopUpShowing} onClose={toggleTopUp} />
    </>   
  )
}

export default Billing