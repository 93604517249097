import {unparse} from 'papaparse'
import apiService from '../services/apiService'
import reportService from '../services/reportService'
import i18nService from '../services/i18nService'
import {toReadableDateTime} from './data'

export const downloadCDRData = inputArgs => {
  return new Promise( async (resolve, reject) => {
    const args = { ...inputArgs, limit: 100}

    const response = await reportService.getCdrData(args)
    let pages
    let rawResult = []

    if (response.ok) {
      pages = response.data.pagination.totalPages
      rawResult.push(...response.data.results)
    }

    // if theres more than one page, we'll have to do multiple API calls and append the results
    if (pages > 1) {
      for (let i=2; i <= pages; ++i) {
        args.page = i
        let res = await reportService.getCdrData(args);
        if (res.ok) {
          rawResult.push(...response.data.results)
        }
      }
    }
    i18nService.t('')

    // big transform on the data in order to give us the data we want
    let result = rawResult.map( row => ({
      [i18nService.t('simOverview.cdrs.headers.start')]: toReadableDateTime(row.data.startTime),
      [i18nService.t('simOverview.cdrs.headers.end')]: toReadableDateTime(row.data.endTime),
      [i18nService.t('simOverview.cdrs.headers.ip')]: row.data.ipAddress,
      [i18nService.t('simOverview.cdrs.headers.usage')]: row.dataUsed,
      [i18nService.t('simOverview.cdrs.headers.mcc')]: row.data.servingNetwork.mcc,
      [i18nService.t('simOverview.cdrs.headers.mnc')]: row.data.servingNetwork.mnc,
      [i18nService.t('simOverview.cdrs.headers.network')]: row.data.servingNetwork.network
    }))

    // finally, parse the results to the CSV string
    result = unparse(result)
    var blob = new Blob([result]);
    downloadFileBlob(blob, args)

    resolve()
  })
}


export const downloadAPIData = (inputArgs={}) => {
  return new Promise( async (resolve, reject) => {
    const args = { ...inputArgs, limit: 100}
    const response = await apiService.logs(args)
    let pages
    let rawResult = []

    if (response.ok) {
      pages = response.data.pagination.totalPages
      rawResult.push(...response.data.results)
    }

    // if theres more than one page, we'll have to do multiple API calls and append the results
    if (pages > 1) {
      for (let i=2; i <= pages; ++i) {
        args.page = i
        let res = await apiService.logs(args);
        if (res.ok) {
          rawResult.push(...response.data.results)
        }
      }
    }

    // big transform on the data in order to give us the data we want
    let result = rawResult.map( row => ({
      [i18nService.t('api.logs.headers.key')]: row.apiKey,
      [i18nService.t('api.logs.headers.method')]: row.method,
      [i18nService.t('api.logs.headers.date')]: toReadableDateTime(row.dateTime),
      [i18nService.t('api.logs.headers.endpoint')]: row.endpoint,
      [i18nService.t('api.logs.headers.status')]: row.statusCode,
      [i18nService.t('api.logs.headers.response')]: row.responseBody,
    }))

    result = unparse(result)
    var blob = new Blob([result]);
    downloadFileBlob(blob, args)

    resolve()
  })
}

const downloadFileBlob = (blob, args) => {
  // create a file blob, and download file (couple options for browser compat.)
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, args.filename || 'export.csv');
  } else {
    let a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
    a.download = args.filename || 'export.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  downloadCDRData,
  downloadAPIData
}