import { useState } from 'react';

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [modalData, setModalData] = useState(null);

  function toggle() {
    setIsShowing(!isShowing);
  }

  return [isShowing, toggle, setIsShowing, modalData, setModalData]
};

export default useModal;